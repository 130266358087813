import { Avatar, Typography } from 'antd';
import { greyShed5, primary, white } from 'constants/theme.constants';
import { AOIDetail } from 'store/aoi/types';
import { RouteDetail } from 'store/route/types';

const { Text } = Typography;

interface WorkspaceStepPropstype {
  isBeeLineCompleted: boolean;
  isHighResLULCGeneration: boolean;
  currentStep: number;
  editedAOIData: AOIDetail | null;
  editedRouteData: RouteDetail | null;
  handleChangeStep: (val: number) => void;
}

const WorkspaceStep = ({
  isBeeLineCompleted,
  isHighResLULCGeneration,
  currentStep,
  editedAOIData,
  editedRouteData,
  handleChangeStep
}: WorkspaceStepPropstype) => {
  return (
    <div>
      <div
        className="main-col main-col-1"
        onClick={
          Boolean(editedAOIData) || Boolean(editedRouteData) ? () => {} : () => handleChangeStep(0)
        }>
        <Avatar
          style={{
            backgroundColor: currentStep === 0 || isBeeLineCompleted ? primary : white,
            border:
              currentStep === 0 || isBeeLineCompleted
                ? `0.5px solid ${primary}`
                : `0.5px solid ${greyShed5}`,
            color: currentStep === 0 || isBeeLineCompleted ? white : '#8F8F8F'
          }}>
          01
        </Avatar>
        <Text
          style={{ color: currentStep === 0 || isBeeLineCompleted ? primary : '#8F8F8F' }}
          className="step-title">
          Bee Line
        </Text>
      </div>
      <div className="empty-col" />
      <div
        className="main-col main-col-2"
        onClick={
          !isBeeLineCompleted || Boolean(editedRouteData) ? () => {} : () => handleChangeStep(1)
        }>
        <Avatar
          style={{
            backgroundColor: currentStep === 1 || isHighResLULCGeneration ? primary : white,
            border:
              currentStep === 1 || isHighResLULCGeneration
                ? `0.5px solid ${primary}`
                : `0.5px solid ${greyShed5}`,
            color: currentStep === 1 || isHighResLULCGeneration ? white : '#8F8F8F'
          }}>
          02
        </Avatar>
        <Text
          className="step-title"
          style={{
            color: currentStep === 1 || isHighResLULCGeneration ? primary : '#8F8F8F'
          }}>
          AOI Generation
        </Text>
      </div>
      <div className="empty-col" />
      <div
        className="main-col main-col-3"
        onClick={
          Boolean(editedAOIData) || !isHighResLULCGeneration ? () => {} : () => handleChangeStep(2)
        }>
        <Avatar
          style={{
            backgroundColor: currentStep === 2 ? primary : white,
            border: currentStep === 2 ? `0.5px solid ${primary}` : `0.5px solid ${greyShed5}`,
            color: currentStep === 2 ? white : '#8F8F8F'
          }}>
          03
        </Avatar>
        <Text style={{ color: currentStep === 2 ? primary : '#8F8F8F' }} className="step-title">
          Route Generation
        </Text>
      </div>
    </div>
  );
};

export default WorkspaceStep;

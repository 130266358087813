import { Button } from 'antd';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import { useAppSelector } from 'hooks/useReduxHook';
import { find } from 'lodash';
import { ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';

interface InitiateLayerButtonType {
  currentStep: number;
  isLulcGenInprogress: boolean;
  handleInitiateLayerGeneration: () => void;
}

const InitiateLayerButton = ({
  currentStep,
  isLulcGenInprogress,
  handleInitiateLayerGeneration
}: InitiateLayerButtonType) => {
  const { selectedProject } = useAppSelector((state) => state.projects);

  return currentStep === 1 &&
    Boolean(
      find(selectedProject?.stages, {
        stage_name: ProjectStagesList.LULCLow,
        status: ProposedTimelineStatus.NotStarted
      })
    ) ? (
    <IsViewerWrapper>
      <Button
        type="primary"
        ghost
        disabled={isLulcGenInprogress}
        onClick={handleInitiateLayerGeneration}>
        Initiate Layer Generation
      </Button>
    </IsViewerWrapper>
  ) : null;
};

export default InitiateLayerButton;

import React, { createContext, useContext } from 'react';
import { Map } from 'ol';

const MapContext = createContext<Map | null | undefined>(null);

export const useMap = () => {
  return useContext(MapContext);
};

export const MapProvider: React.FC<{ map: Map; children: React.ReactNode }> = ({
  map,
  children
}) => {
  return <MapContext.Provider value={map}>{children}</MapContext.Provider>;
};

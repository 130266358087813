import { Button, Modal, Progress, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import { primary } from 'constants/theme.constants';

const { Text } = Typography;

interface LulcGenerateOkayModalPropsType {
  isOpen: boolean;
  isClosable: boolean;
  text: string;
  percentage: number;
  handleClickOkButton: Function;
  handleClickCancelButton: Function;
  width?: number;
}

const LulcGenerateOkayModal = ({
  isOpen,
  isClosable,
  text,
  percentage,
  width,
  handleClickOkButton,
  handleClickCancelButton
}: LulcGenerateOkayModalPropsType) => {
  return (
    <Modal
      title=""
      className="approve-modal"
      width={width}
      closable={isClosable}
      open={isOpen}
      closeIcon={<CloseIcon onClick={() => handleClickCancelButton()} />}
      footer={null}
      centered>
      <div className="approve-modal-content approved-aoi-sat-purchase sat-purchase lulc-okay">
        <Text className="modal-title">
          <div>
            <div>
              <Text className="procurring">{text}</Text>
              <Text>{percentage}%</Text>
            </div>
            <Progress strokeColor={primary} trailColor="#D9D9D9" percent={3} showInfo={false} />
          </div>
        </Text>
        <Text>We will notify you via email once it is completed and added to layers.</Text>
        <div className="modal-content-btn no-cancel-btn">
          <Button type="primary" className="confirm-btn" onClick={() => handleClickOkButton()}>
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
};
LulcGenerateOkayModal.defaultProps = {
  width: 523
};

export default LulcGenerateOkayModal;

import { primary } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const ArchiveIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5499_22487)">
        <path
          d="M5.90625 7C5.7322 7 5.56528 7.06914 5.44221 7.19221C5.31914 7.31528 5.25 7.4822 5.25 7.65625C5.25 7.8303 5.31914 7.99722 5.44221 8.12029C5.56528 8.24336 5.7322 8.3125 5.90625 8.3125H8.09375C8.2678 8.3125 8.43472 8.24336 8.55779 8.12029C8.68086 7.99722 8.75 7.8303 8.75 7.65625C8.75 7.4822 8.68086 7.31528 8.55779 7.19221C8.43472 7.06914 8.2678 7 8.09375 7H5.90625Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.85432e-07 2.40625C1.85432e-07 1.561 0.686 0.875 1.53125 0.875H12.4688C13.314 0.875 14 1.561 14 2.40625V3.71875C14 4.3295 13.6421 4.85625 13.125 5.103V11.1562C13.125 11.6784 12.9176 12.1792 12.5484 12.5484C12.1792 12.9176 11.6784 13.125 11.1562 13.125H2.84375C2.32161 13.125 1.82085 12.9176 1.45163 12.5484C1.08242 12.1792 0.875 11.6784 0.875 11.1562V5.103C0.613077 4.97876 0.391802 4.78272 0.236906 4.53767C0.0820104 4.29263 -0.000142455 4.00865 1.85432e-07 3.71875V2.40625ZM11.8125 11.1562V5.25H2.1875V11.1562C2.1875 11.5185 2.4815 11.8125 2.84375 11.8125H11.1562C11.3303 11.8125 11.4972 11.7434 11.6203 11.6203C11.7434 11.4972 11.8125 11.3303 11.8125 11.1562ZM12.6875 3.71875C12.6875 3.77677 12.6645 3.83241 12.6234 3.87343C12.5824 3.91445 12.5268 3.9375 12.4688 3.9375H1.53125C1.47323 3.9375 1.41759 3.91445 1.37657 3.87343C1.33555 3.83241 1.3125 3.77677 1.3125 3.71875V2.40625C1.3125 2.34823 1.33555 2.29259 1.37657 2.25157C1.41759 2.21055 1.47323 2.1875 1.53125 2.1875H12.4688C12.5268 2.1875 12.5824 2.21055 12.6234 2.25157C12.6645 2.29259 12.6875 2.34823 12.6875 2.40625V3.71875Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5499_22487">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
ArchiveIcon.defaultProps = {
  color: primary,
  height: 14,
  width: 14
};

export default ArchiveIcon;

import { Button, Slider, Typography } from 'antd';
import TrueIcon from 'assets/svg/TrueIcon';
import { primary } from 'constants/theme.constants';
import IsViewerWrapper from './IsViewerWrapper';
import './core.less';

const { Text } = Typography;

interface RangeSliderProps {
  layerWeight: number;
  handleChangeLayerPosition: (newValue: number) => void;
  handleAfterFileWeightChange: (val: number) => void;
  handleHideSlider: () => void;
}

const RangeSlider = ({
  layerWeight,
  handleChangeLayerPosition,
  handleAfterFileWeightChange,
  handleHideSlider
}: RangeSliderProps) => {
  return (
    <div className="range-slider" style={{ color: '#333333' }}>
      <Text>{layerWeight}</Text>
      <div className="slider-div">
        <Text>0 (Go)</Text>
        <IsViewerWrapper>
          <Slider
            onChange={handleChangeLayerPosition}
            onAfterChange={handleAfterFileWeightChange}
            min={0}
            step={5}
            max={100}
            value={layerWeight}
            tooltip={{ formatter: null }}
          />
        </IsViewerWrapper>
        <Text>100 (No-Go)</Text>
        <Button
          type="text"
          className="slider-close-btn"
          onClick={handleHideSlider}
          icon={<TrueIcon color={primary} />}
        />
      </div>
    </div>
  );
};

export default RangeSlider;

import React from 'react';
import { Button, Typography } from 'antd';

const { Text } = Typography;

interface EditModeProps {
  layerType: string;
  handleCloseEditMode: () => void;
  handleEdit: () => void;
  isEdited: Boolean;
  handleSave: () => void;
}

const EditMode: React.FC<EditModeProps> = ({
  layerType,
  handleCloseEditMode,
  isEdited,
  handleEdit,
  handleSave
}) => {
  const handleButtonClick = () => {
    handleSave();
    if (isEdited) {
      handleEdit();
    }
  };

  return (
    <div className="edit-mode">
      <Text>You are in Edit Mode.</Text>
      {isEdited && (
        <Button className="save-aoi-btn" onClick={handleButtonClick}>
          Save {layerType}
        </Button>
      )}
      <Button
        onClick={() => {
          handleCloseEditMode();
          if (isEdited) handleEdit();
        }}>
        Discard
      </Button>
    </div>
  );
};

export default EditMode;

import React, { useState, useEffect } from 'react';
import { Table, InputNumber } from 'antd';

interface TowerType {
  key: React.Key;
  towertype: string;
  count: any;
  weight?: number;
  volume?: number;
  cost: number;
}

interface TowerDetailsProps {
  steelUnitCost?: number;
  concreteUnitCost?: number;
  selectedRouteData?: any;
  onTotalHardCostChange?: (totalHardCost: number) => void;
  onPayloadChange?: (payload: any[]) => void;
  onPayloadGet?: (getPayload: () => any[]) => void;
}

const TowerDetails: React.FC<TowerDetailsProps> = ({
  steelUnitCost = 0,
  concreteUnitCost = 0,
  selectedRouteData = {},
  onTotalHardCostChange,
  onPayloadChange,
  onPayloadGet
}) => {
  const [initialDataSource, setInitialDataSource] = useState<TowerType[]>([]);
  const [dataSource, setDataSource] = useState<TowerType[]>([]);

  useEffect(() => {
    if (selectedRouteData && selectedRouteData.tower_stats) {
      const initialDataSource = selectedRouteData.tower_stats.map((item: any, index: number) => ({
        key: index.toString(),
        towertype: item.type || '',
        count: item.count || 0,
        weight: item.steel_weight,
        volume: item.concrete_volume,
        cost: 0
      }));
      setInitialDataSource(initialDataSource);
      setDataSource(initialDataSource);
    }
  }, [selectedRouteData]);

  const getPayload = () => {
    return dataSource.map(({ towertype, count, weight, volume }) => ({
      type: towertype,
      count,
      steel_weight: weight ?? 0,
      concrete_volume: volume ?? 0
    }));
  };

  useEffect(() => {
    const updatedDataSource = initialDataSource.map((item) => {
      const weight = item.weight ?? 0;
      const volume = item.volume ?? 0;
      const steelCost = item.count * weight * steelUnitCost;
      const concreteCost = item.count * volume * concreteUnitCost;
      const totalHardCost = steelCost + concreteCost;
      return { ...item, cost: totalHardCost };
    });

    setDataSource(updatedDataSource);

    if (onTotalHardCostChange) {
      const totalHardCost = updatedDataSource.reduce((total, item) => total + item.cost, 0);
      onTotalHardCostChange(totalHardCost);
    }

    const payload = getPayload();
    if (onPayloadChange) {
      onPayloadChange(payload);
    }

    if (onPayloadGet) {
      onPayloadGet(getPayload);
    }
  }, [steelUnitCost, concreteUnitCost, initialDataSource]);

  const handleChange = (key: React.Key, field: keyof TowerType, value: number | undefined) => {
    const newData = dataSource.map((item) =>
      item.key === key ? { ...item, [field]: value } : item
    );
    setDataSource(newData);
    setInitialDataSource(newData);
  };

  const calculateTotalCost = () => {
    return dataSource.reduce((total, item) => total + item.cost, 0).toLocaleString();
  };

  const columns = [
    {
      title: 'Tower Type',
      dataIndex: 'towertype',
      key: 'towertype'
    },
    {
      title: 'Tower Count',
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: 'Steel Weight (tonnes per tower)',
      dataIndex: 'weight',
      key: 'weight',
      render: (text: number | undefined, record: TowerType) =>
        record.key !== 'total' ? (
          <InputNumber
            value={text}
            min={0}
            placeholder="0.0"
            step={0.1}
            style={{ width: '70%', fontSize: '12px', color: '#316db3' }}
            controls={false}
            // @ts-ignore
            onChange={(value) => handleChange(record.key, 'weight', value)}
          />
        ) : null
    },
    {
      title: 'Concrete Volume (kg per cubic metre per tower)',
      dataIndex: 'volume',
      key: 'volume',
      render: (text: number | undefined, record: TowerType) =>
        record.key !== 'total' ? (
          <InputNumber
            value={text}
            min={0}
            step={0.1}
            placeholder="0.0"
            style={{ width: '70%', fontSize: '12px', color: '#316db3' }}
            controls={false}
            // @ts-ignore
            onChange={(value) => handleChange(record.key, 'volume', value)}
          />
        ) : null
    },
    {
      title: 'Total Tower Cost (INR)',
      dataIndex: 'cost',
      key: 'cost',
      render: (text: number, record: TowerType) =>
        record.key !== 'total' ? (
          <span style={{ color: '#42444A', fontWeight: '500' }}>{text.toLocaleString()}</span>
        ) : (
          text.toLocaleString()
        )
    }
  ];

  const totalCost = calculateTotalCost();

  const totalCostRow: TowerType = {
    key: 'total',
    towertype: '',
    count: '',
    weight: undefined,
    volume: undefined,
    cost: parseFloat(totalCost.replace(/,/g, ''))
  };

  return (
    <div className="tower-detail-content">
      <Table
        scroll={{ y: '' }}
        className="tower-table"
        dataSource={[...dataSource, totalCostRow]}
        pagination={false}
        columns={columns}
        rowClassName={(record) => (record.key === 'total' ? 'total-row' : '')}
      />
    </div>
  );
};

TowerDetails.defaultProps = {
  steelUnitCost: 0,
  concreteUnitCost: 0,
  selectedRouteData: {},
  onTotalHardCostChange: () => {},
  onPayloadChange: () => {},
  onPayloadGet: () => {}
};

export default TowerDetails;

import React from 'react';
import { IconProps } from 'types/common.types';
import { lightred0 } from 'constants/theme.constants';

const InvalidIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33398 6.66699H8.66731V10.0003H7.33398V6.66699ZM7.33331 10.667H8.66665V12.0003H7.33331V10.667Z"
        fill={color}
      />
      <path
        d="M9.17864 2.7998C8.94664 2.36313 8.49464 2.0918 7.99997 2.0918C7.5053 2.0918 7.0533 2.36313 6.8213 2.80046L1.9293 12.0425C1.82101 12.2455 1.76738 12.4732 1.77369 12.7032C1.78 12.9332 1.84604 13.1577 1.9653 13.3545C2.0829 13.5522 2.25015 13.7158 2.45047 13.829C2.65079 13.9422 2.87722 14.0011 3.1073 13.9998H12.8926C13.3646 13.9998 13.792 13.7585 14.0353 13.3545C14.1546 13.1577 14.2206 12.9332 14.2269 12.7032C14.2332 12.4732 14.1796 12.2455 14.0713 12.0425L9.17864 2.7998ZM3.1073 12.6665L7.99997 3.42446L12.896 12.6665H3.1073Z"
        fill={color}
      />
    </svg>
  );
};
InvalidIcon.defaultProps = {
  color: lightred0,
  height: 16,
  width: 16
};

export default InvalidIcon;

import { useState } from 'react';
import { Button, Form, Input, Select, Typography } from 'antd';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addProject } from 'store/projects/actions';
import { createProjectSync } from 'utils/yupsync';
import { getAsyncStorageValue } from 'utils/localStorage';
import { selectAvailableProjectMembers } from 'store/projects';

interface AddProjectModalProps {
  handleCloseAddProjectModal: () => void;
}

const { Text } = Typography;

const AddProjectModal = ({ handleCloseAddProjectModal }: AddProjectModalProps) => {
  const dispatch = useAppDispatch();
  const availableMembers = useAppSelector(selectAvailableProjectMembers);
  const { isAddProjectRequesting } = useAppSelector((state) => state.projects);
  const [form] = Form.useForm();
  const [selectedMember, setSelectedMember] = useState<any>(null);
  const [isDisabled, setDisabled] = useState<boolean>(true);

  const handleSelectProjectMember = (value: string[]) => {
    setSelectedMember(value);
  };

  const handleSubmitCallback = () => {
    handleCloseAddProjectModal();
    form.resetFields();
    setSelectedMember('');
  };

  const handleProjectSubmit = () => {
    const payload = form.getFieldsValue(['project_title']);
    payload.project_manager_id = selectedMember;
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addProject(payload, token, handleSubmitCallback));
    });
  };

  const onChangeValue = () => {
    const formFields = form.getFieldsValue();

    const allFieldsFilled = Object.values(formFields).every((value) => {
      return value;
    });

    const projectTitleValid = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/.test(formFields.project_title);

    setDisabled(!(allFieldsFilled && projectTitleValid));
  };

  const handleCloseModal = () => {
    handleCloseAddProjectModal();
    form.resetFields();
    setDisabled(true);
  };

  return (
    <div className="modalFormContent">
      <p>Project Creation</p>
      <Form
        layout="vertical"
        className="addmember-form"
        form={form}
        onFinish={handleProjectSubmit}
        onValuesChange={onChangeValue}>
        <Form.Item
          name="project_title"
          label={
            <Text>
              Enter name of Project<Text>*</Text>
            </Text>
          }
          className="formField"
          rules={[createProjectSync]}>
          <Input placeholder="Enter name of project" className="formInputField" />
        </Form.Item>
        <Form.Item
          name="project_member_ids"
          label={
            <Text>
              Assign Project Manager<Text>*</Text>
            </Text>
          }
          className="formField form-select-content">
          <Select
            showArrow
            showSearch
            onChange={handleSelectProjectMember}
            style={{ width: '100%' }}
            filterOption={(input, option: any) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            options={availableMembers}
            className="formSelectField"
            placeholder="Select members"
            value={selectedMember}
          />
        </Form.Item>
        <div className="formButton">
          <Button
            loading={isAddProjectRequesting}
            type="primary"
            disabled={isDisabled}
            className="formCreatebtn"
            htmlType="submit">
            Create
          </Button>
          <Button type="ghost" className="formCancelbtn" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddProjectModal;

import { useEffect, useState } from 'react';
import { Form, Input, Modal, Select, Slider, Typography } from 'antd';
import { find, orderBy } from 'lodash';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { editRepositoryFile } from 'store/repositoryfile/actions';
import { Category, FileEditPayload, RepositoryFile, FilePayload } from 'store/repositoryfile/types';
import { editFileRepoSync } from 'utils/yupsync';
import { getAsyncStorageValue } from 'utils/localStorage';

interface EditFileModalProps {
  projectId: number;
  isOpenModal: boolean;
  handleEditFileCloseModal: Function;
  selectedRow: RepositoryFile;
  repoCategory: Array<Category>;
  isRequestingEditRepoFile: boolean;
}

const { Text } = Typography;
const { Option } = Select;

const EditFileModal = ({
  projectId,
  isOpenModal,
  handleEditFileCloseModal,
  selectedRow,
  repoCategory,
  isRequestingEditRepoFile
}: EditFileModalProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [form] = Form.useForm();
  const [weight, setWeight] = useState<number | null>(selectedRow?.weight);

  useEffect(() => {
    form.setFieldsValue({
      filename: selectedRow.file_name,
      buffer: selectedRow.buffer,
      comment: selectedRow.comment,
      weight: selectedRow.weight,
      category_id: find(repoCategory, (cat: Category) => cat.category_name === selectedRow.category)
        ?.id
    });
    setWeight(selectedRow.weight);
  }, [form, selectedRow, repoCategory]);

  const handleSubmitRepofile = () => {
    form.submit();
  };

  const handleSuccess = () => {
    form.resetFields();
    handleEditFileCloseModal();
  };

  const handleEditFileSubmit = (values: FileEditPayload) => {
    const payload: FilePayload = {
      ...values,
      filename: values.filename,
      category_id: values.category_id,
      buffer: values.buffer ? values.buffer.toString() : '0',
      weight: weight ?? 0,
      user_id: user.id!
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(editRepositoryFile(payload, projectId, selectedRow?.id, token, handleSuccess));
    });
  };

  const handleChangeWeights = (val: number) => {
    setWeight(val);
    form.setFieldsValue({ weight: val });
  };

  useEffect(() => {
    const [baseName] = selectedRow.file_name.split('.');

    form.setFieldsValue({
      filename: baseName,
      buffer: selectedRow.buffer,
      comment: selectedRow.comment,
      weight: selectedRow.weight,
      category_id: find(repoCategory, (cat: Category) => cat.category_name === selectedRow.category)
        ?.id
    });

    setWeight(selectedRow.weight);
  }, [form, selectedRow, repoCategory]);

  return (
    <Modal
      visible={isOpenModal}
      title={<Text>Edit File</Text>}
      okText="Save"
      cancelText="Cancel"
      confirmLoading={isRequestingEditRepoFile}
      onCancel={() => handleEditFileCloseModal()}
      onOk={handleSubmitRepofile}
      centered
      width={820}
      closable
      className="edit-modal"
      destroyOnClose>
      <div className="edit-content">
        <Form
          form={form}
          layout="vertical"
          name="edit_repofile_form"
          className="edit-form"
          autoComplete="off"
          onFinish={handleEditFileSubmit}>
          <Form.Item name="filename" label="NAME OF FILE" className="formField">
            <Input
              className="formInputField"
              addonAfter={<Text>{selectedRow.file_name.split('.').pop()}</Text>}
              value={selectedRow.file_name.split('.').slice(0, -1).join('.')}
            />
          </Form.Item>

          <Form.Item
            name="category_id"
            label="CATEGORY"
            className="formField"
            rules={[editFileRepoSync]}>
            <Select
              style={{ width: '100%' }}
              showArrow
              className="formSelectField edit-form-select"
              disabled>
              {repoCategory &&
                repoCategory.length > 0 &&
                orderBy(repoCategory, ['category_name'], ['asc']).map((cat: Category) => (
                  <Option key={cat.id} value={cat.id}>
                    {cat.category_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Item
              name="weight"
              style={{ width: '48%' }}
              label="WEIGHTS"
              className="formField"
              rules={[editFileRepoSync]}>
              <div className="file-weight-div">
                <Text>{weight || 0}</Text>
                <div className="weights-slider">
                  <Text>0 (Go)</Text>
                  <Slider
                    onChange={handleChangeWeights}
                    tooltip={{ open: false }}
                    min={0}
                    max={100}
                    step={5}
                    value={weight || undefined}
                  />
                  <Text>100 (No-Go)</Text>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="buffer"
              label="BUFFER"
              style={{ width: '48%' }}
              className="formField"
              rules={[editFileRepoSync]}>
              <Input
                className="buffer-field"
                size="large"
                type="number"
                step={1}
                min={0}
                addonAfter={<Text>m</Text>}
                placeholder="Enter buffer value"
              />
            </Form.Item>
          </div>

          <Form.Item
            name="comment"
            label="DESCRIPTION"
            className="formField"
            rules={[editFileRepoSync]}>
            <Input className="formInputField" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditFileModal;

import { UserRole } from 'store/users/types';
import { LatLongNumberType, LatLongType } from 'types/aoi.types';

export enum ProjectStagesList {
  ProjectCreation = 'Project Creation',
  ProjectDetails = 'Project Details',
  BeeLineRequest = 'Defining A & B points',
  LULCLow = 'Low Res LULC generation',
  AOIGeneration = 'AOI Generation',
  AOIConfirmation = 'AOI Confirmation',
  SatelliteConfirm = 'Satellite Imagery Purchase Confirmation',
  SatelliteProcurement = 'Satellite Imagery Procurement',
  LULCHigh = 'High Res LULC Generation',
  RouteGeneration = 'Route Generation'
}

export enum LULCLayerType {
  lulclow = 'low-res',
  lulchigh = 'high-res'
}

export type ProjectMember = {
  department: string | null;
  email: string;
  employee_id: string | null;
  first_name: string | null;
  id: string;
  last_name: string | null;
  phone_number: string | null;
  project_list: string[];
  role: UserRole;
  is_active: boolean;
};

export type MemberT = {
  label: string;
  value: string;
  role?: UserRole;
};

export enum ProjectStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Closed = 'Closed'
}

export enum ProposedTimelineStatus {
  OnGoing = 'On Going',
  Started = 'Started',
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  SentforApproval = 'Sent for Approval',
  Completed = 'Completed',
  Approved = 'Approved'
}

export interface TeamMember {
  id: string;
  email: string;
  role?: UserRole;
  first_name: string | null;
  last_name: string | null;
}

export interface ProjectStartEndPoint {
  coordinates: LatLongType | null;
  name: string | null;
  file_id?: number;
  uploaded_by?: string;
}

export interface ProjectData {
  bid_submission_date: string | null;
  circuit_type: string | null;
  completion_percentage: number | null;
  current_stage: string;
  current_stage_status: ProjectStatus;
  end_point: LatLongNumberType | null;
  start_point: LatLongNumberType | null;
  id: number;
  is_closed: boolean;
  last_updated: string;
  line_voltage: number | null;
  manager: {
    email: string;
    first_name: string | null;
    id: string;
    last_name: string | null;
  };
  members: TeamMember[];
  name: string;
  user_permission: string;
  row_width_m: string | null;
  created_at: string;
}

export interface ProjectType extends ProjectData {
  created_at: string;
}

export interface ProjectDataT {
  id: number;
  name: string;
  start_point: ProjectStartEndPoint;
  end_point: ProjectStartEndPoint;
  status: ProjectStatus;
  status_percentage: number;
  last_updated: string | null;
  team_members: Array<MemberT>;
  access: boolean;
}

export interface AvailableProjectMember {
  department: string | null;
  email: string;
  employee_id: string | null;
  first_name: string | null;
  id: string;
  last_name: string | null;
  phone_number: string | null;
  role: UserRole;
}

export interface ProjectStage {
  completion_date: string | null;
  deadline: string;
  id: number;
  stage_name: string;
  status: string | null;
  stage_id: number | null;
}

export interface ProjectTimeline extends ProjectType {
  stages: Array<ProjectStage>;
}

export type ProjectPayload = {
  admin_id: string;
  organisation_id: string;
  project_title: string;
  project_member_ids: string[];
};

export interface AddProjectMemberPayload {
  project_member_ids: string[];
}

export interface ProjectActivityData {
  created_at: string;
  email: string;
  employee_id: string;
  first_name: string;
  id: number;
  last_name: string;
  message: string;
  user_id: string;
}

export interface ProjectStagePayload {
  creation_date: string;
  defining_coordinates: string;
  AOI_request: string;
  LULC_classification_low: string;
  AOI_creation: string;
  AOI_finalization: string;
  tasking_high_resolution: string;
  procurement_high_resolution: string;
  pre_processing_high_resolution: string;
  DEM_generation: string;
  LULC_classification_high: string;
  preliminary_route: string;
  route_finalization: string;
}

export interface StageUploadPayload extends ProjectStagePayload {
  project_id: string;
}

export interface ProjectStageChangeStatusPayload {
  stage: string;
  project_id: string;
  status: string;
}

export interface RemoveProjectMembersPayload {
  member_list: string[];
}

export interface UpdateProjectDetailsPayload {
  line_voltage_id: string;
  circuit_type_id: string;
  bid_submission_date: string;
}

export interface RemoveOrganizationMemberPayload {
  is_active: boolean;
}

export type LineVoltages = {
  id: number;
  voltage_kv: number;
};

export type CircuitType = {
  id: number;
  circuit_type: string;
};

export interface ProjectDetail {
  line_voltages: LineVoltages[] | null;
  circuit_type: CircuitType[] | null;
}

export interface LulcLayerDetails {
  id: number;
  lulc_type: string;
  status: string;
  generation_percentage: number | null;
  error_msg: string | null;
  current_airflow_task: string | null;
}

export interface ProjectState {
  isLoadingProjects: boolean;
  projects: Array<ProjectData>;
  project: ProjectTimeline | null;
  isAddProjectRequesting: boolean;
  selectedProject: ProjectTimeline | null;
  isRequestProject: boolean;
  isAddProjectMemberRequest: boolean;
  isAvailableProjectMemberRequesting: boolean;
  availableProjectMembers: Array<AvailableProjectMember>;
  isDeadlineStageUpload: boolean;
  isRequestingProjectStageChangeStatus: boolean;
  isRequestingRemoveMemberFromProject: boolean;
  isRequestingRemoveMemberFromOrganization: boolean;
  isRequestingProjectActivityLogs: boolean;
  isRequestLulcLayerDetails: boolean;
  isRequestingCloseProject: boolean;
  isRequestingAddProjectDetails: boolean;
  projectActivityLogs: Array<ProjectActivityData> | [];
  projectDetails: ProjectDetail[];
  LulcLayerDetails: LulcLayerDetails | null;
}

import {
  loginSchema,
  adminDetailSchema,
  createProjectSchema,
  addMemberSchema,
  organizationSchema,
  fileRepositorySchema,
  ManagerMemberDetailSchema,
  editFileRepoSchema,
  coordSchema
} from './yupschema';

export const loginSync = {
  async validator({ field }: any, value: any) {
    await loginSchema.validateSyncAt(field, { [field]: value });
  }
};

export const addMemberSync = {
  async validator({ field }: any, value: any) {
    await addMemberSchema.validateSyncAt(field, { [field]: value });
  }
};

export const adminSync = {
  async validator({ field }: any, value: any) {
    await adminDetailSchema.validateSyncAt(field, { [field]: value });
  }
};

export const managerMemberSync = {
  async validator({ field }: any, value: any) {
    await ManagerMemberDetailSchema.validateSyncAt(field, { [field]: value });
  }
};

export const createProjectSync = {
  async validator({ field }: any, value: any) {
    await createProjectSchema.validateSyncAt(field, { [field]: value });
  }
};

export const organizationSync = {
  async validator({ field }: any, value: any) {
    await organizationSchema.validateSyncAt(field, { [field]: value });
  }
};

export const fileRepositorySync = {
  async validator({ field }: any, value: any) {
    await fileRepositorySchema.validateSyncAt(field, { [field]: value });
  }
};

export const editFileRepoSync = {
  async validator({ field }: any, value: any) {
    await editFileRepoSchema.validateSyncAt(field, { [field]: value });
  }
};

export const coordSync = {
  async validator({ field }: any, value: any) {
    await coordSchema.validateSyncAt(field, { [field]: value });
  }
};

export const bufferValidationRule = [
  {
    validator(_: any, value: string) {
      if (value === '0' || value === '') {
        return Promise.resolve();
      }
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        return Promise.reject(new Error('Please enter a valid number.'));
      }
      return Promise.resolve();
    }
  }
];

import { useMemo } from 'react';
import { useAppSelector } from 'hooks/useReduxHook';
import { RepositoryFile } from 'store/repositoryfile/types';
import { getRouteLayersGroupByCategory } from 'store/repositoryfile';
import { RouteDetail } from 'store/route/types';
import LayerList from './LayerList';
import RouteLists from './RouteLists';

interface RouteGenerationStepProps {
  isRouteGeneration: boolean;
  isShowHideWeightSlider: boolean;
  selectedRoutesIds: string[];
  vectorLayerCategories: string[];
  layers: Array<RepositoryFile> | [];
  layerWeight: number;
  selectedLayer: number | null;
  editedRouteData: RouteDetail | null;
  selectedRouteData: RouteDetail | null;
  handleChangeLayerPosition: (layer: number, layerPos: number) => void;
  handleFileWeightChange: (val: number) => void;
  handleAfterFileWeightChange: (val: number, file: RepositoryFile[]) => void;
  handleVectorLayerIds: (category: string) => void;
  handleShowHideLayers: (isshowhide: boolean) => void;
  handleShowHideRoute: (fileId: string) => void;
  handleGenerateRoute: () => void;
  handleHideSlider: () => void;
  handleEditBuffer: (file: RepositoryFile[]) => void;
  handleEditRoute: (route: RouteDetail) => void;
  handleSelectRouteDetail: (route: RouteDetail) => void;
  handleGozone: (text: boolean) => void;
  handleNoGozone: (text: boolean) => void;
}

const RouteGenerationStep = ({
  isRouteGeneration,
  isShowHideWeightSlider,
  editedRouteData,
  layers,
  layerWeight,
  selectedLayer,
  selectedRoutesIds,
  selectedRouteData,
  vectorLayerCategories,
  handleChangeLayerPosition,
  handleFileWeightChange,
  handleVectorLayerIds,
  handleAfterFileWeightChange,
  handleShowHideRoute,
  handleGenerateRoute,
  handleEditBuffer,
  handleEditRoute,
  handleShowHideLayers,
  handleSelectRouteDetail,
  handleGozone,
  handleNoGozone,
  handleHideSlider
}: RouteGenerationStepProps) => {
  const layerCategory = useAppSelector((state) => getRouteLayersGroupByCategory(state));

  const layerList = useMemo(
    () => (
      <LayerList
        isRouteGeneration={isRouteGeneration}
        selectedLayer={selectedLayer}
        layerWeight={layerWeight}
        layers={layers}
        layerCategory={layerCategory}
        selectedAOIRouteData={selectedRouteData}
        editAOIRouteData={editedRouteData}
        vectorLayerCategories={vectorLayerCategories}
        isShowHideWeightSlider={isShowHideWeightSlider}
        handleChangeLayerPosition={handleChangeLayerPosition}
        handleVectorLayerIds={handleVectorLayerIds}
        handleShowHideLayers={handleShowHideLayers}
        handleFileWeightChange={handleFileWeightChange}
        handleAfterFileWeightChange={handleAfterFileWeightChange}
        handleEditBuffer={handleEditBuffer}
        handleHideSlider={handleHideSlider}
        handleGozone={handleGozone}
        handleNoGozone={handleNoGozone}
      />
    ),
    [
      layers,
      layerWeight,
      layerCategory,
      selectedLayer,
      isShowHideWeightSlider,
      vectorLayerCategories
    ]
  );

  return (
    <div className="main-start-end-point aoi-layers route-layers">
      {layerList}
      {/* Route generation and list START */}
      <RouteLists
        selectedRouteData={selectedRouteData}
        editedRouteData={editedRouteData}
        selectedRoutesIds={selectedRoutesIds}
        handleShowHideRoute={handleShowHideRoute}
        handleEditRoute={handleEditRoute}
        handleSelectRouteDetail={handleSelectRouteDetail}
        handleGenerateRoute={handleGenerateRoute}
      />
      {/* Route generation and list END */}
    </div>
  );
};

export default RouteGenerationStep;

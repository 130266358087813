import { createSelector } from '@reduxjs/toolkit';
import { filter, find, isEmpty } from 'lodash';
import { UserRole } from 'store/users/types';
import { TeamMember } from 'store/projects/types';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { AOIDetail } from './types';

import { aoiSlice } from './slice';
import { RootState } from '..';

export const {
  setRequestingBeelinePointsCoords,
  setRequestingGetApprovedAoi,
  setAoiLists,
  setRequestingGenerateLULCLayer,
  setRequestingGenerateAOI,
  setRequestingAoiFiles,
  setRequestingAppproveDeclineAOI,
  setRequestingEditAOI,
  setRequestHighRes,
  clearAoi
} = aoiSlice.actions;
export default aoiSlice.reducer;

const selectedFileRepository = (state: RootState) => state.repofiles.repositoryFiles;
const repoFileId = (state: RootState, id: number | undefined) => id;

const unionStats = (aoiList: AOIDetail[]) => {
  const uniqueStatsIds = new Set<number>();
  const idToNameMap = new Map<number, string | undefined>();
  const vectorValues: string[] = [];

  aoiList?.forEach((aoi) => {
    aoi.parameters.vectors.forEach((vector) => {
      vectorValues.push(vector.file_cat);
    });
    aoi?.stats?.forEach((stat) => {
      uniqueStatsIds?.add(stat.file_cat_id);
      if (!idToNameMap.has(stat.file_cat_id)) {
        idToNameMap.set(stat.file_cat_id, stat.file_cat_name);
      }
    });
  });
  const uniqueCategories: string[] = vectorValues.filter((x, i, a) => a.indexOf(x) === i);
  const idList: number[] = Array.from(uniqueStatsIds);
  const result = aoiList?.map((aoi) => {
    const unionedVectors = uniqueCategories?.map((id) => {
      const stat = aoi.parameters.vectors.find((s) => s.file_cat === id);
      return stat || { file_id: 0, file_cat: id, weight: 0, buffer: 0, file_name: '' };
    });
    const unionedStats = idList?.map((id) => {
      const stat = aoi?.stats?.find((s) => s.file_cat_id === id);
      return stat || { file_cat_id: id, file_cat_name: idToNameMap.get(id), area_km_sq: 0 };
    });
    return {
      id: aoi.id,
      index: aoi.index,
      color: aoi.color,
      stats: unionedStats,
      weights: unionedVectors,
      geometry: aoi?.geometry
    };
  });
  return result;
};
export const getFileFromId = createSelector(
  [selectedFileRepository, repoFileId],
  (repoFileList, fileId) => {
    if (!fileId) return null;

    return find(repoFileList, (file: RepositoryFile) => file.id === fileId);
  }
);

const selectedProject = (state: RootState) => state.projects.selectedProject;
const selectUserId = (state: RootState, id: string | undefined) => id;

export const getUserFromId = createSelector([selectedProject, selectUserId], (project, userId) => {
  if (!userId) return null;
  const teamMembers = project?.team_members;
  if (teamMembers && teamMembers.length === 0) return null;

  return find(teamMembers, (member: TeamMember) => member.id === userId);
});

export const getSuperAdminAoifiles = (state: RootState) => {
  const { aoiLists } = state.aoi;

  return filter(aoiLists, {
    category: FilesCategory.AOI,
    active: true,
    user_role: UserRole.WorkspaceManager
  });
};

export const getAdminMangerAoiFiles = (state: RootState) => {
  const { aoiLists } = state.aoi;
  if (isEmpty(aoiLists)) return [];

  return filter(
    aoiLists,
    (file: RepositoryFile) =>
      file.category === FilesCategory.AOI &&
      file.active &&
      file.user_role !== UserRole.WorkspaceManager
  );
};

export const getAllAoiFiles = (state: RootState) => {
  const { aoiLists } = state.aoi;
  if (isEmpty(aoiLists)) return [];

  return filter(aoiLists, {
    category: FilesCategory.AOI,
    active: true
  });
};

export const getAoiFileFromId = createSelector(
  [selectedFileRepository, repoFileId],
  (repoFileList, fileId) => {
    if (!fileId) return null;

    const aoiFile = find(
      repoFileList,
      (file: RepositoryFile) => file.id === fileId && file.category === FilesCategory.AOI
    );

    return aoiFile;
  }
);

export const getFinalizedAoiFile = (state: RootState) => {
  const { aoi, aoiLists } = state.aoi;

  if (isEmpty(aoi) || !aoi?.is_approved) return null;
  return aoiLists.find((file: RepositoryFile) => file.id === aoi.aoi_file_id);
};

export const getGeneratedAOI = (state: RootState) => {
  const { aoiLists } = state.aoi;
  if (isEmpty(aoiLists)) return [];

  return aoiLists.filter((aoi: AOIDetail) => aoi.status !== 'Requested' && aoi.status !== 'Failed');
};

export const getSelectedAOI = createSelector(
  [
    (state: RootState) => state.aoi.aoiLists,
    (state: RootState, selectedAOIIndex: string[]) => selectedAOIIndex
  ],
  (aoiLists, selectedAOIIndex) => {
    if (isEmpty(aoiLists)) return [];

    if (isEmpty(selectedAOIIndex)) {
      return unionStats(aoiLists.slice(0, 3));
    }

    return unionStats(
      aoiLists
        .filter((aoi: AOIDetail) => selectedAOIIndex.includes(aoi.id))
        .sort(
          (a: AOIDetail, b: AOIDetail) =>
            selectedAOIIndex.indexOf(a.id) - selectedAOIIndex.indexOf(b.id)
        )
    );
  }
);

export const getDropDownOptions = createSelector(
  [
    (state: RootState) => state.aoi.aoiLists,
    (state: RootState, selectedAOIIndex: string[]) => selectedAOIIndex
  ],
  (aoiLists, selectedAOIIndex) => {
    if (isEmpty(aoiLists)) return [];
    if (isEmpty(selectedAOIIndex)) {
      return aoiLists.slice(3).map((aoi: AOIDetail) => ({
        label: `AOI ${aoi.index}`,
        value: aoi.id
      }));
    }

    return aoiLists
      .filter((aoi: AOIDetail) => !selectedAOIIndex.includes(aoi.id))
      .map((aoi: AOIDetail) => ({
        label: `AOI ${aoi.index}`,
        value: aoi.id
      }));
  }
);

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ACCESS_TOKEN } from 'constants/common.constant';
import FileRepositoryTable from 'components/FileRepositoryTable/FileRepositoryTable';
import FileRepositoryForm from 'components/FileRepositoryForm/FileRepositoryForm';
import { useAppDispatch } from 'hooks/useReduxHook';
import { getCategories, getRepositoryFiles } from 'store/repositoryfile/actions';
import { setAoiLists } from 'store/aoi';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const FileRepository = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(setAoiLists([]));
    if (id) {
      getAsyncStorageValue(ACCESS_TOKEN)?.then((token: string) => {
        dispatch(getCategories(Number(id), token));
        dispatch(getRepositoryFiles(Number(id), token));
      });
    }
  }, [id]);

  return (
    <div className="fileRepository">
      <FileRepositoryForm />
      <FileRepositoryTable />
    </div>
  );
};

export default FileRepository;

import { Button, Modal, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import ProgressIcon from 'assets/svg/Progress.svg';

const { Text } = Typography;

interface AOIApproveHighResConfirmModalPropsType {
  isOpen: boolean;
  isClosable: boolean;
  handleClickOkButton: Function;
  handleClickCancelButton: Function;
  width?: number;
}

const AOIApproveHighResConfirmModal = ({
  isOpen,
  isClosable,
  width,
  handleClickOkButton,
  handleClickCancelButton
}: AOIApproveHighResConfirmModalPropsType) => {
  return (
    <Modal
      title=""
      className="approve-modal"
      width={width}
      closable={isClosable}
      open={isOpen}
      closeIcon={<CloseIcon onClick={() => handleClickCancelButton()} />}
      footer={null}
      centered>
      <div className="approve-modal-content approved-aoi-sat-purchase sat-purchase">
        <Text className="modal-title">
          <div>
            <img src={ProgressIcon} alt="progress" />
            <Text className="procurring">Procuring Satellite Imagery</Text>
          </div>
        </Text>
        <Text>We will notify you via email once it is completed.</Text>
        <div className="modal-content-btn no-cancel-btn">
          <Button type="primary" className="confirm-btn" onClick={() => handleClickOkButton()}>
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
};
AOIApproveHighResConfirmModal.defaultProps = {
  width: 523
};

export default AOIApproveHighResConfirmModal;

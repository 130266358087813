import { useEffect, useRef } from 'react';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { Style, Icon } from 'ol/style';
import { Map } from 'ol';
import YellowPoint from 'assets/png/YellowPoint.png';
import OLPopup from 'ol-popup';
import { ProjectType } from 'store/projects/types';

interface RenderProjectListPointProps {
  projectList: Array<ProjectType> | [];
  map: Map | null | undefined;
  filterText: string;
}

const RenderProjectListPoint = ({ projectList, map, filterText }: RenderProjectListPointProps) => {
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer | null>(null);
  const vectorSourceRef = useRef<VectorSource>();
  const selectInteractionRef = useRef<Select>();
  const popupRef = useRef<OLPopup | null>(null);
  const selectedFeatureRef = useRef<Feature | null>(null);

  useEffect(() => {
    if (!map) return;

    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 100,
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          height: 20,
          width: 20,
          src: YellowPoint
        })
      })
    });
    vectorLayerRef.current.set('name', 'projectlist');
    map.addLayer(vectorLayerRef.current);
  }, [map]);

  useEffect(() => {
    if (map) {
      popupRef.current = new OLPopup();
      map.addOverlay(popupRef.current);
    }
    return () => {
      if (map && popupRef.current) {
        map.removeOverlay(popupRef.current);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!vectorSourceRef.current) return;

    vectorSourceRef.current.clear();

    projectList.forEach((project: ProjectType) => {
      if (project.start_point) {
        const feature = new Feature({
          geometry: new Point(fromLonLat([project.start_point.lng, project.start_point.lat]))
        });
        feature.setId(project.id);
        // @ts-ignore
        vectorSourceRef.current.addFeature(feature);
      }
    });
  }, [projectList]);

  useEffect(() => {
    if (!map) return;
    const select = new Select({
      condition: click,
      // @ts-ignore
      style: (feature) => {
        // @ts-ignore
        const originalStyle = feature.getStyle();
        if (originalStyle instanceof Style) {
          return originalStyle;
        }
        return null;
      }
    });

    map.addInteraction(select);
    selectInteractionRef.current = select;

    const handleSelect = (event: any) => {
      const selectedFeature = event.selected[0];
      if (selectedFeature) {
        const projectId = selectedFeature.getId();
        const selectedProject = projectList.find((project) => project.id === projectId);
        if (selectedProject) {
          if (popupRef.current) {
            const coordinates = selectedFeature.getGeometry().getCoordinates();
            popupRef.current.show(
              coordinates,
              `
              <div class='project-popup'>
                <div class='pop-up-info'>
                  <div>
                    <span>Name of Project: <b>${selectedProject.name}</b></span>
                    <span>Line Voltage: <b>${
                      selectedProject.line_voltage
                        ? `${selectedProject.line_voltage} kv`
                        : 'Not defined'
                    }</b></span>
                    <span>Circuit type: <b>${selectedProject.circuit_type || '-'}</b></span>
                    <span>Bid Submission Date: <b>${
                      selectedProject.bid_submission_date
                        ? new Date(selectedProject.bid_submission_date).toLocaleDateString()
                        : 'Not defined'
                    }</b></span>
                  </div>
                  <div>
                    <button id='closebutton'>x</button>
                  </div>
                </div>
              </div>
              `
            );

            const closeBtn = document.getElementById('closebutton');

            if (closeBtn) {
              closeBtn.addEventListener('click', () => {
                popupRef.current?.hide();
                selectInteractionRef.current?.getFeatures().clear();
                if (selectedFeatureRef.current) {
                  vectorSourceRef.current?.addFeature(selectedFeatureRef.current);
                }
              });
            }

            selectedFeatureRef.current = new Feature({
              geometry: new Point(coordinates)
            });
            selectedFeatureRef.current.setStyle(
              new Style({
                image: new Icon({
                  anchor: [0.5, 1],
                  height: 20,
                  width: 20,
                  src: YellowPoint
                })
              })
            );
            // @ts-ignore
            vectorSourceRef.current.addFeature(selectedFeatureRef.current);
          }
        }
      } else {
        popupRef.current?.hide();
        if (selectedFeatureRef.current) {
          vectorSourceRef.current?.removeFeature(selectedFeatureRef.current);
          selectedFeatureRef.current = null;
        }
      }
    };

    select.on('select', handleSelect);
  }, [map, projectList, filterText]);

  return null;
};

export default RenderProjectListPoint;

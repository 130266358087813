import React, { useState } from 'react';
import moment from 'moment';
import type { DatePickerProps } from 'antd';
import { toast } from 'react-toastify';
import { Button, Form, Select, DatePicker, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { updateProjectDetails } from 'store/projects/actions';
import type { ProjectType } from 'store/projects/types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

interface AddProjectDetailsProps {
  setDetailModal: Function;
  selectedRow: ProjectType;
}

const { Text } = Typography;

const AddProjectDetails = ({ selectedRow, setDetailModal }: AddProjectDetailsProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { projectDetails, isRequestingAddProjectDetails } = useAppSelector(
    (state) => state.projects
  );
  const [date, setDate] = useState<string>('');
  const [lineVoltageDropdownOpen, setLineVoltageDropdownOpen] = useState(false);
  const [circuitTypeDropdownOpen, setCircuitTypeDropdownOpen] = useState(false);

  const handleSubmitCallback = () => {
    setDetailModal(false);
    form.resetFields();
  };

  const onChange: DatePickerProps['onChange'] = (date) => {
    setDate(date ? moment(date).format('YYYY-MM-DD') : '');
  };

  const onHandleSubmit = () => {
    const payload = {
      line_voltage_id: form.getFieldValue(['line_voltage']),
      circuit_type_id: form.getFieldValue(['circuit_type']),
      bid_submission_date: date
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        updateProjectDetails(payload, selectedRow.id, token, () => {
          toast.success('Project details updated successfully!');
          setDate('');
          handleSubmitCallback();
        })
      );
    });
  };

  return (
    <div className="modalFormContent">
      <div className="project-detail-header">
        <h1>Add Project Details</h1>
        <div>
          <CloseIcon
            onClick={() => {
              setDetailModal(false);
              form.resetFields();
            }}
          />
        </div>
      </div>
      <hr />
      <Form layout="vertical" className="addproject-form" form={form} onFinish={onHandleSubmit}>
        <Form.Item
          name="line_voltage"
          rules={[{ required: true, message: 'Enter LINE VOLTAGE' }]}
          label={
            <Text>
              LINE VOLTAGE<Text className="required">*</Text>
            </Text>
          }
          className="formField">
          <Select
            showArrow
            open={lineVoltageDropdownOpen}
            onDropdownVisibleChange={(open) => setLineVoltageDropdownOpen(open)}
            style={{ width: '100%' }}
            placeholder="Select"
            suffixIcon={
              lineVoltageDropdownOpen ? (
                <CaretUpOutlined onClick={() => setLineVoltageDropdownOpen(false)} />
              ) : (
                <CaretDownOutlined onClick={() => setLineVoltageDropdownOpen(true)} />
              )
            }
            options={
              projectDetails &&
              projectDetails.line_voltages?.map((item: { voltage_kv: any; id: any }) => {
                return { label: `${item.voltage_kv} kV`, value: item.id };
              })
            }
          />
        </Form.Item>

        <Form.Item
          name="circuit_type"
          rules={[{ required: true, message: 'Enter CIRCUIT TYPE' }]}
          label={
            <Text>
              CIRCUIT TYPE<Text className="required">*</Text>
            </Text>
          }
          className="formField">
          <Select
            showArrow
            open={circuitTypeDropdownOpen}
            onDropdownVisibleChange={(open) => setCircuitTypeDropdownOpen(open)}
            style={{ width: '100%' }}
            placeholder="Select"
            suffixIcon={
              circuitTypeDropdownOpen ? (
                <CaretUpOutlined onClick={() => setCircuitTypeDropdownOpen(false)} />
              ) : (
                <CaretDownOutlined onClick={() => setCircuitTypeDropdownOpen(true)} />
              )
            }
            options={
              projectDetails &&
              projectDetails.circuit_types?.map((item: { circuit_type: any; id: any }) => {
                return { label: item.circuit_type, value: item.id };
              })
            }
          />
        </Form.Item>

        <Form.Item
          name="bid_submission"
          rules={[{ required: true, message: 'Enter BID SUBMISSION DATE' }]}
          label={
            <Text>
              BID SUBMISSION DATE <Text className="required">*</Text>
            </Text>
          }
          className="formField bid-submission">
          <DatePicker
            placeholder="dd-mm-yyyy"
            allowClear={false}
            className="project-date"
            style={{ width: '100%' }}
            format="DD-MM-YYYY"
            value={date ? moment(date, 'DD-MM-YYYY') : null}
            onChange={onChange}
            disabledDate={(current) => current && current < moment().startOf('day')}
          />
        </Form.Item>

        <div className="formButton">
          <Button
            type="primary"
            className="formCreatebtn"
            loading={isRequestingAddProjectDetails}
            htmlType="submit"
            style={{ marginRight: '20px' }}>
            Save
          </Button>
          <Button
            type="ghost"
            className="formCancelbtn"
            onClick={() => {
              setDetailModal(false);
              form.resetFields();
            }}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddProjectDetails;

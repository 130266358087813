import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { Button, Col, InputNumber, Row, Table, Typography } from 'antd';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { getLength } from 'ol/sphere';
import { toast } from 'react-toastify';
import { fromLonLat } from 'ol/proj';
import { setSteelCount } from 'store/users';
import { LineString } from 'ol/geom';
import { primary } from 'constants/theme.constants';
import { RouteDetail } from 'store/route/types';
import { getRouteLists, updatesRouteDetails } from 'store/route/actions';
import { getAsyncStorageValue } from 'utils/localStorage';
import { getDistance } from 'utils/geoCode';
import { ACCESS_TOKEN } from 'constants/common.constant';
import AreaRouteDetail from './AreaRouteDetail';
import RouteWeightBufferDetail from './RouteWeightBufferDetail';
import TowerDetails from './TowerDetails';
import TotalCostDetails from './TotalCostDetails';
import LinearCrossing from './LinearCrossing';
import './styles.less';

const { Text } = Typography;

interface AreaCrossStat {
  file_cat_id: number;
  file_cat_name: string;
  area_km_sq: number;
  unit_cost: number;
}

interface TowerStat {
  type: string;
  count: number;
  steel_weight: number;
  concrete_volume: number;
}

interface Payload {
  area_cross_stat: AreaCrossStat[];
  tower_stats: TowerStat[];
  steel_unit_cost: number;
  concrete_unit_cost: number;
  total_tower_cost: number;
  total_row_cost: number;
}

interface RouteDetailsCardPropsType {
  selectedRouteData: RouteDetail;
}

const RouteDetailsCard = ({ selectedRouteData }: RouteDetailsCardPropsType) => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { project } = useAppSelector((state) => state.projects);
  const { concreteUnitCostValue, steelUnitCostValue } = useAppSelector((state) => state.user);
  const [isOpenCloseRouteDetails, setOpenCloseRouteDetailsModal] = useState<boolean>(false);
  const [steelUnitCost, setSteelUnitCost] = useState<number | undefined>(undefined);
  const [concreteUnitCost, setConcreteUnitCost] = useState<number | undefined>(undefined);
  const [totalHardCost, setTotalHardCost] = useState<number>(0);
  const [totalRowCost, setTotalRowCost] = useState<number>(0);
  const [towerPayload, setTowerPayload] = useState<Payload | null>();

  const [currentModalHeight, setCurrentModalHeight] = useState('10vh');
  const MIN_MODAL_HEIGHT = '10vh';
  const MID_MODAL_HEIGHT = '40vh';
  const MAX_MODAL_HEIGHT = '76vh';

  const handleCloseOpen = () => {
    setOpenCloseRouteDetailsModal(!isOpenCloseRouteDetails);

    let newModalHeight;
    if (currentModalHeight === MIN_MODAL_HEIGHT) {
      newModalHeight = MID_MODAL_HEIGHT;
    } else if (currentModalHeight === MID_MODAL_HEIGHT) {
      newModalHeight = MAX_MODAL_HEIGHT;
    } else {
      newModalHeight = MIN_MODAL_HEIGHT;
    }

    setCurrentModalHeight(newModalHeight);
  };

  const handleTowerPayloadChange = (newPayload: TowerStat[]) => {
    const formattedPayload: Payload = {
      area_cross_stat: towerPayload
        ? towerPayload.area_cross_stat
        : selectedRouteData.area_cross_stat,
      tower_stats: newPayload,
      steel_unit_cost: steelUnitCost || 0,
      concrete_unit_cost: concreteUnitCost || 0,
      total_tower_cost: totalHardCost,
      total_row_cost: totalRowCost
    };
    setTowerPayload(formattedPayload);
  };

  const handleTotalCostPayloadChange = (newPayload: AreaCrossStat[]) => {
    const formattedPayload: Payload = {
      area_cross_stat: newPayload,
      tower_stats: towerPayload ? towerPayload.tower_stats : selectedRouteData.tower_stats,
      steel_unit_cost: steelUnitCost || 0,
      concrete_unit_cost: concreteUnitCost || 0,
      total_tower_cost: totalHardCost,
      total_row_cost: totalRowCost
    };
    setTowerPayload(formattedPayload);
  };

  const handleTotalCostChange = (cost: number) => {
    setTotalRowCost(cost);
  };

  const handleSteelUnitCostChange = (value: number | null) => {
    setSteelUnitCost(value ?? undefined);
  };

  const handleConcreteUnitCostChange = (value: number | null) => {
    setConcreteUnitCost(value ?? undefined);
  };

  const formatToIndianCurrency = (value: number) => {
    return new Intl.NumberFormat('en-IN').format(value);
  };

  const hardCost = formatToIndianCurrency(totalHardCost);
  const rowCost = formatToIndianCurrency(totalRowCost);
  const totalCost = formatToIndianCurrency(totalHardCost + totalRowCost);

  const data = [
    {
      key: '1',
      hardCost,
      rowCost,
      totalCost
    }
  ];

  const columns = [
    {
      title: 'Total Tower Cost (INR)',
      dataIndex: 'hardCost',
      key: 'hardCost'
    },
    {
      title: 'Total RoW Cost (INR)',
      dataIndex: 'rowCost',
      key: 'rowCost'
    },
    {
      title: 'Total Cost of Route (INR)',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (text: number) => (
        <span style={{ color: primary, fontWeight: '800' }}>{text.toLocaleString()}</span>
      )
    }
  ];

  const totalTowersCount =
    selectedRouteData.tower_stats?.reduce(
      (acc: any, currentTower: { count: any }) => acc + currentTower.count,
      0
    ) || 0;

  const handleSave = () => {
    if (selectedRouteData.id && towerPayload) {
      dispatch(
        setSteelCount({
          steelUnitCostValue: steelUnitCost,
          concreteUnitCostValue: concreteUnitCost
        })
      );
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(updatesRouteDetails(towerPayload, selectedRouteData.id, token));
        toast.success('Route details saved successfully');
        dispatch(getRouteLists(selectedProject.id, token));
        setTotalHardCost(towerPayload.total_tower_cost);
        setTotalRowCost(towerPayload.total_row_cost);
      });
    }
  };

  useEffect(() => {
    if (concreteUnitCostValue !== undefined) {
      setConcreteUnitCost(parseFloat(concreteUnitCostValue));
    }
    if (steelUnitCostValue !== undefined) {
      setSteelUnitCost(parseFloat(steelUnitCostValue));
    }
  }, [concreteUnitCostValue, steelUnitCostValue]);

  useEffect(() => {
    setTowerPayload((prevPayload) => ({
      total_tower_cost: totalHardCost,
      total_row_cost: totalRowCost,
      area_cross_stat: prevPayload?.area_cross_stat || [],
      tower_stats: prevPayload?.tower_stats || [],
      steel_unit_cost: prevPayload?.steel_unit_cost ?? 0,
      concrete_unit_cost: prevPayload?.concrete_unit_cost ?? 0
    }));
  }, [totalHardCost, totalRowCost]);

  return (
    <div className="aoi_details_card_container">
      <div className="aoiDetailsCard_Sections">
        <div className="arrow_icon_sections">
          <Button
            onClick={handleCloseOpen}
            ghost
            type="text"
            icon={
              isOpenCloseRouteDetails ? (
                <IoIosArrowUp
                  data-testid="arrow-up"
                  size={18}
                  style={{ cursor: 'pointer', marginTop: 6 }}
                />
              ) : (
                <IoIosArrowDown
                  data-testid="arrow-down"
                  size={18}
                  style={{ cursor: 'pointer', marginTop: 7 }}
                />
              )
            }
          />
        </div>
        <div className="aoiDetailsCard_Sections route-modal" style={{ height: currentModalHeight }}>
          <Row className="header-sections">
            <Col span={3} className="col-custom">
              <Text className="detail-title">Details : </Text>
              <Text className="detail-title-data">Route {selectedRouteData?.index || ''}</Text>
            </Col>
            <Col span={5} className="col-custom">
              <Text className="detail-title">Length of Route : </Text>
              <Text className="detail-title-data">
                {' '}
                {selectedRouteData?.geometry
                  ? `${(
                      getLength(
                        new LineString(
                          selectedRouteData.geometry.coordinates.map((point: any) =>
                            fromLonLat(point)
                          )
                        )
                      ) / 1e3
                    ).toFixed(2)} km`
                  : 0}
              </Text>
            </Col>
            <Col span={3} className="col-custom">
              <Text className="detail-title">RoW Width : </Text>
              <Text className="detail-title-data">{project.row_width_m}</Text>
            </Col>
            <Col span={3} className="col-custom">
              <Text className="detail-title">Total Towers : </Text>
              <Text className="detail-title-data">{totalTowersCount}</Text>
            </Col>
            <Col span={4} className="col-custom">
              <Text className="detail-title">Bee Line Length (km) : </Text>
              <Text className="detail-title-data">
                {selectedProject && selectedProject.start_point && selectedProject.end_point
                  ? getDistance(
                      Number(selectedProject.start_point?.lat),
                      Number(selectedProject.start_point?.lng),
                      Number(selectedProject.end_point?.lat),
                      Number(selectedProject.end_point?.lng)
                    )
                  : ''}
              </Text>
            </Col>
            <Col span={3} className="col-custom">
              <Text className="detail-title">Total Cost: </Text>
              <Text className="detail-title-data">{totalCost.toLocaleString()}</Text>
            </Col>
            <Col span={3} className="col-custom">
              <Button onClick={handleSave}>Save</Button>
            </Col>
          </Row>
          <div className="detail-main-header">
            <div className="total_route_cost">
              <h1>Total Route Cost</h1>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="routes_table_data"
              />
            </div>
            <div className="divider" />
            <div className="route-detail-data">
              <Row className="body_sections" justify="space-between">
                <Col span={8}>
                  <AreaRouteDetail
                    title="Area Crossing Stats"
                    selectedRouteData={selectedRouteData}
                  />
                </Col>
                <Col span={8}>
                  <LinearCrossing
                    title="Linear Crossing Stats"
                    selectedRouteData={selectedRouteData}
                  />
                </Col>
                <Col span={8}>
                  <RouteWeightBufferDetail
                    title="Weights & Buffer"
                    selectedRouteData={selectedRouteData}
                  />
                </Col>
              </Row>
              <div className="divider" />

              <div className="hard_cost">
                <h2>Tower Cost</h2>
              </div>
              <Row className="steel-con-cost">
                <Col span={10}>
                  <Text style={{ paddingRight: '30px' }}>Steel Unit Cost (INR/tonnes)</Text>
                  <InputNumber
                    placeholder="6445.787"
                    className="input-number"
                    value={steelUnitCost}
                    formatter={(value) =>
                      value ? `${new Intl.NumberFormat('en-IN').format(value)}` : ''
                    }
                    parser={(value) => {
                      if (!value) return 0;
                      const parsed = parseFloat(value.replace('₹ ', '').replace(/,/g, ''));
                      return Number.isNaN(parsed) ? 0 : parsed;
                    }}
                    onChange={handleSteelUnitCostChange}
                  />
                </Col>
                <Col span={10}>
                  <Text>Concrete Unit Cost (INR / cubic metre)</Text>
                  <InputNumber
                    placeholder="4564.876"
                    className="input-number"
                    value={concreteUnitCost}
                    formatter={(value) =>
                      value ? `${new Intl.NumberFormat('en-IN').format(value)}` : ''
                    }
                    parser={(value) => {
                      if (!value) return 0;
                      const parsed = parseFloat(value.replace('₹ ', '').replace(/,/g, ''));
                      return Number.isNaN(parsed) ? 0 : parsed;
                    }}
                    onChange={handleConcreteUnitCostChange}
                  />
                </Col>
              </Row>
              <Row className="tower_sections" justify="space-between">
                <Col span={24}>
                  <TowerDetails
                    steelUnitCost={steelUnitCost}
                    concreteUnitCost={concreteUnitCost}
                    selectedRouteData={selectedRouteData}
                    onTotalHardCostChange={(cost) => setTotalHardCost(cost)}
                    onPayloadChange={handleTowerPayloadChange}
                  />
                </Col>
              </Row>
              <div className="divider" />
              <div className="total_cost_details">
                <TotalCostDetails
                  selectedRouteData={selectedRouteData}
                  onTotalCostChange={handleTotalCostChange}
                  onPayloadChange={handleTotalCostPayloadChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteDetailsCard;

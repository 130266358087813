import { useState } from 'react';
import { Button, Card, Col, Progress, Row } from 'antd';
import { round } from 'lodash';
import { primary, lightred, greyShed5, greyShed6 } from 'constants/theme.constants';
import { AOIDetail, AOIStausType } from 'store/aoi/types';
import { RouteDetail } from 'store/route/types';

interface AOIRouteLoadingCardProps {
  aoiDetail: AOIDetail | RouteDetail;
  title: string;
}

const AOIRouteLoadingCard = ({ title, aoiDetail }: AOIRouteLoadingCardProps) => {
  const [isCancelRetryAOI, setCancelRetryAOI] = useState<boolean>(false);
  const isFailedstatus = aoiDetail.status === AOIStausType.failed;
  const isRequestedstatus = aoiDetail.status === AOIStausType.requested;

  return (
    <Card
      className="no-aoi-data-card"
      style={{ border: `1px solid ${isFailedstatus ? lightred : greyShed5}` }}
      data-attr={aoiDetail.color}>
      <div
        className="inr-content-col"
        style={{ backgroundColor: isFailedstatus ? lightred : greyShed6 }}
      />
      <Row className="inner-card-content">
        <Col span={17} className="inner-card-content-col">
          {title} {aoiDetail.index}
        </Col>
        {/* <Col span={4} className="inner-card-content-icon  aoi-loader-icon">
          <AOILoaderIcon />
        </Col> */}
        {isRequestedstatus && (
          <Col span={7} className="inner-card-content-icon aoi-loader-icon">
            {isCancelRetryAOI ? (
              <Button
                ghost
                type="text"
                className="aoi-retry"
                onClick={() => setCancelRetryAOI(!isCancelRetryAOI)}>
                Retry
              </Button>
            ) : (
              <>
                <Progress
                  width={26}
                  strokeColor={primary}
                  strokeWidth={8}
                  type="circle"
                  percent={
                    aoiDetail.status === AOIStausType.requested &&
                    aoiDetail.generation_percentage === null
                      ? 0
                      : round(Number(aoiDetail.generation_percentage!))
                  }
                />
                {/* <Button
                  ghost
                  type="text"
                  data-testid={`close-icon-${aoiDetail.index}`}
                  className="aoi-retry aoi-cancel"
                  onClick={() => setCancelRetryAOI(!isCancelRetryAOI)}
                  icon={<CloseIcon />}
                /> */}
              </>
            )}
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default AOIRouteLoadingCard;

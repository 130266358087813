import React from 'react';
import { sortBy } from 'lodash';
import { Table, Typography } from 'antd';
import { RouteDetail } from 'store/route/types';

const { Text } = Typography;

interface AreaRouteDetailPropsType {
  title: string;
  selectedRouteData?: RouteDetail;
}

const AreaRouteDetail = ({ title, selectedRouteData }: AreaRouteDetailPropsType) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'file_cat_name',
      key: 'name'
    },
    {
      title: 'Area (sq km)',
      dataIndex: 'area_km_sq',
      key: 'area_km_sq',
      render: (area: number) => area?.toFixed(2)
    }
  ];
  return (
    <div>
      <div className="aoi_card">
        <Text className="aoi_card_title">{title}</Text>
        <Table
          scroll={{ y: '200px' }}
          className="aoi-card-table"
          dataSource={sortBy(selectedRouteData?.area_cross_stat, ['file_cat_name'])}
          pagination={false}
          columns={columns}
        />
      </div>
    </div>
  );
};

AreaRouteDetail.defaultProps = {
  selectedRouteData: {}
};

export default AreaRouteDetail;

import React, { ReactElement } from 'react';
import { useAppSelector } from 'hooks/useReduxHook';
import { ProjectState } from 'store/projects/types';
import { UserPermission } from 'store/users/types';

interface IsViewerWrapperPropsType {
  children: ReactElement;
}

const IsViewerWrapper = ({ children }: IsViewerWrapperPropsType) => {
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);

  const isViewer = selectedProject?.user_permission === UserPermission.Viewer;

  if (isViewer) {
    return React.cloneElement(children, {
      ...children.props,
      disabled: true
    });
  }
  return React.cloneElement(children, {
    ...children.props
  });
};

export default IsViewerWrapper;

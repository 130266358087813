import React from 'react';
import { Input } from 'antd';

interface ProjectFilterType {
  onFilter: (filterText: string) => void;
  filterText: string;
}

const ProjectFilter = ({ onFilter, filterText }: ProjectFilterType) => (
  <Input
    id="search"
    type="text"
    placeholder="Search by Project ID or Name"
    value={filterText}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFilter(e.target.value.toLowerCase())}
  />
);

export default ProjectFilter;

import { useMemo, useState } from 'react';
import 'ol-popup/src/ol-popup.css';
import { Row } from 'antd';
import { find, isEmpty } from 'lodash';
import { ACCESS_TOKEN } from 'constants/common.constant';
import AOILineLength from 'components/WorkspaceOlMap/AOILineLength';
import AoiDetailsCard from 'components/AoiRouteDetailsCard/AoiDetailsCard';
import ConfirmModal from 'components/Modal/ConfirmModal';
import RouteDetailsCard from 'components/AoiRouteDetailsCard/RouteDetailsCard';
import MapComponent from 'components/WorkspaceOlMap/MapComponent';
import BeePointsAndLine from 'components/WorkspaceOlMap/BeePointsAndLine';
import LineComponent from 'components/WorkspaceOlMap/LineComponent';
import AOILayerComponent from 'components/WorkspaceOlMap/AOILayerComponent';
import RouteLayerComponent from 'components/WorkspaceOlMap/RouteLayerComponent';
import EditAOILayer from 'components/WorkspaceOlMap/EditAOILayer';
import RenderHighResLayer from 'components/WorkspaceOlMap/RenderHighResLayer';
import EditRouteLayer from 'components/WorkspaceOlMap/EditRouteLayer';
import RenderLowResLayer from 'components/WorkspaceOlMap/RenderLowResLayer';
import PointAComponent from 'components/WorkspaceOlMap/PointAComponent';
import EditMode from 'components/WorkspaceOlMap/EditMode';
import PointBComponent from 'components/WorkspaceOlMap/PointBComponent';
import RenderUserVectorLayer from 'components/WorkspaceOlMap/RenderUserVectorLayer';
import WorkspaceMeasureTool from 'components/WorkspaceOlMap/WorkspaceMeasureTool';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { AOIDetail, EditAoiPayload } from 'store/aoi/types';
import { EditRoutePayload, RouteDetail, RouteState } from 'store/route/types';
import { ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import { editAOI } from 'store/aoi/actions';
import { editRoute } from 'store/route/actions';
import { BeeLinePointType, LatLongNumberType } from 'types/aoi.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';
import NoAndGoZoneText from './NoAndGoZoneText';
import AoiLineComponent from './AoiLineComponent';

interface WorkspaceMapProps {
  currentStep: number;
  beeLineCoords: BeeLinePointType | null;
  selectedAoiIds: Array<string> | [];
  selectedRouteIds: Array<string> | [];
  vectorLayerCategories: Array<string> | [];
  aoiLists: AOIDetail[] | [];
  latlngA: LatLongNumberType | null;
  latlngB: LatLongNumberType | null;
  selectedAOIData: AOIDetail | null;
  editedAOIData: AOIDetail | null;
  editedRouteData: RouteDetail | null;
  selectedRouteData: RouteDetail | null;
  editAOICoordsData: any;
  editRouteCoordsData: any;
  handleSaveEditAOICoordsData: (d: any) => void;
  handleSaveEditRouteCoordsData: (d: any) => void;
  handleCloseEditMode: () => void;
  handleEditAOICallback: () => void;
  handleEditRouteCallback: () => void;
  handleCloseGoNogoZoneFilter: () => void;
  handleShowHideTowerText: () => void;
  handleChangeDoneLatngA: (data: number[]) => void;
  handleChangeDoneLatngB: (data: number[]) => void;
  isOpenApproveAOIModal: boolean;
  isOpenDeclineAOIModal: boolean;
  isOpenApprovePurchaseModal: boolean;
  isGozoneLayers: boolean;
  isNoGozoneLayers: boolean;
  isShowTowerText: boolean;
}

const WorkspaceMap = ({
  editedAOIData,
  currentStep,
  beeLineCoords,
  selectedAoiIds,
  aoiLists,
  latlngA,
  latlngB,
  vectorLayerCategories,
  selectedAOIData,
  editAOICoordsData,
  editRouteCoordsData,
  selectedRouteIds,
  editedRouteData,
  selectedRouteData,
  isOpenApproveAOIModal,
  isOpenDeclineAOIModal,
  isOpenApprovePurchaseModal,
  isGozoneLayers,
  isNoGozoneLayers,
  isShowTowerText,
  handleSaveEditAOICoordsData,
  handleSaveEditRouteCoordsData,
  handleCloseEditMode,
  handleEditAOICallback,
  handleEditRouteCallback,
  handleChangeDoneLatngA,
  handleChangeDoneLatngB,
  handleCloseGoNogoZoneFilter,
  handleShowHideTowerText
}: WorkspaceMapProps) => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { isRequestingEditAOI } = useAppSelector((state) => state.aoi);
  const { isRequestingEditRoute } = useAppSelector((state) => state.route);
  const { routeList }: RouteState = useAppSelector((state) => state.route);
  const [isEditAOIModal, setEditAOIModal] = useState<boolean>(false);
  const [isAOIEdited, setAOIEdited] = useState<boolean>(false);
  const [isRouteEdited, setRouteEdited] = useState<boolean>(false);
  const [isEditRouteModal, setEditRouteModal] = useState<boolean>(false);
  const [isRulerMeasurement, setRulerMeasurement] = useState<boolean>(false);

  const isBeeLineCompleted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.BeeLineRequest,
      status: ProposedTimelineStatus.Completed
    })
  );

  const handleSaveEditAoi = () => {
    setEditAOIModal(true);
  };

  const handleConfirmEditAOI = () => {
    if (!editedAOIData) return;
    const payload: EditAoiPayload = {
      coordinates: editAOICoordsData
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        editAOI(payload, selectedProject?.id, editedAOIData?.id, token, () => {
          setEditAOIModal(false);
          setAOIEdited(false);
          handleEditAOICallback();
        })
      );
    });
  };

  const handleEditAoi = () => {
    setAOIEdited(true);
  };
  const handleEditRoute = () => {
    setRouteEdited(true);
  };

  const handleCancelEditAoi = () => {
    setEditAOIModal(false);
    setAOIEdited(true);
  };

  const handleSaveEditRoute = () => {
    setEditRouteModal(true);
  };

  const handleConfirmEditRoute = () => {
    if (!editedRouteData) return;

    const payload: EditRoutePayload = {
      geometry: {
        type: 'LineString',
        coordinates: editRouteCoordsData
      }
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        editRoute(payload, selectedProject?.id, editedRouteData?.id, token, () => {
          setEditRouteModal(false);
          setRouteEdited(false);
          handleEditRouteCallback();
        })
      );
    });
  };

  const handleCancelEditRoute = () => {
    setEditRouteModal(false);
    setRouteEdited(true);
  };

  const handleChangeRulerMeasurement = () => {
    setRulerMeasurement(!isRulerMeasurement);
  };

  const mapContainer = useMemo(() => {
    return (
      <MapComponent
        isRulerMeasurement={isRulerMeasurement}
        isShowTowerText={isShowTowerText}
        handleChangeRulerMeasurement={handleChangeRulerMeasurement}
        handleShowHideTowerText={handleShowHideTowerText}
        latlngA={latlngA}
        latlngB={latlngB}>
        {currentStep === 1 && vectorLayerCategories.length > 0 && (
          // @ts-ignore
          <RenderLowResLayer
            isNoGozoneLayers={isNoGozoneLayers}
            vectorLayerCategories={vectorLayerCategories}
          />
        )}
        {currentStep === 2 && vectorLayerCategories.length > 0 && (
          // @ts-ignore
          <RenderHighResLayer
            isNoGozoneLayers={isNoGozoneLayers}
            vectorLayerCategories={vectorLayerCategories}
          />
        )}
        {vectorLayerCategories.length > 0 && (
          // @ts-ignore
          <RenderUserVectorLayer vectorLayerCategories={vectorLayerCategories} />
        )}

        {currentStep === 1 && (
          <>
            <AOILayerComponent
              selectedAoiIds={selectedAoiIds}
              aoiLists={aoiLists}
              selectedAOI={selectedAOIData}
            />
            <AoiLineComponent
              isShowTowerText={isShowTowerText}
              selectedAoiIds={selectedAoiIds}
              selectedAoiData={selectedAOIData}
            />
          </>
        )}
        {currentStep === 1 && editedAOIData && (
          <EditAOILayer
            editedAOI={editedAOIData}
            handleSaveEditAOICoordsData={handleSaveEditAOICoordsData}
            handleEditAoi={handleEditAoi}
          />
        )}
        {/* {currentStep === 2 && <ApprovedAOILayer aoiLists={aoiLists} />} */}
        {currentStep === 2 && editedRouteData && (
          <EditRouteLayer
            editedRoute={editedRouteData}
            handleSaveEditRouteCoordsData={handleSaveEditRouteCoordsData}
            handleEditRoute={handleEditRoute}
          />
        )}
        {currentStep === 2 && !isEmpty(selectedRouteIds) && (
          <RouteLayerComponent
            isShowTowerText={isShowTowerText}
            editedRouteData={editedRouteData}
            selectedRouteIds={selectedRouteIds}
            routeLists={routeList}
          />
        )}
        {latlngA && !isBeeLineCompleted && (
          <PointAComponent
            initPoint={[latlngA.lng, latlngA.lat]}
            pointB={latlngB}
            handleDoneLatngA={handleChangeDoneLatngA}
          />
        )}
        {!isBeeLineCompleted && latlngB && (
          <PointBComponent
            initPoint={[latlngB.lng, latlngB.lat]}
            pointA={latlngA}
            handleDoneLatngB={handleChangeDoneLatngB}
          />
        )}
        {!isBeeLineCompleted && latlngA && latlngB && (
          <LineComponent
            latlngA={[latlngA.lng, latlngA.lat]}
            latlngB={[latlngB.lng, latlngB.lat]}
          />
        )}
        {isBeeLineCompleted && (
          <BeePointsAndLine
            startPoint={
              selectedProject && selectedProject.start_point
                ? [selectedProject.start_point.lng, selectedProject.start_point.lat]
                : null
            }
            endPoint={
              selectedProject && selectedProject.end_point
                ? [selectedProject.end_point.lng, selectedProject.end_point.lat]
                : null
            }
          />
        )}
        {isRulerMeasurement && <WorkspaceMeasureTool isMeasure={isRulerMeasurement} />}
      </MapComponent>
    );
  }, [
    vectorLayerCategories,
    beeLineCoords,
    selectedAoiIds,
    selectedAOIData,
    aoiLists,
    latlngA,
    latlngB,
    currentStep,
    editedAOIData,
    editedRouteData,
    editAOICoordsData,
    editRouteCoordsData,
    isRulerMeasurement,
    isBeeLineCompleted,
    isGozoneLayers,
    isNoGozoneLayers,
    isShowTowerText
  ]);

  return (
    <Row className="aoi-content-row">
      {mapContainer}
      {/* {!editedAOIData && !editedRouteData && selectedProject && selectedProject.start_point && (
        <AOILineLength
          beeLineCoords={beeLineCoords}
          approvedAOI={
            find(aoiLists, ['status', AOIStausType.wmapproved] || AOIStausType.pmapproved) || null
          }
        />
      )} */}
      {(isGozoneLayers || isNoGozoneLayers) && (
        <NoAndGoZoneText
          isGozone={isGozoneLayers}
          isNoGozone={isNoGozoneLayers}
          handleCloseGoNogoZoneFilter={handleCloseGoNogoZoneFilter}
        />
      )}
      {!editedAOIData && !editedRouteData && selectedProject && !selectedProject.start_point && (
        <AOILineLength
          beeLineCoords={{
            start_point: latlngA,
            end_point: latlngB
          }}
          approvedAOI={null}
        />
      )}
      {editedAOIData && (
        <EditMode
          layerType="AOI"
          handleCloseEditMode={handleCloseEditMode}
          handleEdit={handleEditAoi}
          isEdited={isAOIEdited}
          handleSave={handleSaveEditAoi}
        />
      )}
      {editedRouteData && (
        <EditMode
          layerType="Route"
          handleCloseEditMode={handleCloseEditMode}
          handleEdit={handleEditRoute}
          isEdited={isRouteEdited}
          handleSave={handleSaveEditRoute}
        />
      )}

      {/* AOI details start */}
      {selectedAOIData && (
        <AoiDetailsCard
          selectedAOI={selectedAOIData}
          isOpenApproveAOIModal={isOpenApproveAOIModal}
          isOpenDeclineAOIModal={isOpenDeclineAOIModal}
          isOpenApprovePurchaseModal={isOpenApprovePurchaseModal}
        />
      )}
      {/* AOI details end */}
      {/* Route details start */}
      {selectedRouteData && <RouteDetailsCard selectedRouteData={selectedRouteData} />}
      {/* Route details end */}
      {/* Edit AOI confirmation Modal */}
      <ConfirmModal
        isOpen={isEditAOIModal}
        isClosable
        isCancelButton
        isLoading={isRequestingEditAOI}
        title="Edit AOI"
        description={`Are you sure you want to Edit AOI ${editedAOIData?.index}?`}
        handleClickOkButton={handleConfirmEditAOI}
        handleClickCancelButton={handleCancelEditAoi}
      />
      <ConfirmModal
        isOpen={isEditRouteModal}
        isClosable
        isCancelButton
        isLoading={isRequestingEditRoute}
        title="Edit Route"
        description={`Are you sure you want to Edit Route ${editedRouteData?.index}?`}
        handleClickOkButton={handleConfirmEditRoute}
        handleClickCancelButton={handleCancelEditRoute}
      />
    </Row>
  );
};

export default WorkspaceMap;

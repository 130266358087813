import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Modal, PageHeader, Empty, Spin, Typography, Avatar, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { BsArrowUpShort } from 'react-icons/bs';
import DataTable, { TableColumn } from 'react-data-table-component';
import ListIcon from 'assets/svg/ListIcon';
import MapIcon from 'assets/svg/MapIcon';
import { greyShed4, primary, red, white } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN, DateFormat2, TimeFormat, USER_ROLE } from 'constants/common.constant';
import AvatarList from 'components/Avatar/AvatarList';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import AddProjectMember from 'components/Modal/AddProjectMember/AddProjectMemberModal';
import AddProject from 'components/Modal/AddProject/AddProjectModal';
import StatusTitle from 'components/core/StatusTitle';
import MergedTitle from 'components/core/MergedTitle';
import ConfirmModal from 'components/Modal/ConfirmModal';
import ProjectFilter from 'components/core/ProjectFilter';
import AddProjectDetails from 'components/Modal/AddProjectDetails/AddProjectDetails';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { getAvailableProjectMembers, getProjectDetails, getProjects } from 'store/projects/actions';
import { ProjectStatus, ProjectData, TeamMember } from 'store/projects/types';
import { UserPermission, UserRole } from 'store/users/types';
import {
  setSelectedProject,
  clearProjects,
  getProjectCountByCompletedClosed,
  getProjectCountByInprogress,
  getMember,
  getProjectLists
} from 'store/projects';
import { clearRepofiles } from 'store/repositoryfile';
import { clearRoute } from 'store/route';
import { clearAoi } from 'store/aoi';
import { shortLabel } from 'utils/util';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import ProjectNameTooltip from './ProjectsNameTooltip';
import './styles.less';

const { Text } = Typography;

const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { isLoadingProjects, projects } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.user);
  const currentUser = user?.id;
  const [isOpenAddProjectModal, setAddProjectModal] = useState<boolean>(false);
  const [isAddDetailModal, setDetailModal] = useState<boolean>(false);
  const [isOpenAddProjectMemberModal, setAddProjectMemberModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ProjectData | null>(null);
  const [filterText, setFilterText] = useState<string>('');
  const projectDataFilter: Array<ProjectData> | [] = useAppSelector((state) =>
    getProjectLists(state, filterText)
  );
  const [showEmptyProjectModal, setShowEmptyProjectModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearRepofiles());
    dispatch(clearAoi());
    dispatch(clearRoute());
    dispatch(clearProjects());
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getProjects(token));
      dispatch(getAvailableProjectMembers(token));
    });
  }, []);

  const handleAddProjectMemberForm = (row: ProjectData) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getAvailableProjectMembers(token));
    });
    setAddProjectMemberModal(!isOpenAddProjectMemberModal);
    setSelectedRow(row);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModal(false);
  };

  const handleOpenAddProjectModal = () => {
    if (userRole === UserPermission.WorkspaceManager) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getAvailableProjectMembers(token));
      });
    }
    setAddProjectModal(true);
  };

  const handleCloseAddProjectMemberModal = () => {
    setSelectedRow(null);
    setAddProjectMemberModal(false);
  };

  const handleRowClick = (row: ProjectData) => {
    if (
      (UserRole.WorkspaceManager && UserRole.Member) !== row.user_permission &&
      row.line_voltage === null
    )
      setShowEmptyProjectModal(true);
    else {
      dispatch(setSelectedProject(row));
      navigate(`${routes.Projects}/${row.id}/${routes.ProjectTimelineUrl}`);
    }
  };

  const sortFunctionForLastUpdatedDate = (rowA: ProjectData, rowB: ProjectData) => {
    const a1 = new Date(rowA.last_updated || new Date()).getTime();
    const b1 = new Date(rowB.last_updated || new Date()).getTime();
    if (a1 < b1) {
      return 1;
    }
    if (a1 > b1) {
      return -1;
    }
    return 0;
  };

  const onHandleDetails = (row: ProjectData) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getProjectDetails(token));
    });
    setDetailModal(true);
    setSelectedRow(row);
  };

  const columns: TableColumn<ProjectData>[] = [
    {
      name: 'PROJECT ID',
      width: '10%',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      selector: (row) => row.id,
      sortable: true
    },
    {
      name: 'PROJECT NAME',
      width: '10%',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      selector: (row) => row.name,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <div onClick={() => handleRowClick(row)}>
          <ProjectNameTooltip projectName={row.name} />
        </div>
      )
    },
    {
      name: <MergedTitle />,
      width: '30%',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      cell: (row) => {
        return useMemo(() => {
          if (
            (UserPermission.Manager === row.user_permission ||
              UserPermission.WorkspaceManager === row.user_permission) &&
            row.line_voltage === null
          ) {
            return (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  color: primary,
                  position: 'absolute',
                  right: '15px'
                }}
                onClick={() => onHandleDetails(row)}>
                Add Details
              </div>
            );
          }
          return (
            <div className="mergedCell">
              {!(row.line_voltage || row.circuit_type) && (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    color: 'red'
                  }}>
                  Project Manager is notified to fill project details
                </div>
              )}
              <div
                style={{
                  width: '33%',
                  textAlign: 'left',
                  paddingLeft: '10px'
                }}
                onClick={() => handleRowClick(row)}>
                {row.line_voltage ? `${row.line_voltage} kV` : ''}
              </div>
              <div
                style={{ width: '33%', textAlign: 'left', paddingLeft: '5px' }}
                onClick={() => handleRowClick(row)}>
                {row.circuit_type || ''}
              </div>
              <div style={{ width: '33%', textAlign: 'left' }} onClick={() => handleRowClick(row)}>
                <Text className="project-dateTime">
                  {row.bid_submission_date
                    ? moment(row.bid_submission_date).format(DateFormat2)
                    : ''}
                </Text>
              </div>
            </div>
          );
        }, [row]);
      }
    },
    {
      name: <StatusTitle />,
      width: '12%',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      // eslint-disable-next-line
      cell: (row) => (
        <ProgressBar
          percent={row.completion_percentage!}
          statusText={row.current_stage_status!}
          project={row}
          handleOnClick={handleRowClick}
        />
      )
    },
    {
      name: 'LAST UPDATED',
      width: '12%',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      sortable: true,
      sortFunction: sortFunctionForLastUpdatedDate,
      cell: (row) =>
        useMemo(
          () =>
            !isEmpty(row.last_updated) ? (
              <div className="last-updated" onClick={() => handleRowClick(row)}>
                <Text className="project-dateTime">
                  {moment(row.last_updated).format(DateFormat2)}
                </Text>
                <Text className="project-dateTime">
                  {moment(row.last_updated).format(TimeFormat)} IST
                </Text>
              </div>
            ) : (
              <div className="last-updated" onClick={() => handleRowClick(row)}>
                <Text className="project-dateTime">-</Text>
              </div>
            ),
          [row.last_updated]
        )
    },
    {
      name: 'PROJECT MANAGER',
      width: '13%',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px',
        width: '112px'
      },
      cell: (row) =>
        useMemo(() => {
          const member = getMember(row.manager as unknown as TeamMember);
          return row.manager ? (
            <Tooltip
              title={
                <div className="avtars-name">
                  <Text>{member ? member.label : ''}</Text>
                  <Text>{member ? member.role : ''}</Text>
                </div>
              }
              color={white}
              placement="bottom"
              overlayClassName="avtar-tooltip">
              <Avatar
                className={
                  currentUser === member.value ? 'manager-avatar active' : 'manager-avatar'
                }>
                {shortLabel(member.label)}
              </Avatar>
            </Tooltip>
          ) : (
            '-'
          );
        }, [row.manager])
    },
    {
      name: 'PROJECT MEMBERS',
      width: '13%',
      style: {
        paddingRight: '0px',
        paddingLeft: '0px'
      },
      cell: (row) =>
        useMemo(
          () =>
            row.user_permission === UserPermission.Viewer ? (
              <Text>Viewer</Text>
            ) : (
              <AvatarList
                projectDetail={row}
                handleAddProjectMemberForm={handleAddProjectMemberForm}
              />
            ),
          [row.members]
        )
    }
  ];

  const conditionalRowStyles = [
    {
      when: (row: ProjectData) =>
        row.current_stage_status === ProjectStatus.Closed ||
        row.current_stage_status === ProjectStatus.Completed,
      style: {
        paddingLeft: '5px'
      }
    }
  ];

  const customStyles = {
    noData: {
      style: {
        backgroundColor: 'unset !important',
        height: '100% !important',
        display: 'flex',
        alignItem: 'center'
      }
    }
  };

  return (
    <>
      <HeaderLayout />
      <Spin
        size="large"
        tip="Loading..."
        spinning={isLoadingProjects}
        indicator={<LoadingOutlined color={red} style={{ fontSize: 24 }} spin />}>
        <div className="main-project-content">
          <PageHeader
            className="main-title-content"
            ghost={false}
            title={
              <>
                <Text>Projects</Text>
                <ProjectFilter
                  onFilter={(filterValue: string) => setFilterText(filterValue)}
                  filterText={filterText}
                />
              </>
            }
            extra={[
              userRole === UserRole.WorkspaceManager && (
                <Button
                  key="2"
                  type="primary"
                  className="topContentBtn"
                  onClick={handleOpenAddProjectModal}>
                  Create project
                </Button>
              ),
              <Button.Group className="list-map-grp-btn">
                <Button className="active-view-btn" icon={<ListIcon color={white} />} />
                <Button
                  onClick={() => navigate(routes.Mapview, { replace: true })}
                  icon={<MapIcon color={greyShed4} />}
                />
              </Button.Group>
            ]}
          />
          <div className="maintableContent">
            <DataTable
              fixedHeader
              responsive
              fixedHeaderScrollHeight="500px"
              columns={columns}
              data={projectDataFilter}
              onRowClicked={(row) => handleRowClick(row)}
              sortIcon={<BsArrowUpShort />}
              conditionalRowStyles={conditionalRowStyles}
              subHeader
              noDataComponent={
                <Empty
                  imageStyle={{
                    height: 0
                  }}
                  description={<Text className="descriptionText">NO PROJECTS FOUND</Text>}
                />
              }
              customStyles={customStyles}
            />
            <div className="mainProjectTLFooter">
              <div className="companyProjectContent">
                <Text>Company’s Projects</Text>
                <Text>{projects?.length > 0 ? projects.length : 0}</Text>|
              </div>
              {/* <div className="notStartedContent">
                    <Text>Not Started</Text>
                    <Text>{getProjectCountByStatus(projects, ProjectStatus.NotStarted)}</Text>
                  </div> */}
              <div className="inProgressContent">
                <Text>In Progress</Text>
                <Text>{getProjectCountByInprogress(projects)}</Text>
              </div>
              <div className="completeContent">
                <Text>Completed</Text>
                <Text>{getProjectCountByCompletedClosed(projects, ProjectStatus.Completed)}</Text>
              </div>
              <div className="closeContent">
                <Text>Closed</Text>
                <Text>{getProjectCountByCompletedClosed(projects, ProjectStatus.Closed)}</Text>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      {isOpenAddProjectMemberModal && selectedRow && (
        <Modal
          open={isOpenAddProjectMemberModal}
          okText="Create"
          cancelText="Cancel"
          centered
          width={697}
          className="newModal"
          closable={false}
          footer={null}>
          <AddProjectMember
            handleCloseAddProjectMemberModal={handleCloseAddProjectMemberModal}
            selectedRow={selectedRow}
            isProjectMember="project"
          />
        </Modal>
      )}
      <Modal
        open={isOpenAddProjectModal}
        okText="Create"
        cancelText="Cancel"
        centered
        width={697}
        className="newModal"
        closable={false}
        footer={null}>
        <AddProject handleCloseAddProjectModal={handleCloseAddProjectModal} />
      </Modal>
      {selectedRow && (
        <Modal
          open={isAddDetailModal}
          okText="Create"
          cancelText="Cancel"
          centered
          width={502}
          className="newModal"
          closable={false}
          footer={null}>
          <AddProjectDetails setDetailModal={setDetailModal} selectedRow={selectedRow} />
        </Modal>
      )}
      {/* No project details Modal */}
      <ConfirmModal
        width={600}
        isOpen={showEmptyProjectModal}
        isClosable={false}
        isCancelButton={false}
        title=""
        description="Oops, You cannot view the project at this time"
        subDescription="Once the project manager/workspace manager fills the details then only you can view the project."
        okbuttonText="Okay"
        handleClickOkButton={() => setShowEmptyProjectModal(false)}
        handleClickCancelButton={() => {}}
      />
    </>
  );
};

export default Projects;

import { white } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const RulerIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0964 20H9.90356C9.66408 19.9997 9.43445 19.8982 9.26507 19.7176C9.09568 19.537 9.00035 19.2922 9 19.0367V4.96374C9.00024 4.70822 9.09551 4.46325 9.26491 4.28256C9.4343 4.10188 9.664 4.00025 9.90356 4H15.0964C15.336 4.00025 15.5657 4.10188 15.7351 4.28256C15.9045 4.46325 15.9998 4.70822 16 4.96374V19.0367C15.9996 19.2922 15.9043 19.537 15.7349 19.7176C15.5656 19.8982 15.3359 19.9997 15.0964 20ZM9.90356 4.94809L9.88889 19.0367L15.0964 19.0519L15.1111 4.96374C15.1112 4.96167 15.1108 4.9596 15.1101 4.95767C15.1094 4.95574 15.1083 4.95398 15.107 4.95252C15.1056 4.95105 15.1039 4.94992 15.1021 4.94916C15.1003 4.9484 15.0984 4.94803 15.0964 4.94809H9.90356Z"
        fill={color}
      />
      <path
        d="M12 6.75278H9.44444C9.32657 6.75278 9.21353 6.70283 9.13018 6.61393C9.04683 6.52503 9 6.40446 9 6.27873C9 6.15301 9.04683 6.03244 9.13018 5.94354C9.21353 5.85464 9.32657 5.80469 9.44444 5.80469H12C12.1179 5.80469 12.2309 5.85464 12.3143 5.94354C12.3976 6.03244 12.4444 6.15301 12.4444 6.27873C12.4444 6.40446 12.3976 6.52503 12.3143 6.61393C12.2309 6.70283 12.1179 6.75278 12 6.75278Z"
        fill={color}
      />
      <path
        d="M11.1387 8.6561H9.44444C9.32657 8.6561 9.21353 8.60615 9.13018 8.51725C9.04683 8.42835 9 8.30778 9 8.18205C9 8.05633 9.04683 7.93576 9.13018 7.84686C9.21353 7.75796 9.32657 7.70801 9.44444 7.70801H11.1387C11.2565 7.70801 11.3696 7.75796 11.4529 7.84686C11.5363 7.93576 11.5831 8.05633 11.5831 8.18205C11.5831 8.30778 11.5363 8.42835 11.4529 8.51725C11.3696 8.60615 11.2565 8.6561 11.1387 8.6561Z"
        fill={color}
      />
      <path
        d="M11.1387 10.5809H9.44444C9.32657 10.5809 9.21353 10.531 9.13018 10.4421C9.04683 10.3532 9 10.2326 9 10.1069C9 9.98113 9.04683 9.86057 9.13018 9.77166C9.21353 9.68276 9.32657 9.63281 9.44444 9.63281H11.1387C11.2565 9.63281 11.3696 9.68276 11.4529 9.77166C11.5363 9.86057 11.5831 9.98113 11.5831 10.1069C11.5831 10.2326 11.5363 10.3532 11.4529 10.4421C11.3696 10.531 11.2565 10.5809 11.1387 10.5809Z"
        fill={color}
      />
      <path
        d="M12 12.4432H9.44444C9.32657 12.4432 9.21353 12.3933 9.13018 12.3044C9.04683 12.2155 9 12.0949 9 11.9692C9 11.8434 9.04683 11.7229 9.13018 11.634C9.21353 11.5451 9.32657 11.4951 9.44444 11.4951H12C12.1179 11.4951 12.2309 11.5451 12.3143 11.634C12.3976 11.7229 12.4444 11.8434 12.4444 11.9692C12.4444 12.0949 12.3976 12.2155 12.3143 12.3044C12.2309 12.3933 12.1179 12.4432 12 12.4432Z"
        fill={color}
      />
      <path
        d="M11.1387 14.2821H9.44444C9.32657 14.2821 9.21353 14.2321 9.13018 14.1432C9.04683 14.0543 9 13.9338 9 13.808C9 13.6823 9.04683 13.5617 9.13018 13.4728C9.21353 13.3839 9.32657 13.334 9.44444 13.334H11.1387C11.2565 13.334 11.3696 13.3839 11.4529 13.4728C11.5363 13.5617 11.5831 13.6823 11.5831 13.808C11.5831 13.9338 11.5363 14.0543 11.4529 14.1432C11.3696 14.2321 11.2565 14.2821 11.1387 14.2821Z"
        fill={color}
      />
      <path
        d="M11.1387 16.2059H9.44444C9.32657 16.2059 9.21353 16.156 9.13018 16.0671C9.04683 15.9782 9 15.8576 9 15.7319C9 15.6061 9.04683 15.4856 9.13018 15.3967C9.21353 15.3078 9.32657 15.2578 9.44444 15.2578H11.1387C11.2565 15.2578 11.3696 15.3078 11.4529 15.3967C11.5363 15.4856 11.5831 15.6061 11.5831 15.7319C11.5831 15.8576 11.5363 15.9782 11.4529 16.0671C11.3696 16.156 11.2565 16.2059 11.1387 16.2059Z"
        fill={color}
      />
      <path
        d="M12 18.1385H9.44444C9.32657 18.1385 9.21353 18.0886 9.13018 17.9997C9.04683 17.9108 9 17.7902 9 17.6645C9 17.5388 9.04683 17.4182 9.13018 17.3293C9.21353 17.2404 9.32657 17.1904 9.44444 17.1904H12C12.1179 17.1904 12.2309 17.2404 12.3143 17.3293C12.3976 17.4182 12.4444 17.5388 12.4444 17.6645C12.4444 17.7902 12.3976 17.9108 12.3143 17.9997C12.2309 18.0886 12.1179 18.1385 12 18.1385Z"
        fill={color}
      />
    </svg>
  );
};
RulerIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default RulerIcon;

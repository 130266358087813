import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { XYZ } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, PageHeader, Spin, Typography } from 'antd';
import ListIcon from 'assets/svg/ListIcon';
import MapIcon from 'assets/svg/MapIcon';
import { white } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import MeasurementTool from 'components/WorkspaceOlMap/MeasurementTool';
import RenderProjectListPoint from 'components/WorkspaceOlMap/RenderProjectListPoint';
import AddProject from 'components/Modal/AddProject/AddProjectModal';
import ProjectFilter from 'components/core/ProjectFilter';
import MapControls from 'components/WorkspaceOlMap/MapControls';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import HeaderLayout from 'layouts/HeaderLayout';
import { ProjectState, ProjectType } from 'store/projects/types';
import { clearProjects, getProjectLists } from 'store/projects';
import { UserPermission, UserRole } from 'store/users/types';
import { getAvailableProjectMembers, getProjects } from 'store/projects/actions';
import { clearRepofiles } from 'store/repositoryfile';
import { clearAoi } from 'store/aoi';
import { clearRoute } from 'store/route';
import { Maptype } from 'types/common.types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import { hybridUrl, roadmapUrl } from 'utils/apiUrls';
import 'components/WorkspaceOlMap/styles.less';
import './styles.less';

const { Text } = Typography;

const ProjectMapView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = getStorageValue(USER_ROLE);
  const { isLoadingProjects }: ProjectState = useAppSelector((state) => state.projects);
  const mapElement = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null | undefined>(null);
  const [filterText, setFilterText] = useState<string>('');
  const [isRulerMeasurement, setRulerMeasurement] = useState<boolean>(false);
  const [mapLayerType, setMapLayerType] = useState<Maptype>(Maptype.satellite);
  const [isOpenAddProjectModal, setAddProjectModal] = useState<boolean>(false);
  const projectDataFilter: Array<ProjectType> | [] = useAppSelector((state) =>
    getProjectLists(state, filterText)
  );

  useEffect(() => {
    dispatch(clearRepofiles());
    dispatch(clearAoi());
    dispatch(clearRoute());
    dispatch(clearProjects());
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getProjects(token));
    });
  }, []);

  useEffect(() => {
    if (filterText && projectDataFilter.length === 0) {
      toast.error('Project not found');
    }
  }, [filterText, projectDataFilter]);

  const googleMapsLayer = new TileLayer({
    source: new XYZ({
      url: roadmapUrl
    })
  });

  useEffect(() => {
    if (mapElement.current && !mapRef.current) {
      mapRef.current = new Map({
        target: mapElement.current,
        layers: [googleMapsLayer],
        view: new View({
          center: fromLonLat([78.0, 21.0]),
          zoom: 5
        }),
        controls: []
      });
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const newLayer = new TileLayer({
        source: new XYZ({
          url: mapLayerType === Maptype.satellite ? hybridUrl : roadmapUrl
        })
      });

      mapRef.current.getLayers().setAt(0, newLayer);
    }
  }, [mapLayerType, mapRef, isLoadingProjects]);

  const handleMapType = (layer: Maptype) => {
    setMapLayerType(layer);
  };

  const handleOpenAddProjectModal = () => {
    if (userRole === UserPermission.WorkspaceManager) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getAvailableProjectMembers(token));
      });
    }
    setAddProjectModal(true);
  };

  const handleChangeRulerMeasurement = () => {
    setRulerMeasurement(!isRulerMeasurement);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModal(false);
  };

  const mapContainer = useMemo(() => {
    return (
      <div
        className="workspace-map-container project-mapview-container"
        style={{ height: 'calc(100% - 72px)', padding: '0 24px 24px 24px' }}>
        <div ref={mapElement} style={{ width: '100%', height: '100%' }} />
        <MapControls
          map={mapRef.current}
          isWorkspace={false}
          isShowRecenter
          latlngA={null}
          latlngB={null}
          mapType={mapLayerType}
          handleMapType={handleMapType}
          isRulerMeasurement={isRulerMeasurement}
          isShowTowerText={false}
          handleShowHideTowerText={() => {}}
          handleChangeRulerMeasurement={handleChangeRulerMeasurement}
        />
        {mapRef.current && (
          <RenderProjectListPoint
            map={mapRef.current}
            projectList={projectDataFilter}
            filterText={filterText}
          />
        )}
        {isRulerMeasurement && <MeasurementTool map={mapRef.current} />}
      </div>
    );
  }, [mapRef, isRulerMeasurement, mapLayerType, projectDataFilter, isLoadingProjects, filterText]);

  return (
    <>
      <HeaderLayout />
      <ToastContainer />
      <Spin spinning={isLoadingProjects}>
        <div className="main-map-view">
          <PageHeader
            className="main-title-content"
            ghost={false}
            title={
              <>
                <Text>Projects</Text>
                <ProjectFilter
                  onFilter={(filterValue: string) => setFilterText(filterValue)}
                  filterText={filterText}
                />
              </>
            }
            extra={[
              userRole === UserRole.WorkspaceManager && (
                <Button
                  key="createproject"
                  type="primary"
                  className="topContentBtn"
                  onClick={handleOpenAddProjectModal}>
                  Create project
                </Button>
              ),
              <Button.Group className="list-map-grp-btn">
                <Button
                  key="list"
                  icon={<ListIcon />}
                  onClick={() => navigate(routes.ProjectList)}
                />
                <Button key="map" className="active-view-btn" icon={<MapIcon color={white} />} />
              </Button.Group>
            ]}
          />
          {mapContainer}
        </div>
      </Spin>
      <Modal
        open={isOpenAddProjectModal}
        okText="Create"
        cancelText="Cancel"
        centered
        width={697}
        className="newModal"
        closable={false}
        footer={null}>
        <AddProject handleCloseAddProjectModal={handleCloseAddProjectModal} />
      </Modal>
    </>
  );
};

export default ProjectMapView;

import React, { useState, useEffect } from 'react';
import { Table, InputNumber } from 'antd';

interface Featuretype {
  key: string;
  featuretype: string;
  area: number | string;
  unit: number;
  categoryId: number;
  cost: number;
}

interface RouteDetail {
  area_cross_stat: {
    file_cat_id: number;
    file_cat_name: string;
    area_km_sq: number;
    unit_cost: number | null;
  }[];
}

interface TotalCostDetailsProps {
  selectedRouteData: RouteDetail;
  onTotalCostChange: (totalCost: number) => void;
  onPayloadChange?: (payload: any[]) => void;
}

const TotalCostDetails: React.FC<TotalCostDetailsProps> = ({
  selectedRouteData,
  onTotalCostChange,
  onPayloadChange = () => {}
}) => {
  const [dataSource, setDataSource] = useState<Featuretype[]>([]);

  useEffect(() => {
    const areaCrossStat = selectedRouteData.area_cross_stat || [];
    const transformedData: Featuretype[] = areaCrossStat.map((item, index) => ({
      key: index.toString(),
      categoryId: item.file_cat_id,
      featuretype: item.file_cat_name,
      area: item.area_km_sq,
      unit: item.unit_cost ?? 0,
      cost: item.area_km_sq * (item.unit_cost ?? 0)
    }));
    setDataSource(transformedData);
  }, [selectedRouteData]);

  const handleUnitCostChange = (value: number | null, key: React.Key) => {
    const newData = dataSource.map((item) => {
      if (item.key === key) {
        const { area } = item;
        const numericArea = typeof area === 'number' ? area : parseFloat(area);
        const newCost = numericArea * (value ?? 0);
        return {
          ...item,
          unit: value ?? 0,
          cost: newCost
        };
      }
      return item;
    });
    setDataSource(newData);
  };

  const calculateTotalCost = () => {
    const totalCost = dataSource.reduce((total, { cost }) => {
      return total + cost;
    }, 0);
    return totalCost;
  };

  useEffect(() => {
    const totalCost = calculateTotalCost();
    onTotalCostChange(totalCost);

    const payload = dataSource.map(({ featuretype, area, unit, categoryId }) => ({
      file_cat_name: featuretype,
      area_km_sq: area,
      unit_cost: unit,
      file_cat_id: categoryId
    }));
    onPayloadChange(payload);
  }, [dataSource]);

  const totalCost = calculateTotalCost();
  const dataSourceWithTotal = [
    ...dataSource,
    {
      key: 'total',
      featuretype: '',
      area: '',
      unit: 0,
      categoryId: 0,
      cost: totalCost
    }
  ];

  const columns = [
    {
      title: 'Feature Type',
      dataIndex: 'featuretype',
      key: 'featuretype'
    },
    {
      title: 'Total Area Crossing (RoW Width x Length of Crossing)',
      dataIndex: 'area',
      key: 'area',
      render: (text: number | string) => (
        <span>{typeof text === 'number' ? text.toFixed(2) : text}</span>
      )
    },
    {
      title: 'Unit Cost (INR per sq km)',
      dataIndex: 'unit',
      key: 'unit',
      render: (text: number, record: Featuretype) =>
        record.key !== 'total' && (
          <InputNumber
            value={text}
            min={0}
            step={0.1}
            placeholder="0.0"
            style={{ width: '70%', fontSize: '12px', color: '#316db3' }}
            controls={false}
            onChange={(value) => handleUnitCostChange(value, record.key)}
          />
        )
    },
    {
      title: 'Total RoW Cost (INR)',
      dataIndex: 'cost',
      key: 'cost',
      render: (text: number) => <span>{text.toLocaleString()}</span>
    }
  ];

  return (
    <div className="tower-detail-content">
      <Table
        scroll={{ y: '' }}
        className="tower-table"
        dataSource={dataSourceWithTotal}
        pagination={false}
        columns={columns}
      />
    </div>
  );
};

TotalCostDetails.defaultProps = {
  onPayloadChange: () => {}
};

export default TotalCostDetails;

import { useEffect, useState } from 'react';
import { find, findIndex, includes, isEmpty, keys, orderBy, remove, uniq } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ACCESS_TOKEN, aoiRouteColors } from 'constants/common.constant';
import { defaultGrey, lightred1 } from 'constants/theme.constants';
import BeeLinePointsForm from 'components/WorkspaceSidebar/BeeLinePointsForm';
import AOIApproveConfirmModal from 'components/Modal/AOIApproveConfirmModal';
import AoiGenerationStep from 'components/WorkspaceSidebar/AoiGenerationStep';
import ConfirmModal from 'components/Modal/ConfirmModal';
import WorkspaceMap from 'components/WorkspaceOlMap/WorkspaceMap';
import DeclineModal from 'components/Modal/declineModal';
import WorkspaceStep from 'components/WorkspaceSidebar/WorkspaceStep';
import EditBufferModal from 'components/Modal/EditBufferModal';
import LulcGenerateOkayModal from 'components/Modal/LulcGenerateOkayModal';
import RouteGenerationStep from 'components/WorkspaceSidebar/RouteGenerationStep';
import PMActionButtons from 'components/WorkspaceSidebar/PMActionButtons';
import MemberActionButtons from 'components/WorkspaceSidebar/MemberActionButtons';
import WMActionButtons from 'components/WorkspaceSidebar/WMActionButtons';
import AOIApproveHighResConfirmModal from 'components/Modal/AOIApproveHighResConfirmModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  addBeelinePointsCoords,
  appproveDeclineAOI,
  generateAOI,
  getAOILists,
  initialGenerateLULCLayer,
  initiateRequestHighRes
} from 'store/aoi/actions';
import { getLulcLayerDetails, getProject } from 'store/projects/actions';
import { getRepositoryFiles } from 'store/repositoryfile/actions';
import { RepositoryFile } from 'store/repositoryfile/types';
import {
  AOIDetail,
  AoiState,
  AOIStaus,
  AOIStausType,
  ApproveDeclineAoiPayload,
  BeeLinePayload,
  GenerateAoiPayload,
  HiResPayload
} from 'store/aoi/types';
import { LULCLayerType, ProjectStagesList, ProposedTimelineStatus } from 'store/projects/types';
import { UserPermission } from 'store/users/types';
import { GenerateRoutePayload, RouteDetail } from 'store/route/types';
import {
  activeHighresLayersFile,
  activeLowresLayersFile,
  getAOILayersGroupByCategory,
  getRouteLayersGroupByCategory,
  setUpdateAllLayersWeightBuffer,
  setUpdateLayerWeightBuffer
} from 'store/repositoryfile';
import { generateRoute, getRouteLists, getTowerTypeOption } from 'store/route/actions';
import { setLULCLayerDetails } from 'store/projects';
import { LatLongNumberType } from 'types/aoi.types';
import { PostResponse } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import 'components/WorkspaceSidebar/styles.less';
import './styles.less';

const Workspace = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { selectedProject, LulcLayerDetails } = useAppSelector((state) => state.projects);
  const { aoiLists, isRequestHighRes }: AoiState = useAppSelector((state) => state.aoi);
  const layers: RepositoryFile[] | [] = useAppSelector((state) =>
    state.repofiles.repositoryFiles.filter((file: RepositoryFile) => file.active)
  );

  const activeLowLayerFiles: RepositoryFile[] | [] = useAppSelector(activeLowresLayersFile);
  const activeHighLayerFiles: RepositoryFile[] | [] = useAppSelector(activeHighresLayersFile);
  const aoiLayerCategory = useAppSelector((state) => getAOILayersGroupByCategory(state));
  const routeLayerCategory = useAppSelector((state) => getRouteLayersGroupByCategory(state));
  const [searchParams, setSearchParams] = useSearchParams();
  const [isConfirmBeeLine, setConfirmBeeLine] = useState(false);
  const [isLayerGen, setLayerGen] = useState(false);
  const [isLayerGenReq, setLayerGenReq] = useState(false);
  const [selectedAoiIds, setSelectedAoiIds] = useState<string[]>([]);
  const [selectedRoutesIds, setSelectedRoutesIds] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [latlngA, setLatlngA] = useState<LatLongNumberType | null>(null);
  const [latlngB, setLatlngB] = useState<LatLongNumberType | null>(null);
  const [vectorLayerCategories, setVectorLayerCategories] = useState<string[]>([]);
  const [selectedLayer, setSelectedLayer] = useState<number | null>(null);
  const [layerWeight, setLayerWeight] = useState<number>(0);
  const [isBeelineBtnDisable, setBeelineBtnDisable] = useState<boolean>(false);
  const [isShowHideWeightSlider, setWeightSlider] = useState<boolean>(false);
  const [isOpenSentForApprovalModal, setSentForApprovalModal] = useState<boolean>(false);
  const [isOpenSentApprovalOkayModal, setSentApprovalOkayModal] = useState<boolean>(false);
  const [isOpenApproveAOIModal, setApproveAOIModal] = useState<boolean>(false);
  const [isOpenApprovalOkayModal, setApprovalOkayModal] = useState<boolean>(false);
  const [isOpenDeclineAOIModal, setDeclineAOIModal] = useState<boolean>(false);
  const [isOpenApprovePurchaseModal, setApprovePurchaseModal] = useState<boolean>(false);
  const [isOpenRecievedPurchaseModal, setRecievedPurchaseModal] = useState<boolean>(false);
  const [isOpenEditBufferModal, setEditBufferModal] = useState<boolean>(false);
  const [isOpenGenerateAOIModal, setGenerateAOIModal] = useState<boolean>(false);
  const [isOpenGenerateRouteModal, setGenerateRouteModal] = useState<boolean>(false);
  const [selectedAOIData, setSelectedAOIData] = useState<AOIDetail | null>(null);
  const [selectedRouteData, setSelectedRouteData] = useState<RouteDetail | null>(null);
  const [selectedEditBufferData, setSelectedEditBufferData] = useState<RepositoryFile[] | []>([]);
  const [editedAOIData, setEditedAOIData] = useState<AOIDetail | null>(null);
  const [editedRouteData, setEditedRouteData] = useState<RouteDetail | null>(null);
  const [editAOICoordsData, setEditAOICoordsData] = useState<any>(null);
  const [editRouteCoordsData, setEditRouteCoordsData] = useState<any>(null);
  const [declineComment, setDeclineComment] = useState<string | null>(null);
  const [isGozoneLayers, setGozoneLayers] = useState<boolean>(false);
  const [isNoGozoneLayers, setNoGozoneLayers] = useState<boolean>(false);
  const [isShowTowerText, setShowTowerText] = useState(true);

  const isLulcGenInprogress = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.LULCLow,
      status: ProposedTimelineStatus.InProgress
    })
  );

  const isLulcGenCompleted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.LULCLow,
      status: ProposedTimelineStatus.Completed
    })
  );

  const isBeeLineCompleted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.BeeLineRequest,
      status: ProposedTimelineStatus.Completed
    })
  );

  const isSatelliteInprogress = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.SatelliteConfirm,
      status: ProposedTimelineStatus.InProgress
    })
  );

  const isSatelliteConfirmApproved = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.SatelliteConfirm,
      status: ProposedTimelineStatus.Approved
    })
  );

  const isSatelliteProcurementInProgress = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.SatelliteProcurement,
      status: ProposedTimelineStatus.InProgress
    })
  );

  const isHighResLULCGeneration = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.LULCHigh,
      status: ProposedTimelineStatus.Completed
    })
  );

  const isHighResLULCInProgress = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.LULCHigh,
      status: ProposedTimelineStatus.InProgress
    })
  );

  const isAOIConfirmApproved = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.AOIConfirmation,
      status: ProposedTimelineStatus.Approved
    })
  );

  const isAOIGenerationNotStarted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.AOIGeneration,
      status: ProposedTimelineStatus.NotStarted
    })
  );

  const isRouteGenerationNotStarted = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.RouteGeneration,
      status: ProposedTimelineStatus.NotStarted
    })
  );

  useEffect(() => {
    if (id) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(setLULCLayerDetails(null));
        dispatch(getProject(Number(id), token));
        dispatch(
          getAOILists(Number(id), token, (data: AOIDetail[] | []) => {
            const id = searchParams.get('aoiId');
            const selectedData = find(data, ['id', id]);
            if (selectedData) {
              const index: number = findIndex(orderBy(data, ['requested_at'], ['asc']), [
                'id',
                selectedData.id
              ]);

              const aoiData = {
                ...selectedData,
                color:
                  selectedData.status === AOIStausType.invalid
                    ? lightred1
                    : aoiRouteColors[index] || defaultGrey,
                index: index + 1
              };
              setSelectedAOIData(aoiData);
              setSelectedAoiIds([selectedData.id]);

              // eslint-disable-next-line
              handleNoGozoneLayers(selectedData.status === AOIStausType.invalid, aoiData, null);
            }
          })
        );
        dispatch(
          getRouteLists(Number(id), token, (data: RouteDetail[] | []) => {
            const id = searchParams.get('routeId');
            const selectedData = find(data, ['id', id]);
            if (selectedData) {
              const index: number = findIndex(orderBy(data, ['requested_at'], ['asc']), [
                'id',
                selectedData.id
              ]);
              const routeData = {
                ...selectedData,
                color:
                  selectedData.status === AOIStausType.invalid
                    ? lightred1
                    : aoiRouteColors[index] || defaultGrey,
                index: index + 1
              };
              setSelectedRouteData(routeData);
              setSelectedRoutesIds([selectedData.id]);
              // eslint-disable-next-line
              handleNoGozoneLayers(selectedData.status === AOIStausType.invalid, null, routeData);
            }
          })
        );
        if (isLulcGenInprogress || isAOIGenerationNotStarted) {
          dispatch(getLulcLayerDetails(Number(id), LULCLayerType.lulclow, token));
        }
        if (isHighResLULCInProgress || isRouteGenerationNotStarted) {
          dispatch(getLulcLayerDetails(Number(id), LULCLayerType.lulchigh, token));
        }
        dispatch(getTowerTypeOption(token));
        dispatch(getRepositoryFiles(Number(id), token));
      });
    }
  }, []);

  useEffect(() => {
    if (selectedProject) {
      if (selectedProject.start_point) {
        setLatlngA({ lat: selectedProject.start_point.lat, lng: selectedProject.start_point.lng });
      }
      if (selectedProject.end_point) {
        setLatlngB({ lat: selectedProject.end_point.lat, lng: selectedProject.end_point.lng });
        if (isHighResLULCGeneration) {
          setCurrentStep(2);
          return;
        }
        setCurrentStep(1);
      }
    }
  }, [selectedProject]);

  const handleDisableBeelineBtn = (isDisable: boolean) => {
    setBeelineBtnDisable(isDisable);
  };

  const handlePointASubmit = (values: { startlatA: number; startlngA: number }) => {
    setLatlngA({ lat: values.startlatA, lng: values.startlngA });
  };

  const handlePointBSubmit = (values: { endlatB: number; endlngB: number }) => {
    setLatlngB({ lat: values.endlatB, lng: values.endlngB });
  };

  const handleConfirmBeeLine = () => {
    setConfirmBeeLine(true);
  };

  const handleConfirmBeelineCall = () => {
    if (!(latlngA && latlngA.lat && latlngA.lng && latlngB && latlngB.lat && latlngB.lng)) return;
    const payload: BeeLinePayload = {
      start_point: `${latlngA.lat!},${latlngA.lng}`,
      end_point: `${latlngB.lat},${latlngB.lng}`
    };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        addBeelinePointsCoords(payload, selectedProject?.id, token, () => {
          setLatlngA(null);
          setLatlngB(null);
        })
      );
    });
    setConfirmBeeLine(false);
  };

  const handleCancelBeeline = () => {
    setConfirmBeeLine(false);
  };

  const handleChangeStep = (val: number) => {
    if (selectedProject.start_point) {
      setCurrentStep(val);
    }
    if (currentStep !== val) {
      setLayerWeight(0);
      setSelectedAOIData(null);
      setSelectedRouteData(null);
      setEditedAOIData(null);
      setEditAOICoordsData(null);
      setEditRouteCoordsData(null);
      setSelectedAoiIds([]);
      setSelectedRoutesIds([]);
      setVectorLayerCategories([]);
      setGozoneLayers(false);
      setNoGozoneLayers(false);
      setShowTowerText(true);
      // @ts-ignore
      setSearchParams((params) => {
        params.delete('aoiId');
        params.delete('routeId');
        return params;
      });
    }
  };

  const handleNextStep = () => {
    setCurrentStep(1);
  };

  const handleInitiateLayerGeneration = () => {
    setLayerGen(true);
  };

  const handleConfirmLayerGeneration = () => {
    setLayerGen(false);
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        initialGenerateLULCLayer(selectedProject?.id, token, (d: PostResponse) => {
          if (d.status) {
            dispatch(
              getLulcLayerDetails(selectedProject?.id, LULCLayerType.lulclow, token, () =>
                setLayerGenReq(true)
              )
            );
            setLayerGenReq(true);
            toast.success(d.message);
            return;
          }
          toast.error(d.message);
        })
      );
    });
  };

  const handleCancelLayerGeneration = () => {
    setLayerGen(false);
  };

  const handleLayerGenerationReqOkay = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(getProject(selectedProject?.id, token));
    });
    setLayerGenReq(false);
  };

  const handleVectorLayerIds = (category: string) => {
    if (includes(vectorLayerCategories, category)) {
      remove(vectorLayerCategories, (cat: string) => cat === category);
    } else {
      vectorLayerCategories.push(category);
    }

    setVectorLayerCategories([...vectorLayerCategories]);
  };

  const handleGozoneLayers = (isGozone: boolean) => {
    let catArr: string[] = [];
    if (currentStep === 1 && selectedAOIData) {
      const { vectors } = selectedAOIData.parameters;
      catArr = uniq(
        vectors
          .filter((data: GenerateAoiPayload) => isGozone && Number(data.weight) === 0)
          .map((data: GenerateAoiPayload) => data.file_cat)
      );

      if (isGozone) setVectorLayerCategories(catArr);
    } else if (currentStep === 2 && selectedRouteData) {
      const { vectors } = selectedRouteData.parameters;
      catArr = uniq(
        vectors
          .filter((data: GenerateRoutePayload) => isGozone && Number(data.weight) === 0)
          .map((data: GenerateRoutePayload) => data.file_cat)
      );

      if (isGozone) setVectorLayerCategories(catArr);
    }
    setGozoneLayers(isGozone);
  };

  const handleNoGozoneLayers = (
    isNoGozone: boolean,
    adata?: AOIDetail | null | undefined,
    rdata?: RouteDetail | null | undefined
  ) => {
    let catArr: string[] = [];

    if (currentStep === 1) {
      let vectorsData: GenerateAoiPayload[] | [] = [];
      if (adata) {
        vectorsData = adata.parameters.vectors;
      } else if (selectedAOIData) {
        vectorsData = selectedAOIData.parameters.vectors;
      }
      catArr = uniq(
        vectorsData
          .filter((data: GenerateAoiPayload) => isNoGozone && Number(data.weight) === 100)
          .map((data: GenerateAoiPayload) => data.file_cat)
      );

      if (isNoGozone) setVectorLayerCategories(catArr);
    } else if (currentStep === 2) {
      let vectorsData: GenerateRoutePayload[] | [] = [];
      if (rdata) {
        vectorsData = rdata.parameters.vectors;
      } else if (selectedRouteData) {
        vectorsData = selectedRouteData.parameters.vectors;
      }

      catArr = uniq(
        vectorsData
          .filter((data: GenerateRoutePayload) => isNoGozone && Number(data.weight) === 100)
          .map((data: GenerateRoutePayload) => data.file_cat)
      );

      if (isNoGozone) setVectorLayerCategories(catArr);
    }
    setNoGozoneLayers(isNoGozone);
  };

  const handleCloseGoNogoZoneFilter = () => {
    setNoGozoneLayers(false);
    setGozoneLayers(false);
    setVectorLayerCategories([]);
  };

  const handleChangeLayerPosition = (fileId: number, weight: number) => {
    setLayerWeight(weight);
    setWeightSlider(!isShowHideWeightSlider);
    setSelectedLayer(fileId);
  };

  const handleFileWeightChange = (val: number) => {
    setLayerWeight(val);
  };

  const handleAfterFileWeightChange = (val: number, files: RepositoryFile[]) => {
    dispatch(setUpdateLayerWeightBuffer({ weight: val, files }));
  };

  const handleGenerateAoiData = () => {
    if (isOpenGenerateAOIModal) {
      if (!isEmpty(activeLowLayerFiles)) {
        const payload: GenerateAoiPayload[] | [] = activeLowLayerFiles.map(
          (file: RepositoryFile) => {
            return {
              file_id: Number(file.id),
              weight: file.weight ? Number(file.weight) : 0,
              buffer: Number(file.buffer),
              file_name: file.file_name,
              file_cat: file.category
            };
          }
        );
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            generateAOI({ vectors: payload }, selectedProject.id, token, () =>
              dispatch(setLULCLayerDetails(null))
            )
          );
        });
      } else {
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(
            generateAOI({ vectors: [] }, selectedProject.id, token, () =>
              dispatch(setLULCLayerDetails(null))
            )
          );
        });
      }
    }
    setGenerateAOIModal(!isOpenGenerateAOIModal);
    setWeightSlider(false);
  };

  // const handleShowHideSelectedAoiIds = (fileId: string) => {
  //   if (includes(selectedAoiIds, fileId)) {
  //     remove(selectedAoiIds, (aoi: string) => aoi === fileId);
  //   } else {
  //     selectedAoiIds.push(fileId);
  //   }

  //   setSelectedAoiIds([...selectedAoiIds]);
  // };

  const handleShowHideSelectedAoiIds = (fileId: string) => {
    if (includes(selectedAoiIds, fileId)) {
      setSelectedAoiIds([]);
    } else {
      setSelectedAoiIds([fileId]);
    }
  };

  const handleSelectedAOI = (data: AOIDetail | null) => {
    if (data?.id === selectedAOIData?.id) {
      setSelectedAOIData(null);
      dispatch(setUpdateAllLayersWeightBuffer({ layers: [] }));
      // @ts-ignore
      setSearchParams((params) => {
        params.delete('aoiId');
        return params;
      });
    } else {
      setSelectedAOIData(data);
      setSearchParams({ aoiId: data?.id! });
      dispatch(setUpdateAllLayersWeightBuffer({ layers: data?.parameters.vectors }));
      if (data && data.status === AOIStausType.invalid) {
        handleNoGozoneLayers(true, data, null);
      }
    }
  };

  const handleEditAOI = (data: AOIDetail | null) => {
    // if (data?.status === AOIStausType.wmapproved) {
    //   toast.error('AOI is approved by WM, Yo can`t edit AOI.');
    //   return;
    // }
    setEditedAOIData(data);
    setSelectedAOIData(null);
    setEditAOICoordsData(null);
    if (data) setSelectedAoiIds([data.id]);
  };

  const handleCloseEditMode = () => {
    setEditedAOIData(null);
    setEditAOICoordsData(null);
    setEditedRouteData(null);
    setEditRouteCoordsData(null);
    setSelectedAOIData(null);
    setSelectedRouteData(null);
    setSelectedAoiIds([]);
    setVectorLayerCategories([]);
    setVectorLayerCategories([]);
    setSelectedRoutesIds([]);
    setSelectedRouteData(null);
  };

  const handleSentForApprovalAOICancel = () => {
    setSentForApprovalModal(false);
  };

  const handleSentForApprovalAOI = () => {
    setSentForApprovalModal(true);
  };

  const handleRecievedSentApprovalOkay = () => {
    setSentApprovalOkayModal(false);
    handleSentForApprovalAOICancel();
  };

  const handleSentForApprovalAOIOkay = () => {
    const payload: ApproveDeclineAoiPayload = {
      status: AOIStaus.sentforapproval,
      comment: ''
    };
    if (selectedAOIData) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          appproveDeclineAOI(payload, selectedProject.id, selectedAOIData.id, token, () => {
            setSentForApprovalModal(false);
            setSentApprovalOkayModal(true);
            dispatch(getProject(selectedProject?.id, token));
          })
        );
      });
    }
  };

  const handleSaveEditAOICoordsData = (coords: any) => {
    setEditAOICoordsData(coords);
  };

  const handleEditAOICallback = () => {
    setEditAOICoordsData(null);
    setEditedAOIData(null);
    setVectorLayerCategories([]);
    setSelectedAoiIds([]);
    setSelectedAOIData(null);
  };

  /* Route Generation */
  // const handleShowHideRoute = (fileId: string) => {
  //   if (includes(selectedRoutesIds, fileId)) {
  //     remove(selectedRoutesIds, (route: string) => route === fileId);
  //   } else {
  //     selectedRoutesIds.push(fileId);
  //   }

  //   setSelectedRoutesIds([...selectedRoutesIds]);
  // };

  const handleShowHideRoute = (fileId: string) => {
    if (includes(selectedRoutesIds, fileId)) {
      setSelectedRoutesIds([]);
    } else {
      setSelectedRoutesIds([fileId]);
    }
  };

  const handleGenerateRoute = () => {
    if (isOpenGenerateRouteModal) {
      if (!isEmpty(activeHighLayerFiles)) {
        const payload: GenerateRoutePayload[] | [] = activeHighLayerFiles.map(
          (file: RepositoryFile) => {
            return {
              file_id: Number(file.id),
              weight: file.weight ? Number(file.weight) : 0,
              buffer: Number(file.buffer),
              file_name: file.file_name,
              file_cat: file.category
            };
          }
        );

        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(generateRoute({ vectors: payload }, selectedProject.id, token));
        });
      } else {
        getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
          dispatch(generateRoute({ vectors: [] }, selectedProject.id, token));
        });
      }
    }

    setGenerateRouteModal(!isOpenGenerateRouteModal);
  };

  const handleApproveByWMOrPM = () => {
    if (selectedProject?.user_permission === UserPermission.WorkspaceManager) {
      setApprovePurchaseModal(true);
    } else {
      setApproveAOIModal(true);
    }
  };

  const handleDeclineByWMOrPM = () => {
    setDeclineAOIModal(true);
  };

  const handleApproveDeclineWMorPMCancel = () => {
    setApproveAOIModal(false);
    setDeclineAOIModal(false);
  };

  const handleApproveHighResSatOkay = () => {
    if (!selectedAOIData) return;

    const payload: HiResPayload = {
      project_id: selectedProject?.id,
      aoi_id: selectedAOIData.id
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        initiateRequestHighRes(payload, token, (d: PostResponse) => {
          if (d.status) {
            setApprovePurchaseModal(false);
            dispatch(getProject(selectedProject?.id, token));
            toast.success(d.message);
            return;
          }
          setApprovePurchaseModal(false);
          toast.error(d.message);
        })
      );
    });
  };

  const handleRecievedHighResSatOkay = () => {
    setRecievedPurchaseModal(false);
    setApprovePurchaseModal(false);
  };

  const handleRecievedAOIApprovalOkay = () => {
    setApprovalOkayModal(false);
    setApproveAOIModal(false);
  };

  const handleSetDeclineComment = (comment: string | null) => {
    setDeclineComment(comment);
  };

  const handleCallApproveDeclineAOIapi = (
    payload: ApproveDeclineAoiPayload,
    aoidata: AOIDetail | null
  ) => {
    if (aoidata) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(
          appproveDeclineAOI(payload, selectedProject.id, aoidata.id, token, () => {
            handleSetDeclineComment(null);
            if (payload.status === AOIStaus.wmapproved || payload.status === AOIStaus.pmapproved) {
              if (selectedProject.user_permission === UserPermission.WorkspaceManager) {
                handleApproveHighResSatOkay();
              } else {
                setDeclineAOIModal(false);
                setApproveAOIModal(false);
                setApprovalOkayModal(true);
                dispatch(getProject(selectedProject?.id, token));
              }
            } else {
              setDeclineAOIModal(false);
              setApproveAOIModal(false);
              dispatch(getProject(selectedProject?.id, token));
            }
          })
        );
      });
    }
  };

  const handleApproveWMorPMOkay = () => {
    const userPermission = selectedProject.user_permission;
    const payload: ApproveDeclineAoiPayload = {
      status:
        userPermission === UserPermission.WorkspaceManager
          ? AOIStaus.wmapproved
          : AOIStaus.pmapproved,
      comment: ''
    };
    handleCallApproveDeclineAOIapi(payload, selectedAOIData);
  };

  const handleDeclineWMorPMOkay = () => {
    const payload: ApproveDeclineAoiPayload = {
      status: AOIStaus.declined,
      comment: declineComment || ''
    };
    handleCallApproveDeclineAOIapi(payload, selectedAOIData);
  };

  const handleApproveHighResSatCancel = () => {
    setApprovePurchaseModal(false);
  };

  const handleEditBuffer = (file: RepositoryFile[]) => {
    setEditBufferModal(true);
    setSelectedEditBufferData(file);
  };

  const handleEditBufferOkay = async (val: number | undefined) => {
    if (selectedEditBufferData) {
      dispatch(
        setUpdateLayerWeightBuffer({
          buffer: val || 0,
          files: selectedEditBufferData
        })
      );
    }
    setEditBufferModal(false);
  };

  const handleEditBufferCancel = () => {
    setEditBufferModal(false);
    setSelectedEditBufferData([]);
  };

  const handleSelectRouteDetail = (data: RouteDetail) => {
    if (selectedRouteData?.id === data.id) {
      setSelectedRouteData(null);
      dispatch(setUpdateAllLayersWeightBuffer({ layers: [] }));
      // @ts-ignore
      setSearchParams((params) => {
        params.delete('routeId');
        return params;
      });
      return;
    }
    setSearchParams({ routeId: data.id });
    setSelectedRouteData(data);
    dispatch(setUpdateAllLayersWeightBuffer({ layers: data?.parameters.vectors }));
    if (data && data.status === AOIStausType.invalid) {
      handleNoGozoneLayers(true, null, data);
    }
  };

  const handleEditRoute = (data: RouteDetail) => {
    setEditedRouteData(data);
    setEditRouteCoordsData(null);
    setSelectedRoutesIds([]);
    setSelectedRouteData(null);
  };

  const handleSaveEditRouteCoordsData = (coords: any) => {
    setEditRouteCoordsData(coords);
  };

  const handleEditRouteCallback = () => {
    setEditRouteCoordsData(null);
    setEditedRouteData(null);
    setVectorLayerCategories([]);
    setSelectedRoutesIds([]);
    setSelectedRouteData(null);
  };

  const handleChangeDoneLatngA = (data: number[]) => {
    setLatlngA({ lat: data[1], lng: data[0] });
  };

  const handleChangeDoneLatngB = (data: number[]) => {
    setLatlngB({ lat: data[1], lng: data[0] });
  };

  const handleShowHideLayers = (isshowhide: boolean) => {
    if (isshowhide) {
      setVectorLayerCategories(
        currentStep === 1 ? keys(aoiLayerCategory) : keys(routeLayerCategory)
      );
    } else {
      setVectorLayerCategories([]);
    }
  };

  const handleHideSlider = () => {
    setWeightSlider(false);
    setSelectedLayer(null);
  };

  const handleShowHideTowerText = () => {
    setShowTowerText((prev) => !prev);
  };

  return (
    <>
      <div className="workspace-main-content">
        <div className="workspace-header">
          <WorkspaceStep
            isBeeLineCompleted={isBeeLineCompleted}
            isHighResLULCGeneration={isHighResLULCGeneration}
            currentStep={currentStep}
            editedAOIData={editedAOIData}
            editedRouteData={editedRouteData}
            handleChangeStep={handleChangeStep}
          />
        </div>
        <div className="main-aoi-content">
          <div className="left-content workspace-left-content">
            {currentStep === 0 && (
              <BeeLinePointsForm
                latlngA={latlngA}
                latlngB={latlngB}
                isDisableA={!isEmpty(selectedProject?.start_point)}
                isDisableB={!isEmpty(selectedProject?.end_point)}
                handleDisableBeelineBtn={handleDisableBeelineBtn}
                handlePointASubmit={handlePointASubmit}
                handlePointBSubmit={handlePointBSubmit}
              />
            )}
            {currentStep === 1 && (
              <AoiGenerationStep
                isRouteGeneration={false}
                editedAOIData={editedAOIData}
                selectedAOIData={selectedAOIData}
                vectorLayerCategories={vectorLayerCategories}
                layers={layers}
                selectedLayer={selectedLayer}
                layerWeight={layerWeight}
                selectedAoiIds={selectedAoiIds}
                isShowHideWeightSlider={isShowHideWeightSlider}
                isShowGenerateButton={isLulcGenCompleted}
                isDisableGenerateButton={!isLulcGenCompleted}
                handleGenerateAoiData={handleGenerateAoiData}
                handleChangeLayerPosition={handleChangeLayerPosition}
                handleAfterFileWeightChange={handleAfterFileWeightChange}
                handleFileWeightChange={handleFileWeightChange}
                handleVectorLayerIds={handleVectorLayerIds}
                handleShowHideLayers={handleShowHideLayers}
                handleShowHideSelectedAoiIds={handleShowHideSelectedAoiIds}
                handleSelectedAOI={handleSelectedAOI}
                handleEditAOI={handleEditAOI}
                handleHideSlider={handleHideSlider}
                handleGozone={handleGozoneLayers}
                handleNoGozone={handleNoGozoneLayers}
              />
            )}
            {currentStep === 2 && (
              <RouteGenerationStep
                isRouteGeneration
                editedRouteData={editedRouteData}
                vectorLayerCategories={vectorLayerCategories}
                selectedRouteData={selectedRouteData}
                layers={layers}
                selectedLayer={selectedLayer}
                layerWeight={layerWeight}
                selectedRoutesIds={selectedRoutesIds}
                isShowHideWeightSlider={isShowHideWeightSlider}
                handleChangeLayerPosition={handleChangeLayerPosition}
                handleAfterFileWeightChange={handleAfterFileWeightChange}
                handleFileWeightChange={handleFileWeightChange}
                handleVectorLayerIds={handleVectorLayerIds}
                handleShowHideLayers={handleShowHideLayers}
                handleShowHideRoute={handleShowHideRoute}
                handleGenerateRoute={handleGenerateRoute}
                handleEditBuffer={handleEditBuffer}
                handleEditRoute={handleEditRoute}
                handleHideSlider={handleHideSlider}
                handleSelectRouteDetail={handleSelectRouteDetail}
                handleGozone={handleGozoneLayers}
                handleNoGozone={handleNoGozoneLayers}
              />
            )}

            {selectedProject?.user_permission === UserPermission.Member && (
              <MemberActionButtons
                isBeelineBtnDisable={isBeelineBtnDisable}
                isLulcGenInprogress={isLulcGenInprogress}
                isSatelliteInprogress={isSatelliteInprogress}
                isAOIConfirmApproved={isAOIConfirmApproved}
                isAOIGenerationNotStarted={isAOIGenerationNotStarted}
                isSatelliteProcurementInProgress={isSatelliteProcurementInProgress}
                currentStep={currentStep}
                aoiLists={aoiLists}
                latlngA={latlngA}
                latlngB={latlngB}
                selectedAOIData={selectedAOIData}
                editedAOIData={editedAOIData}
                handleNextStep={handleNextStep}
                handleInitiateLayerGeneration={handleInitiateLayerGeneration}
                handleConfirmBeeLine={handleConfirmBeeLine}
                handleSentForApprovalAOI={handleSentForApprovalAOI}
              />
            )}
            {selectedProject?.user_permission === UserPermission.Manager && (
              <PMActionButtons
                isBeelineBtnDisable={isBeelineBtnDisable}
                isLulcGenInprogress={isLulcGenInprogress}
                isSatelliteInprogress={isSatelliteInprogress}
                isAOIConfirmApproved={isAOIConfirmApproved}
                isAOIGenerationNotStarted={isAOIGenerationNotStarted}
                isSatelliteProcurementInProgress={isSatelliteProcurementInProgress}
                currentStep={currentStep}
                aoiLists={aoiLists}
                latlngA={latlngA}
                latlngB={latlngB}
                selectedAOIData={selectedAOIData}
                editedAOIData={editedAOIData}
                handleNextStep={handleNextStep}
                handleInitiateLayerGeneration={handleInitiateLayerGeneration}
                handleConfirmBeeLine={handleConfirmBeeLine}
                handleApproveByWMOrPM={handleApproveByWMOrPM}
                handleDeclineByWMOrPM={handleDeclineByWMOrPM}
              />
            )}
            {selectedProject?.user_permission === UserPermission.WorkspaceManager && (
              <WMActionButtons
                isBeelineBtnDisable={isBeelineBtnDisable}
                isLulcGenInprogress={isLulcGenInprogress}
                isSatelliteInprogress={isSatelliteInprogress}
                isAOIGenerationNotStarted={isAOIGenerationNotStarted}
                isSatelliteProcurementInProgress={isSatelliteProcurementInProgress}
                isSatelliteConfirmApproved={isSatelliteConfirmApproved}
                currentStep={currentStep}
                aoiLists={aoiLists}
                latlngA={latlngA}
                latlngB={latlngB}
                selectedAOIData={selectedAOIData}
                editedAOIData={editedAOIData}
                handleNextStep={handleNextStep}
                handleInitiateLayerGeneration={handleInitiateLayerGeneration}
                handleConfirmBeeLine={handleConfirmBeeLine}
                handleApproveByWMOrPM={handleApproveByWMOrPM}
                handleDeclineByWMOrPM={handleDeclineByWMOrPM}
              />
            )}
          </div>
          <WorkspaceMap
            currentStep={currentStep}
            latlngA={latlngA}
            latlngB={latlngB}
            aoiLists={aoiLists}
            selectedAOIData={selectedAOIData}
            beeLineCoords={{
              start_point: selectedProject?.start_point,
              end_point: selectedProject?.end_point
            }}
            selectedAoiIds={selectedAoiIds}
            editedAOIData={editedAOIData}
            editedRouteData={editedRouteData}
            vectorLayerCategories={vectorLayerCategories}
            selectedRouteIds={selectedRoutesIds}
            selectedRouteData={selectedRouteData}
            editAOICoordsData={editAOICoordsData}
            editRouteCoordsData={editRouteCoordsData}
            handleChangeDoneLatngA={handleChangeDoneLatngA}
            handleChangeDoneLatngB={handleChangeDoneLatngB}
            handleCloseEditMode={handleCloseEditMode}
            handleSaveEditAOICoordsData={handleSaveEditAOICoordsData}
            handleSaveEditRouteCoordsData={handleSaveEditRouteCoordsData}
            handleEditAOICallback={handleEditAOICallback}
            handleEditRouteCallback={handleEditRouteCallback}
            isOpenApproveAOIModal={isOpenApproveAOIModal}
            isOpenDeclineAOIModal={isOpenDeclineAOIModal}
            isOpenApprovePurchaseModal={isOpenApprovePurchaseModal}
            isGozoneLayers={isGozoneLayers}
            isNoGozoneLayers={isNoGozoneLayers}
            handleCloseGoNogoZoneFilter={handleCloseGoNogoZoneFilter}
            isShowTowerText={isShowTowerText}
            handleShowHideTowerText={handleShowHideTowerText}
          />
        </div>
      </div>
      {/* Bee line confirmation Modal */}
      <ConfirmModal
        isOpen={isConfirmBeeLine}
        isClosable
        isCancelButton
        title="Confirm Bee Line"
        description="Are you sure you want to confirm bee line?"
        handleClickOkButton={handleConfirmBeelineCall}
        handleClickCancelButton={handleCancelBeeline}
      />
      {/* Initiate Layer Generation Modal */}
      <ConfirmModal
        isOpen={isLayerGen}
        isClosable
        isCancelButton
        okbuttonText="Yes"
        cancelbuttonText="No"
        title="Initiate Layer Generation"
        description="Are you sure you want to initiate layer generation?"
        handleClickOkButton={handleConfirmLayerGeneration}
        handleClickCancelButton={handleCancelLayerGeneration}
      />
      {/* Layer Generation Request Modal */}
      <LulcGenerateOkayModal
        isOpen={isLayerGenReq}
        width={571}
        isClosable={false}
        percentage={0}
        text={`Generating ${
          LulcLayerDetails && LulcLayerDetails.lulc_type === LULCLayerType.lulclow ? 'Low Res ' : ''
        }
        ${
          LulcLayerDetails && LulcLayerDetails.lulc_type === LULCLayerType.lulchigh
            ? 'High Res '
            : ''
        } LULC...`}
        handleClickOkButton={handleLayerGenerationReqOkay}
        handleClickCancelButton={() => {}}
      />
      {/* Generate AOI modal */}
      <ConfirmModal
        isOpen={isOpenGenerateAOIModal}
        isClosable
        isCancelButton
        title="Generate AOI"
        description="Confirm AOI generation with the selected weights combinations?"
        handleClickOkButton={handleGenerateAoiData}
        handleClickCancelButton={() => setGenerateAOIModal(false)}
        okbuttonText="Yes"
        cancelbuttonText="No"
      />
      {/* Generate Route modal */}
      <ConfirmModal
        isOpen={isOpenGenerateRouteModal}
        isClosable
        isCancelButton
        title="Generate Route"
        description="Confirm Route generation with the selected weights combinations?"
        handleClickOkButton={handleGenerateRoute}
        handleClickCancelButton={() => setGenerateRouteModal(false)}
        okbuttonText="Yes"
        cancelbuttonText="No"
      />
      {/* Sent for approval Modal */}
      <ConfirmModal
        isOpen={isOpenSentForApprovalModal}
        width={523}
        isClosable
        isCancelButton
        okbuttonText="Send"
        title="Send AOI for Approval"
        description={`Are you sure you want to send AOI ${selectedAOIData?.index} for Approval?`}
        handleClickOkButton={handleSentForApprovalAOIOkay}
        handleClickCancelButton={handleSentForApprovalAOICancel}
      />
      {/* Sent for approval Okay Modal Member */}
      <ConfirmModal
        isOpen={isOpenSentApprovalOkayModal}
        width={523}
        isClosable={false}
        isCancelButton={false}
        okbuttonText="Okay"
        title=""
        description=""
        subDescription={`We will notify the Workspace Manager or Project Manager to verify the AOI ${selectedAOIData?.index} for Approval`}
        handleClickOkButton={handleRecievedSentApprovalOkay}
        handleClickCancelButton={() => {}}
      />
      {/* Approve AOI Modal */}
      <ConfirmModal
        isOpen={isOpenApproveAOIModal}
        width={523}
        isClosable
        isCancelButton
        okbuttonText="Approve"
        title="Approve AOI"
        description={`Are you sure you want to approve AOI ${selectedAOIData?.index} for initiating the High Res Satellite Imagery?`}
        handleClickOkButton={handleApproveWMorPMOkay}
        handleClickCancelButton={handleApproveDeclineWMorPMCancel}
      />
      {/* Approve AOI Okay Modal PM */}
      <ConfirmModal
        isOpen={isOpenApprovalOkayModal}
        width={523}
        isClosable={false}
        isCancelButton={false}
        okbuttonText="Okay"
        title=""
        description=""
        subDescription={`We will notify the Workspace Manager or Project Manager to verify the AOI ${selectedAOIData?.index} for Approval`}
        handleClickOkButton={handleRecievedAOIApprovalOkay}
        handleClickCancelButton={() => {}}
      />
      {/* Decline AOI Modal */}
      <DeclineModal
        isOpen={isOpenDeclineAOIModal}
        isClosable
        comment={declineComment}
        title="Decline AOI"
        description={`Please add your comments for the Review - AOI ${selectedAOIData?.index}`}
        handleClickOkButton={handleDeclineWMorPMOkay}
        handleSetDeclineComment={handleSetDeclineComment}
        handleClickCancelButton={handleApproveDeclineWMorPMCancel}
      />
      {/* Approve AOI with Purchase WM */}
      <AOIApproveConfirmModal
        isOpen={isOpenApprovePurchaseModal}
        width={550}
        isClosable
        isCancelButton
        approvedAoi={selectedAOIData}
        isLoading={isRequestHighRes}
        handleClickOkButton={handleApproveWMorPMOkay}
        handleClickCancelButton={handleApproveHighResSatCancel}
      />
      {/* Recieved High Res Satellite Purchase Modal WM */}
      <AOIApproveHighResConfirmModal
        isOpen={isOpenRecievedPurchaseModal}
        isClosable={false}
        width={571}
        handleClickOkButton={handleRecievedHighResSatOkay}
        handleClickCancelButton={() => {}}
      />
      {/* Edit Buffer Modal */}
      <EditBufferModal
        isOpen={isOpenEditBufferModal}
        isClosable
        title="Edit Buffer"
        description="BUFFER"
        editBufferData={selectedEditBufferData}
        handleClickOkButton={handleEditBufferOkay}
        handleClickCancelButton={handleEditBufferCancel}
      />
    </>
  );
};

export default Workspace;

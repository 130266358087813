import { useEffect } from 'react';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import { Style, Stroke, Fill } from 'ol/style';
import { createXYZ } from 'ol/tilegrid';
import { black } from 'constants/theme.constants';
import { useMap } from './MapContext';

interface TileLayerTileLayerProps {
  uniqueName: string;
  layerUrl: string;
  layercolor: string;
  isNoGozoneLayers: boolean;
  weight: number;
}

const TileLayer = ({
  isNoGozoneLayers,
  weight,
  uniqueName,
  layerUrl,
  layercolor
}: TileLayerTileLayerProps) => {
  const map = useMap();

  useEffect(() => {
    const vectorTileLayer = new VectorTileLayer({
      source: new VectorTileSource({
        format: new MVT(),
        url: layerUrl,
        tileGrid: createXYZ({ maxZoom: 14 }),
        maxZoom: 14
      }),
      zIndex: 1,
      style: new Style({
        stroke: new Stroke({
          color: isNoGozoneLayers && weight === 100 ? black : layercolor,
          width: isNoGozoneLayers && weight === 100 ? 2 : 1
        }),
        fill: new Fill({
          color: layercolor
        })
      })
    });

    if (map) {
      vectorTileLayer.set('name', uniqueName);
      map.addLayer(vectorTileLayer);
    }

    return () => {
      if (map) {
        map.getLayers().forEach((layer) => {
          if (layer && layer.get('name') === uniqueName) {
            map.removeLayer(layer);
          }
        });
      }
    };
  }, [map, layerUrl, layercolor, isNoGozoneLayers]);

  return null;
};

export default TileLayer;

import { useEffect, useRef, useState } from 'react';
import { Draw, Modify } from 'ol/interaction';
import { LineString, Point } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Style, Fill, Stroke, Circle as CircleStyle, Text, RegularShape } from 'ol/style';
import { getLength } from 'ol/sphere';
import { white } from 'constants/theme.constants';
import { useMap } from './MapContext';

interface MeasurementPropstype {
  isMeasure: boolean;
}

const WorkspaceMeasureTool = ({ isMeasure }: MeasurementPropstype) => {
  const map = useMap();
  const sourceRef = useRef<VectorSource>(new VectorSource());
  // @ts-ignore
  const layerRef = useRef<VectorLayer>();
  const modifyRef = useRef<Modify | null>(null);
  const drawRef = useRef<Draw | null>(null);
  const [drawType] = useState<'LineString'>('LineString');
  let tipPoint: any;
  const activeTip = `Click to continue drawing the line`;
  const idleTip = 'Click to start measuring';
  let tip = idleTip;

  const defaultStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)'
    }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      lineDash: [10, 10],
      width: 2
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.4)'
      })
    })
  });

  const labelStyle = new Style({
    text: new Text({
      font: '12px ManropeRegular',
      fill: new Fill({
        color: white
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      padding: [5, 5, 3, 5],
      textBaseline: 'bottom',
      offsetY: -15
    }),
    image: new RegularShape({
      radius: 8,
      points: 3,
      angle: Math.PI,
      displacement: [0, 10],
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      })
    })
  });

  const tipStyle = new Style({
    text: new Text({
      font: '12px ManropeRegular',
      fill: new Fill({
        color: white
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      }),
      padding: [5, 5, 2, 5],
      textAlign: 'left',
      offsetX: 15
    })
  });

  const segmentStyle = new Style({
    text: new Text({
      font: '12px ManropeRegular',
      fill: new Fill({
        color: white
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      }),
      padding: [5, 5, 2, 5],
      textBaseline: 'bottom',
      offsetY: -12
    }),
    image: new RegularShape({
      radius: 6,
      points: 3,
      angle: Math.PI,
      displacement: [0, 8],
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      })
    })
  });

  const modifyStyle = new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)'
      })
    }),
    text: new Text({
      text: 'Drag to modify',
      font: '12px ManropeRegular',
      fill: new Fill({
        color: white
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      padding: [5, 5, 3, 5],
      textAlign: 'left',
      offsetX: 15
    })
  });

  const segmentStyles: Style[] = [segmentStyle];

  const formatLength = (line: LineString) => {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = `${(length / 1000).toFixed(2)} km`;
    } else {
      output = `${length.toFixed(2)} m`;
    }
    return output;
  };

  const createStyleFunction = (
    feature: any,
    showSegments: boolean,
    drawType: 'LineString',
    tip: string
  ) => {
    const styles = [];
    const geometry = feature.getGeometry();
    const type = geometry.getType();
    let point: Point | undefined;
    let label: string | undefined;
    let line: LineString | undefined;

    if (!drawType || drawType === type || type === 'Point') {
      // eslint-disable-next-line
      styles.push(defaultStyle);
      if (type === 'LineString') {
        point = new Point(geometry.getLastCoordinate());
        label = formatLength(geometry);
        line = geometry;
      }
    }

    if (showSegments && line) {
      let count = 0; // eslint-disable-line
      line.forEachSegment((a, b) => {
        const segment = new LineString([a, b]);
        const segmentLabel = formatLength(segment);
        if (segmentStyles.length - 1 < count) {
          segmentStyles.push(segmentStyle.clone());
        }
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles[count].setGeometry(segmentPoint);
        segmentStyles[count].getText()?.setText(segmentLabel);
        styles.push(segmentStyles[count]);
        count++; // eslint-disable-line
      });
    }

    if (label) {
      // @ts-ignore
      labelStyle.setGeometry(point);
      // @ts-ignore
      labelStyle.getText().setText(label);
      styles.push(labelStyle);
    }

    if (
      tip &&
      type === 'Point' &&
      // @ts-ignore
      !modifyRef.current?.getOverlay().getSource().getFeatures().length
    ) {
      tipPoint = geometry;
      // @ts-ignore
      tipStyle.getText().setText(tip);
      styles.push(tipStyle);
    }

    return styles;
  };

  useEffect(() => {
    if (!map) return;
    layerRef.current = new VectorLayer({
      source: sourceRef.current,
      style: (feature) => {
        return createStyleFunction(feature, true, drawType, tip);
      }
    });

    const modify = new Modify({ source: sourceRef.current });
    map.addInteraction(modify);
    map.addLayer(layerRef.current);
    modifyRef.current = modify;

    // eslint-disable-next-line
    return () => {
      map.removeInteraction(modify);
      map.removeLayer(layerRef.current);
    };
  }, [map]);

  const addInteraction = () => {
    if (!map) return;

    if (drawRef.current) {
      map.removeInteraction(drawRef.current);
    }

    const draw = new Draw({
      source: sourceRef.current,
      type: drawType,
      style: (feature) => createStyleFunction(feature, true, drawType, tip)
    });

    draw.on('drawstart', () => {
      sourceRef.current.clear();

      if (modifyRef.current) {
        modifyRef.current.setActive(false);
      }
      tip = activeTip;
    });

    draw.on('drawend', () => {
      if (modifyRef.current) {
        modifyStyle.setGeometry(tipPoint);
        modifyRef.current.setActive(true);
        map.once('pointermove', () => {
          // @ts-ignore
          modifyStyle.setGeometry();
        });
        modifyRef.current.setActive(true);
      }
      tip = idleTip;
    });

    modifyRef.current?.setActive(true);
    map.addInteraction(draw);
    drawRef.current = draw;
  };

  useEffect(() => {
    if (!map) return;

    if (isMeasure) {
      addInteraction();
    }

    // eslint-disable-next-line
    return () => {
      map.removeInteraction(drawRef.current!);
      map.removeLayer(layerRef.current);
    };
  }, [isMeasure]);

  return null;
};

export default WorkspaceMeasureTool;

import { useEffect, useRef } from 'react';
import { indexOf, isEmpty, orderBy } from 'lodash';
import Text from 'ol/style/Text';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LineString, Point } from 'ol/geom';
import Feature from 'ol/Feature';
import { Style, Stroke, Fill } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import CircleStyle from 'ol/style/Circle';
import { Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import OLPopup from 'ol-popup';
import { TowerType } from 'store/route/types';
import { AOIDetail } from 'store/aoi/types';
import { useMap } from './MapContext';

interface AoiLineComponentProps {
  isShowTowerText: boolean;
  selectedAoiIds: string[];
  selectedAoiData: AOIDetail | null;
}

const AoiLineComponent = ({
  isShowTowerText,
  selectedAoiIds,
  selectedAoiData
}: AoiLineComponentProps) => {
  const map = useMap();
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const vectorSourceRef = useRef<VectorSource>();
  const popupRef = useRef<OLPopup>();
  const selectInteractionRef = useRef<Select>();

  useEffect(() => {
    if (vectorLayerRef.current && map && isEmpty(selectedAoiIds)) {
      map.removeLayer(vectorLayerRef.current);
    }
  }, [selectedAoiIds]);

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 100,
      style: (feature) => {
        const routeColor = feature.get('color');
        return new Style({
          stroke: new Stroke({
            color: routeColor,
            width: 2
          }),
          zIndex: 100
        });
      }
    });
    vectorLayerRef.current.set('name', 'routelist');
    if (map) {
      map.addLayer(vectorLayerRef.current);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
        popupRef.current?.hide();
        selectInteractionRef.current?.getFeatures().clear();
      }
    };
  }, [selectedAoiIds]);

  useEffect(() => {
    if (!vectorSourceRef.current || !selectedAoiData || !map) return;
    vectorSourceRef.current.clear();

    if (
      selectedAoiData.geometry &&
      selectedAoiIds.includes(selectedAoiData.id) &&
      !isEmpty(selectedAoiData.towers)
    ) {
      const aoiIndex: number = indexOf(selectedAoiIds, selectedAoiData.id);

      orderBy(selectedAoiData.towers, 'id').forEach((tower: TowerType, j: number) => {
        const routePointFeature = new Feature({
          geometry: new Point(fromLonLat(tower.geometry.coordinates)),
          color: selectedAoiData.color,
          type: tower.geometry.type
        });
        routePointFeature.setStyle(() => {
          // const zoom = map.getView().getZoom() || 0;
          // const fontSize = Math.max(12, 30 - zoom * 2);

          return new Style({
            text: isShowTowerText
              ? new Text({
                  text: tower.name || '',
                  font: '12px Manrope',
                  fill: new Fill({ color: selectedAoiData.color }),
                  stroke: new Stroke({ width: 1, color: selectedAoiData.color }),
                  offsetY: 0,
                  offsetX: j % 2 === 0 ? 25 : -25
                })
              : undefined,
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({ color: selectedAoiData.color }),
              stroke: new Stroke({ color: selectedAoiData.color })
            }),
            zIndex: aoiIndex + 60
          });
        });
        routePointFeature.setId(tower.id);
        routePointFeature.set('name', `${tower.type}-${Number(tower.deviation_angle).toFixed(2)}`);
        routePointFeature.set('index', j);

        vectorSourceRef.current?.addFeature(routePointFeature);
      });

      const select = new Select({
        condition: click,
        filter: (feature) => feature.get('type') === 'Point'
      });

      map.addInteraction(select);
      selectInteractionRef.current = select;

      select.on('select', (event) => {
        const selectedFeature = event.selected[0];

        if (selectedFeature) {
          // @ts-ignore
          const coordinates = selectedFeature.getGeometry().getCoordinates();

          const name = selectedFeature.get('name');
          if (popupRef.current) {
            popupRef.current.show(
              coordinates,
              `<div class='route-point'>
                    <div class='route-ol-container'>
                        <div>
                            <span>Deviation Angle: <b>${name.split('-')[1]}</b></span>
                            <span>Tower Type: <b>${name.split('-')[0]}</b></span>
                        </div>
                        <button id='routeclosebutton'>
                            x
                        </button>
                    </div>
                </div>`
            );

            const closeBtn = document.getElementById('routeclosebutton');

            if (closeBtn) {
              closeBtn.addEventListener('click', () => {
                popupRef.current?.hide();
                selectInteractionRef.current?.getFeatures().clear();
              });
            }
          }
        }
      });

      const coords = orderBy(selectedAoiData.towers, 'id').map((t: TowerType) =>
        fromLonLat(t.geometry.coordinates)
      );

      const feature = new Feature({
        geometry: new LineString(coords),
        color: selectedAoiData.color
      });
      feature.setId(selectedAoiData.id);
      feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: selectedAoiData.color,
            width: 2,
            lineDash: [5, 10]
          }),
          zIndex: aoiIndex + 60
        })
      );
      vectorSourceRef.current?.addFeature(feature);

      // eslint-disable-next-line
      return () => {
        map.removeInteraction(select);
      };
    }
  }, [selectedAoiData, selectedAoiIds, isShowTowerText]);

  useEffect(() => {
    if (map) {
      popupRef.current = new OLPopup();
      map.addOverlay(popupRef.current);
    }
    return () => {
      if (map && popupRef.current) {
        map.removeOverlay(popupRef.current);
      }
    };
  }, [map]);

  return null;
};

export default AoiLineComponent;

import { Typography } from 'antd';
import { black } from 'constants/theme.constants';
import { AOIDetail } from 'store/aoi/types';
import { BeeLinePointType } from 'types/aoi.types';
import { getDistance } from 'utils/geoCode';
import { getAOIArea } from 'utils/util';

interface AOILineLengthProps {
  beeLineCoords: BeeLinePointType | null;
  approvedAOI: AOIDetail | null;
}
const { Text } = Typography;

const AOILineLength = ({ beeLineCoords, approvedAOI }: AOILineLengthProps) => {
  if (!beeLineCoords?.end_point) return null;

  return (
    beeLineCoords?.start_point &&
    beeLineCoords?.end_point && (
      <div className="bee-line-distance">
        {approvedAOI && (
          <Text className="bee-line-aoi">
            <div style={{ backgroundColor: `${approvedAOI.color}`, height: 12 }} />

            <Text style={{ fontSize: 14, fontFamily: 'ManropeRegular' }}>
              Finalized AOI :<Text strong>{`${getAOIArea(approvedAOI!.geometry)} sq km`}</Text>
            </Text>
          </Text>
        )}
        <Text className="bee-line-aoi">
          <div style={{ backgroundColor: `${black}` }} />

          <Text style={{ fontSize: 14, fontFamily: 'ManropeRegular' }}>
            Bee Line Length :
            <Text strong>
              {`${getDistance(
                Number(beeLineCoords?.start_point?.lat),
                Number(beeLineCoords?.start_point?.lng),
                Number(beeLineCoords?.end_point?.lat),
                Number(beeLineCoords?.end_point?.lng)
              )} km`}
            </Text>
          </Text>
        </Text>
      </div>
    )
  );
};

export default AOILineLength;

import { useContext, useState } from 'react';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import { Table, Typography, Dropdown, Menu, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { find, isEmpty, orderBy, upperFirst } from 'lodash';
import { ACCESS_TOKEN, DateFormat2, TimeFormat } from 'constants/common.constant';
import { TimezoneContext } from 'context/CreateUseContext';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { Category, RepositoryFile } from 'store/repositoryfile/types';
import { UserPermission } from 'store/users/types';
import { getFileCategoryOptions, getUploadedByOptions, getFiles } from 'store/repositoryfile';
import { archiveRepositoryFile, getDownloadRepoFile } from 'store/repositoryfile/actions';
import { decryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';
import FileActionButtons from './FileActionButtons';
import EditFileModal from './EditFileModal';
import './styles.less';

const { Text } = Typography;

const FileRepositoryTable = () => {
  const dispatch = useAppDispatch();
  const timeZone = useContext(TimezoneContext);
  const { selectedProject } = useAppSelector((state) => state.projects);

  const isViewer = selectedProject?.user_permission === UserPermission.Viewer;
  const categoryOptions = useAppSelector(getFileCategoryOptions);
  const uploadedByOptions = useAppSelector(getUploadedByOptions);

  const { isRequestingFiles, isRequestingEditRepoFile, categories, isRequestingDownloadFile } =
    useAppSelector((state) => state.repofiles);
  const [isOpenEditModal, setEditModal] = useState(false);
  const [selectedFileCategory, setSelectedFileCategory] = useState<string>('all');
  const [selectedFileUploadedBy, setSelectedFileUploadedBy] = useState<string>('all');
  const [downloadFileId, setDownloadFileId] = useState<number | null>(null);

  const [selectedRow, setSelectedRow] = useState<RepositoryFile | null>();

  const handleEditRepoFile = (record: RepositoryFile) => {
    setSelectedRow(record);
    setEditModal(true);
  };
  const repositoryFiles = useAppSelector((state) =>
    getFiles(state, selectedFileCategory, selectedFileUploadedBy)
  );
  const handleCategoryFilter = (value: string) => {
    setSelectedFileCategory(value);
  };
  const handleUploadedByFilter = (value: string) => {
    setSelectedFileUploadedBy(value);
  };

  const categoryMenu = (
    <Menu onClick={(e) => handleCategoryFilter(e.key)} items={categoryOptions} />
  );

  const uploadedMenu = (
    <Menu onClick={(e) => handleUploadedByFilter(e.key)} items={uploadedByOptions} />
  );
  const handleFileArchive = (record: RepositoryFile) => {
    if (!record.active) return;

    const { id } = record;
    const payload = { active: false };

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(archiveRepositoryFile(selectedProject?.id, id, token, () => {}, payload));
    });
  };

  const handleEditFileCloseModal = () => {
    setSelectedRow(null);
  };

  const handleDownloadFile = (repoFile: RepositoryFile) => {
    if (!isViewer) {
      setDownloadFileId(repoFile.id);
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getDownloadRepoFile(selectedProject?.id, repoFile, token));
      });
    }
  };

  const columns: ColumnsType<RepositoryFile> = [
    {
      title: 'Name',
      dataIndex: 'file_name',
      key: 'file_name',
      width: '10%',
      render: (_, record) => (
        <Tooltip title={record.file_name}>
          <Text
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '10rem',
              display: 'block',
              whiteSpace: 'nowrap'
            }}>
            {`${record.file_name} (${record.id})`}
          </Text>
        </Tooltip>
      )
    },
    {
      title: (
        <div>
          <Text>Category</Text>
          <Dropdown
            overlayClassName="tableHeaderDropdown"
            overlay={categoryMenu}
            placement="bottomRight">
            <DownOutlined />
          </Dropdown>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      width: '12.5%',
      render: (_, record) => {
        const cat: Category | null = find(categories, (cat: Category) => {
          return cat.category_name === record.category;
        });
        return cat ? <Text>{cat?.category_name}</Text> : <Text>-</Text>;
      }
    },
    {
      title: 'Initial Weights',
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      width: '12.5%',
      render: (_, record) => (record.weight ? <Text>{record.weight}</Text> : '0')
    },
    {
      title: 'Buffer',
      dataIndex: 'buffer',
      key: 'buffer',
      width: '12.5%',
      align: 'center',
      render: (_, record) => <Text>{record.buffer ? `${record.buffer} m` : '0 m'}</Text>
    },
    {
      title: 'Description',
      dataIndex: 'comment',
      key: 'comment',
      width: '17%',
      render: (_, record) => (
        <Tooltip title={record.comment}>
          <Text
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '10rem',
              display: 'block',
              whiteSpace: 'nowrap'
            }}>
            {record.comment ? record.comment : '-'}
          </Text>
        </Tooltip>
      )
    },
    {
      title: (
        <div>
          <Text>Uploaded By</Text>
          <Dropdown
            overlayClassName="tableHeaderDropdown"
            overlay={uploadedMenu}
            placement="bottomRight">
            <DownOutlined />
          </Dropdown>
        </div>
      ),
      dataIndex: 'created_by',
      key: 'created_by',
      width: '12.5%',
      render: (_, record) => {
        let firstname;
        let lastname;
        if (record.first_name && record.first_name) {
          firstname = upperFirst(decryptValue(record.first_name));
          lastname = upperFirst(decryptValue(record.last_name));
        } else {
          firstname = upperFirst(decryptValue(record.user_email));
          lastname = '';
        }

        return (
          <div className="uploadedByColumn">
            <Text>{`${firstname} ${lastname}`}</Text>
            {record.modified_at && <Text className="editedText">(Edited)</Text>}
          </div>
        );
      }
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedOn',
      key: 'updatedOn',
      width: '12.5%',
      render: (_, record) => {
        const date: any = record.modified_at
          ? moment(record.modified_at).format(DateFormat2)
          : moment(record.created_at).format(DateFormat2);
        const time: any = record.modified_at
          ? `${moment(record.modified_at).tz('Asia/Kolkata').format(TimeFormat)} ${
              timeZone?.timezone
            }`
          : `${moment(record.created_at).tz('Asia/Kolkata').format(TimeFormat)} ${
              timeZone?.timezone
            }`;
        return (
          <div className="updatedOn">
            <Text key={`${record.id}_date`} className="scheduleColumn">
              {date}
            </Text>
            <Text key={`${record.id}_time`} className="scheduleColumn">
              {time}
            </Text>
          </div>
        );
      }
    },
    {
      title: 'Actions',
      key: 'action',
      width: '12.5%',
      align: 'left',
      render: (_, record) => {
        return (
          <FileActionButtons
            record={record}
            handleEditRepoFile={handleEditRepoFile}
            handleDownloadFile={handleDownloadFile}
            handleFileArchive={handleFileArchive}
            isRequestingDownloadFile={isRequestingDownloadFile}
            downloadFileId={downloadFileId}
          />
        );
      }
    }
  ];

  const filteredFiles =
    selectedRow && !isOpenEditModal
      ? [selectedRow]
      : orderBy(repositoryFiles, ['created_at'], ['desc']).filter(
          (file) => file.type === 'user_vector'
        );

  return (
    <div className="filerepo-table">
      <Table
        loading={isRequestingFiles}
        className="fileReposataryTable custom-table"
        columns={columns}
        dataSource={filteredFiles}
        pagination={false}
        sticky
        rowKey={(row) => row.id}
        rowClassName={(record) => {
          let className = '';
          if (!record.active) {
            className = 'disabled-row';
          }
          return className;
        }}
      />

      {!isEmpty(selectedRow) && (
        <EditFileModal
          projectId={selectedProject?.id}
          isRequestingEditRepoFile={isRequestingEditRepoFile}
          isOpenModal={isOpenEditModal}
          handleEditFileCloseModal={handleEditFileCloseModal}
          selectedRow={selectedRow}
          repoCategory={categories}
        />
      )}
    </div>
  );
};

export default FileRepositoryTable;

import { useEffect, useRef, useState, useCallback } from 'react';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Popup from 'ol-popup';
import { Fill, Text } from 'ol/style';
import { click } from 'ol/events/condition';
import { Select, Modify } from 'ol/interaction';

import { LatLongNumberType } from 'types/aoi.types';
import PointRed from 'assets/png/PointRed.png';
import { lightBlack, white } from 'constants/theme.constants';
import { useMap } from './MapContext';

interface PointBComponentPropsType {
  initPoint: number[] | null;
  handleDoneLatngB: (data: number[]) => void;
  pointA: LatLongNumberType | null;
}

// eslint-disable-next-line
const PointBComponent = ({ initPoint, handleDoneLatngB, pointA }: PointBComponentPropsType) => {
  const map = useMap();
  // @ts-ignore
  const pointLayerRef = useRef<VectorLayer>();
  const pointSourceRef = useRef<VectorSource>();
  const popupRef = useRef<Popup | null>(null);
  const selectInteractionRef = useRef<Select>();
  const [currentPoint, setCurrentPoint] = useState<number[] | null>(null);

  // eslint-disable-next-line
  useEffect(() => {
    pointSourceRef.current = new VectorSource();
    pointLayerRef.current = new VectorLayer({
      source: pointSourceRef.current,
      style: new Style({
        text: new Text({
          fill: new Fill({
            color: white
          }),
          backgroundFill: new Fill({
            color: lightBlack
          }),
          padding: [7, 10, 7, 10],
          textAlign: 'start',
          offsetX: -20,
          offsetY: 20,
          text: 'Point B'
        }),
        image: new Icon({
          anchor: [0.5, 1],
          height: 20,
          width: 20,
          src: PointRed
        })
      })
    });

    if (map) {
      map.addLayer(pointLayerRef.current);

      return () => {
        map.removeLayer(pointLayerRef.current);
        popupRef.current?.hide();
        selectInteractionRef.current?.getFeatures().clear();
      };
    }
  }, [initPoint]);

  const handleSave = useCallback(() => {
    if (currentPoint) {
      handleDoneLatngB(currentPoint);
    }
    popupRef?.current?.hide();
    popupRef?.current?.setPosition(undefined);
  }, [currentPoint]);

  const handleClose = () => {
    popupRef?.current?.setPosition(undefined);
    if (!pointSourceRef.current || !initPoint) return;

    // Update point feature to initial point
    const feature = pointSourceRef.current.getFeatureById('pointb');
    if (feature) {
      const initialCoords = fromLonLat(initPoint);
      // @ts-ignore
      feature.getGeometry().setCoordinates(initialCoords);
      setCurrentPoint(initPoint);

      // Update popup position
      if (popupRef.current) {
        setCurrentPoint(null);
      }
    }
  };

  useEffect(() => {
    if (!pointSourceRef.current || !initPoint || !map) return;

    const feature = new Feature({
      geometry: new Point(fromLonLat(initPoint))
    });
    feature.setId('pointb');
    pointSourceRef.current?.addFeature(feature);

    const select = new Select({
      condition: click
    });
    map.addInteraction(select);
    selectInteractionRef.current = select;

    select.on('select', (event) => {
      const selectedFeature = event.selected[0];

      if (selectedFeature) {
        // @ts-ignore
        const coordinates = selectedFeature.getGeometry().getCoordinates();
        if (
          toLonLat(coordinates)[0] !== pointA?.lng &&
          toLonLat(coordinates)[1] !== pointA?.lat &&
          toLonLat(coordinates)[0] !== initPoint[0] &&
          toLonLat(coordinates)[1] !== initPoint[1]
        ) {
          popupRef.current?.show(
            coordinates,
            `<div class="popup point-btn-div">
            <button id="savebtnB">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39754 13.4999C7.19054 13.4999 6.99254 13.4144 6.85079 13.2637L3.20354 9.3794C2.91929 9.0779 2.93504 8.60315 3.23654 8.31965C3.53879 8.03615 4.01354 8.05115 4.29629 8.35265L7.39004 11.6459L13.696 4.7444C13.9765 4.43765 14.4505 4.4174 14.7565 4.6964C15.0618 4.9754 15.0828 5.45015 14.8038 5.7554L7.95104 13.2554C7.81079 13.4099 7.61129 13.4984 7.40279 13.4999H7.39754Z" fill="#ffffff"/>
              </svg>
            </button>
            <button id="closebtnB">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0607 9.00019L13.2804 5.78044C13.5737 5.48719 13.5737 5.01319 13.2804 4.71994C12.9872 4.42669 12.5132 4.42669 12.2199 4.71994L9.00019 7.93969L5.78044 4.71994C5.48719 4.42669 5.01319 4.42669 4.71994 4.71994C4.42669 5.01319 4.42669 5.48719 4.71994 5.78044L7.93969 9.00019L4.71994 12.2199C4.42669 12.5132 4.42669 12.9872 4.71994 13.2804C4.86619 13.4267 5.05819 13.5002 5.25019 13.5002C5.44219 13.5002 5.63419 13.4267 5.78044 13.2804L9.00019 10.0607L12.2199 13.2804C12.3662 13.4267 12.5582 13.5002 12.7502 13.5002C12.9422 13.5002 13.1342 13.4267 13.2804 13.2804C13.5737 12.9872 13.5737 12.5132 13.2804 12.2199L10.0607 9.00019Z"
                fill="#ffffff" />
              </svg>
            </button>
          </div>`
          );
        }

        document.getElementById('savebtnB')?.addEventListener('click', handleSave);
        document.getElementById('closebtnB')?.addEventListener('click', handleClose);
      }
    });

    const modify = new Modify({ source: pointSourceRef.current });
    map.addInteraction(modify);

    modify.on('modifyend', (evt: any) => {
      const coords = evt.features.item(0).getGeometry().getCoordinates();
      const lonLat = toLonLat(coords);
      setCurrentPoint(lonLat);
      popupRef.current?.show(
        coords,
        `<div class="popup point-btn-div">
          <button id="savebtnB">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39754 13.4999C7.19054 13.4999 6.99254 13.4144 6.85079 13.2637L3.20354 9.3794C2.91929 9.0779 2.93504 8.60315 3.23654 8.31965C3.53879 8.03615 4.01354 8.05115 4.29629 8.35265L7.39004 11.6459L13.696 4.7444C13.9765 4.43765 14.4505 4.4174 14.7565 4.6964C15.0618 4.9754 15.0828 5.45015 14.8038 5.7554L7.95104 13.2554C7.81079 13.4099 7.61129 13.4984 7.40279 13.4999H7.39754Z" fill="#ffffff"/>
            </svg>
          </button>
          <button id="closebtnB">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0607 9.00019L13.2804 5.78044C13.5737 5.48719 13.5737 5.01319 13.2804 4.71994C12.9872 4.42669 12.5132 4.42669 12.2199 4.71994L9.00019 7.93969L5.78044 4.71994C5.48719 4.42669 5.01319 4.42669 4.71994 4.71994C4.42669 5.01319 4.42669 5.48719 4.71994 5.78044L7.93969 9.00019L4.71994 12.2199C4.42669 12.5132 4.42669 12.9872 4.71994 13.2804C4.86619 13.4267 5.05819 13.5002 5.25019 13.5002C5.44219 13.5002 5.63419 13.4267 5.78044 13.2804L9.00019 10.0607L12.2199 13.2804C12.3662 13.4267 12.5582 13.5002 12.7502 13.5002C12.9422 13.5002 13.1342 13.4267 13.2804 13.2804C13.5737 12.9872 13.5737 12.5132 13.2804 12.2199L10.0607 9.00019Z"
              fill="#ffffff" />
            </svg>
          </button>
        </div>`
      );
    });

    // eslint-disable-next-line
    return () => {
      map.removeInteraction(modify);
      map.removeInteraction(select);
    };
  }, [initPoint, map, currentPoint]);

  useEffect(() => {
    if (!map) return;

    const popup = new Popup();
    popupRef.current = popup;
    map.addOverlay(popup);

    // eslint-disable-next-line
    return () => {
      if (popupRef.current) {
        popupRef.current.setPosition(undefined);
        map.removeOverlay(popupRef.current);
      }
    };
  }, [map]);

  useEffect(() => {
    if (popupRef.current && currentPoint) {
      const coords = fromLonLat(currentPoint);
      popupRef.current.show(
        coords,
        `<div class="popup point-btn-div">
          <button id="savebtnB">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39754 13.4999C7.19054 13.4999 6.99254 13.4144 6.85079 13.2637L3.20354 9.3794C2.91929 9.0779 2.93504 8.60315 3.23654 8.31965C3.53879 8.03615 4.01354 8.05115 4.29629 8.35265L7.39004 11.6459L13.696 4.7444C13.9765 4.43765 14.4505 4.4174 14.7565 4.6964C15.0618 4.9754 15.0828 5.45015 14.8038 5.7554L7.95104 13.2554C7.81079 13.4099 7.61129 13.4984 7.40279 13.4999H7.39754Z" fill="#ffffff"/>
            </svg>
          </button>
          <button id="closebtnB">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0607 9.00019L13.2804 5.78044C13.5737 5.48719 13.5737 5.01319 13.2804 4.71994C12.9872 4.42669 12.5132 4.42669 12.2199 4.71994L9.00019 7.93969L5.78044 4.71994C5.48719 4.42669 5.01319 4.42669 4.71994 4.71994C4.42669 5.01319 4.42669 5.48719 4.71994 5.78044L7.93969 9.00019L4.71994 12.2199C4.42669 12.5132 4.42669 12.9872 4.71994 13.2804C4.86619 13.4267 5.05819 13.5002 5.25019 13.5002C5.44219 13.5002 5.63419 13.4267 5.78044 13.2804L9.00019 10.0607L12.2199 13.2804C12.3662 13.4267 12.5582 13.5002 12.7502 13.5002C12.9422 13.5002 13.1342 13.4267 13.2804 13.2804C13.5737 12.9872 13.5737 12.5132 13.2804 12.2199L10.0607 9.00019Z"
              fill="#ffffff" />
            </svg>
          </button>
        </div>`
      );
      document.getElementById('savebtnB')?.addEventListener('click', handleSave);
      document.getElementById('closebtnB')?.addEventListener('click', handleClose);
    }
  }, [currentPoint]);

  return null;
};

export default PointBComponent;

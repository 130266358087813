import { Fragment, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Tooltip, Typography } from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { take, reverse, xor, isEmpty } from 'lodash';
import { lightGrey, white } from 'constants/theme.constants';
import { USER_ROLE } from 'constants/common.constant';
import MemberListModal from 'components/Modal/MemberList/MemberListModal';
import { useAppSelector } from 'hooks/useReduxHook';
import { UserPermission, UserRole } from 'store/users/types';
import type { MemberT, ProjectType } from 'store/projects/types';
import { getMember, getTeamMembers, selectAvailableProjectMembers } from 'store/projects';
import { shortLabel } from 'utils/util';
import { getStorageValue } from 'utils/localStorage';
import './styles.less';

const { Text } = Typography;

interface AvatarListProps {
  projectDetail: ProjectType;
  handleAddProjectMemberForm: Function;
  className?: string;
}

const AvatarList = ({ projectDetail, handleAddProjectMemberForm, className }: AvatarListProps) => {
  const { user } = useAppSelector((state) => state.user);
  const userRole = getStorageValue(USER_ROLE);
  const currentUser = user?.id;
  const availableMembers = useAppSelector(selectAvailableProjectMembers) ?? [];
  const [isOpenProjectMemberListModal, setOpenProjectMemberListModal] = useState(false);
  const projectManager: MemberT = getMember(projectDetail.manager);
  const projectMembers = getTeamMembers(projectDetail?.members ?? []);
  let memberNum = 4;
  let hasMoreMembers: Array<MemberT> | [] = [];

  if (userRole !== UserRole.Member) {
    if (!isEmpty(projectMembers) && projectMembers.length > 4) {
      memberNum = 3;
    }
  }

  const managerMembers = take(projectMembers, memberNum);

  if (projectMembers && projectMembers.length > 0) {
    hasMoreMembers = xor(projectMembers, managerMembers);
  }

  const handleMoreMembers = () => {
    setOpenProjectMemberListModal(true);
  };

  const handleCloseModal = () => {
    setOpenProjectMemberListModal(false);
  };

  return (
    <div className={`avatars ${className}`}>
      {(projectDetail.user_permission === UserPermission.Manager ||
        projectDetail.user_permission === UserPermission.WorkspaceManager) &&
        availableMembers.length - 1 !== projectMembers.length && (
          <Tooltip
            title="Add members to project"
            color={white}
            placement="bottom"
            overlayClassName="avtar-tooltip">
            <Text className="avatar" onClick={() => handleAddProjectMemberForm(projectDetail)}>
              <div className="last-div">
                <Text>
                  <BiPlus />
                </Text>
              </div>
            </Text>
          </Tooltip>
        )}
      {!isEmpty(hasMoreMembers) && (
        <Tooltip
          title={`Show more member in this ${projectDetail.name}`}
          color={white}
          placement="bottom"
          overlayClassName="avtar-tooltip">
          <Text className="avatar" onClick={() => handleMoreMembers()}>
            <div className="last-div">
              <Text>
                <BsThreeDots color={lightGrey} size={19} />
              </Text>
            </div>
          </Text>
        </Tooltip>
      )}
      {managerMembers?.length > 0 &&
        reverse(managerMembers).map((item: MemberT, i: number) => {
          return (
            <Fragment key={i}>
              <Tooltip
                title={
                  <div className="avtars-name">
                    <Text>{item.label}</Text>
                    <Text>{item.role}</Text>
                  </div>
                }
                color={white}
                placement="bottom"
                overlayClassName="avtar-tooltip">
                <Text className="avatar">
                  <div className={`${item.value === currentUser ? 'first-div' : ''}`}>
                    <Text>{shortLabel(item.label)}</Text>
                  </div>
                </Text>
              </Tooltip>
            </Fragment>
          );
        })}
      <MemberListModal
        isOpen={isOpenProjectMemberListModal}
        selectedProject={projectDetail}
        projectManager={projectManager}
        members={projectMembers}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

AvatarList.defaultProps = {
  className: ''
};

export default AvatarList;

import { useEffect, useRef } from 'react';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { black } from 'constants/theme.constants';
import { useMap } from './MapContext';

interface LineComponentPropsType {
  latlngA: number[] | null;
  latlngB: number[] | null;
}

const LineComponent = ({ latlngA, latlngB }: LineComponentPropsType) => {
  const map = useMap();
  const beeLineRef: any = useRef(null);
  useEffect(() => {
    if (latlngA && latlngB && map) {
      const line = new LineString([fromLonLat(latlngA), fromLonLat(latlngB)]);
      const view = map.getView();
      const extent = line.getExtent();
      view.fit(extent, {
        duration: 1000,
        padding: [200, 200, 200, 200]
      });
    }
  }, [latlngA, latlngB]);

  // eslint-disable-next-line
  useEffect(() => {
    if (latlngA && latlngB) {
      const beeLine = new Feature({
        geometry: new LineString([fromLonLat(latlngA), fromLonLat(latlngB)]),
        name: 'Init Beeline A-B'
      });
      const vectorSourceBeeLine = new VectorSource({
        features: [beeLine]
      });
      const vectorLayerBeeLine = new VectorLayer({
        source: vectorSourceBeeLine,
        style: new Style({
          stroke: new Stroke({
            color: black,
            width: 1
          })
        })
      });
      vectorLayerBeeLine.set('name', 'initbeeline');
      beeLineRef.current = vectorLayerBeeLine;
      if (map) {
        map.addLayer(vectorLayerBeeLine);
        return () => {
          map.getLayers().forEach((layer: any) => {
            if (layer && layer.get('name') === 'initbeeline') {
              map.removeLayer(layer);
            }
          });
        };
      }
    }
  }, [latlngA, latlngB, map]);
  return null;
};

export default LineComponent;

import { useEffect, useState } from 'react';
import { Button, Col, Modal, Progress, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { find, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { lightGrey } from 'constants/theme.constants';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import ProposedTimeline from 'components/ProposedTimeline/ProposedTimeline';
import TeamMemberAndRecentActivity from 'components/ProposedTimeline/TeamMemberAndActivity';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import OpenCloseProjectModal from 'components/ProposedTimeline/CloseProjectModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import {
  closeProject,
  getAvailableProjectMembers,
  getProject,
  getProjectActivityLogs,
  openProject
} from 'store/projects/actions';
import { UserPermission, UserRole } from 'store/users/types';
import { ProjectStagesList, ProjectStatus } from 'store/projects/types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import { statusClass, strokePercentageColor } from 'utils/util';
import './styles.less';

const { Text } = Typography;

const ProjectTimeLine = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    selectedProject,
    project,
    isRequestProject,
    isRequestingProjectActivityLogs,
    isRequestingCloseProject
  } = useAppSelector((state) => state.projects);
  const userRole = getStorageValue(USER_ROLE);
  const [isOpenCloseProjectModal, setOpenClosedProjectModal] = useState(false);
  const [closeProjectId, setCloseProjectId] = useState<number | null>(null);
  const [openProjectId, setOpenProjectId] = useState<number | null>(null);

  useEffect(() => {
    if (id) {
      getAsyncStorageValue(ACCESS_TOKEN)?.then((token: string) => {
        dispatch(getProject(Number(id), token));
        dispatch(getProjectActivityLogs(Number(id), token));
        if (userRole !== UserRole.Member) {
          dispatch(getAvailableProjectMembers(token));
        }
      });
    }
  }, [id]);

  const handleCloseProject = (id: number) => {
    setCloseProjectId(id);
    setOpenClosedProjectModal(true);
  };
  const handleOpenProject = (id: number) => {
    setOpenProjectId(id);
    setOpenClosedProjectModal(true);
  };

  const handleCallBack = (status: boolean) => {
    if (status) {
      setCloseProjectId(null);
      setOpenProjectId(null);
      setOpenClosedProjectModal(false);
    } else {
      toast.error('Something went wrong');
    }
  };

  const handleOpenCloseProject = () => {
    if (closeProjectId) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(closeProject(selectedProject.id, token, handleCallBack));
      });
    } else if (openProjectId) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(openProject(selectedProject.id, token, handleCallBack));
      });
    }
  };

  const handleCancelOpenCloseProject = () => {
    setCloseProjectId(null);
    setOpenProjectId(null);
    setOpenClosedProjectModal(false);
  };

  const isHighResLULCGeneration = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.LULCHigh,
      status: ProjectStatus.Completed
    })
  );
  const isRouteGeneration = Boolean(
    find(selectedProject?.stages, {
      stage_name: ProjectStagesList.RouteGeneration,
      status: ProjectStatus.InProgress
    })
  );
  return (
    <>
      <Row justify="end" className="mainTimelineContent">
        <Col xxl={18} xl={18} lg={24} className="mainTableTLCol">
          <div className="mainProgressBar">
            <div>
              <Text>Project Status</Text>
              <div>
                <Text
                  className={
                    !isEmpty(selectedProject)
                      ? statusClass(
                          selectedProject.completion_percentage,
                          selectedProject.current_stage_status
                        )
                      : ''
                  }>
                  {!isEmpty(selectedProject) && selectedProject.completion_percentage}%
                </Text>
              </div>
            </div>

            <Progress
              percent={!isEmpty(selectedProject) && selectedProject.completion_percentage}
              strokeWidth={10}
              strokeColor={
                !isEmpty(selectedProject)
                  ? strokePercentageColor(
                      selectedProject.completion_percentage,
                      selectedProject.current_stage_status
                    )
                  : ''
              }
              showInfo={false}
              trailColor={lightGrey}
            />
            {!isEmpty(selectedProject) && isHighResLULCGeneration && isRouteGeneration && (
              <div className="close-project-btn">
                <IsViewerWrapper>
                  <Button
                    type="primary"
                    onClick={() => handleCloseProject(selectedProject.id)}
                    disabled
                    loading={isRequestingCloseProject}>
                    Submit the Bid
                  </Button>
                </IsViewerWrapper>
              </div>
            )}
            {!isEmpty(selectedProject) &&
              ProjectStatus.Completed === selectedProject.current_stage_status &&
              selectedProject.user_permission !== UserPermission.Member && (
                <div className="close-project-btn">
                  <Button
                    type="primary"
                    onClick={() => handleCloseProject(selectedProject.id)}
                    disabled
                    loading={isRequestingCloseProject}>
                    Close the project
                  </Button>
                </div>
              )}
            {!isEmpty(selectedProject) &&
              selectedProject.is_closed &&
              userRole === UserRole.WorkspaceManager && (
                <div className="close-project-btn">
                  <Button
                    type="primary"
                    onClick={() => handleOpenProject(selectedProject.id)}
                    loading={isRequestingCloseProject}>
                    Reopen the project
                  </Button>
                </div>
              )}
          </div>
          <div className="proposed-timeline">
            <Text>Project Timeline</Text>
          </div>

          {project && <ProposedTimeline isRequestProject={isRequestProject} project={project} />}
        </Col>
        <Col xxl={6} xl={6} lg={24} className="mainTeamMemberCol">
          <TeamMemberAndRecentActivity
            selectedProject={selectedProject}
            loading={isRequestingProjectActivityLogs}
          />
        </Col>
      </Row>

      <Modal
        open={isOpenCloseProjectModal}
        className="closed-project-modal"
        title=""
        centered
        width={722}
        footer={null}
        closable={false}>
        <OpenCloseProjectModal
          isClose={Boolean(selectedProject?.is_closed)}
          handleConfirm={handleOpenCloseProject}
          handleCancel={handleCancelOpenCloseProject}
          isLoading={isRequestingCloseProject}
        />
      </Modal>
    </>
  );
};

export default ProjectTimeLine;

const MergedTitle = () => {
  return (
    <div className="mergedTitleText">
      <span style={{ width: '33%', textAlign: 'center' }}>LINE VOLTAGE</span>
      <span style={{ width: '33%', textAlign: 'center' }}>CIRCUIT TYPE</span>
      <span style={{ width: '36%', textAlign: 'center' }}>BID SUBMISSION DATE</span>
    </div>
  );
};

export default MergedTitle;

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes as routeName } from 'constants/pageRoutes.constants';
import SidebarLayout from 'layouts/SidebarLayout';
import NotFound from 'pages/404/NotFound';
import Login from 'pages/Authentication/Login';
import Projects from 'pages/Projects/Projects';
import ProjectTimeLine from 'pages/ProjectTimeline/ProjectTimeLine';
import ProjectMapView from 'pages/ProjectMap/ProjectMapView';
import FileRepository from 'pages/FileRepository/FileRepository';
import Workspace from 'pages/Workspace/Workspace';
import Compare from 'pages/Compare/Compare';
import PrivateRoute from './PrivateRoute';

export const Navigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routeName.LoginUrl} element={<Login />} />
        <Route
          path={routeName.ProjectList}
          element={
            <PrivateRoute>
              <Projects />
            </PrivateRoute>
          }
        />
        <Route
          path={routeName.Mapview}
          element={
            <PrivateRoute>
              <ProjectMapView />
            </PrivateRoute>
          }
        />
        <Route
          path={`${routeName.Projects}/:id`}
          element={
            <PrivateRoute>
              <SidebarLayout />
            </PrivateRoute>
          }>
          <Route
            path={`${routeName.ProjectTimelineUrl}`}
            element={
              <PrivateRoute>
                <ProjectTimeLine />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.FileRepository}
            element={
              <PrivateRoute>
                <FileRepository />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.Workspace}
            element={
              <PrivateRoute>
                <Workspace />
              </PrivateRoute>
            }
          />
          <Route
            path={routeName.Compare}
            element={
              <PrivateRoute>
                <Compare />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

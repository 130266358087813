import { useEffect } from 'react';
import { Button, Form, InputNumber, Typography } from 'antd';
import DotIcon from 'assets/svg/DotIcon';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import { LatLongNumberType } from 'types/aoi.types';
import { isEmpty } from 'lodash';

interface BeeLinePointsFormPropsType {
  latlngA: LatLongNumberType | null;
  latlngB: LatLongNumberType | null;
  handlePointASubmit: Function;
  handlePointBSubmit: Function;
  isDisableA: boolean;
  isDisableB: boolean;
  handleDisableBeelineBtn: (isDisable: boolean) => void;
}

const { Text } = Typography;

const BeeLinePointsForm = ({
  latlngA,
  latlngB,
  isDisableA,
  isDisableB,
  handlePointASubmit,
  handlePointBSubmit,
  handleDisableBeelineBtn
}: BeeLinePointsFormPropsType) => {
  const [pointAForm] = Form.useForm();
  const [pointBForm] = Form.useForm();

  useEffect(() => {
    if (latlngA) {
      pointAForm.setFieldsValue({
        startlatA: latlngA.lat,
        startlngA: latlngA.lng
      });
    }
  }, [latlngA]);

  useEffect(() => {
    if (latlngB) {
      pointBForm.setFieldsValue({
        endlatB: latlngB.lat,
        endlngB: latlngB.lng
      });
    }
  }, [latlngB]);

  const handleFinishPointA = (values: { startlatA: number; startlngA: number }) => {
    handlePointASubmit(values);
  };

  const handleFinishPointB = (values: { endlatB: number; endlngB: number }) => {
    handlePointBSubmit(values);
  };

  const validateLatAndLngRange = (rule: any, value: number) => {
    if (!value || (value >= -90 && value <= 90)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Enter a valid Latitude'));
  };

  const validateLngRange = (rule: any, value: number) => {
    if (!value || (value >= -180 && value <= 180)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Enter a valid Longitude'));
  };

  return (
    <div className="main-start-end-point">
      <div className="enter-point-main">
        <div className="enter-point-title">
          Enter Point A <Text>*</Text>
        </div>
        <Form
          form={pointAForm}
          autoComplete="off"
          className="point-form"
          onFinish={handleFinishPointA}
          onBlurCapture={() => {
            handleDisableBeelineBtn(
              !isEmpty(pointAForm.getFieldError('startlatA')) ||
                !isEmpty(pointAForm.getFieldError('startlngA'))
            );
          }}>
          <Form.Item
            name="startlatA"
            rules={[
              { validator: validateLatAndLngRange },
              { required: true, message: 'Required' }
            ]}>
            <InputNumber disabled={isDisableA} placeholder="Enter Latitude A" />
          </Form.Item>
          <Form.Item
            name="startlngA"
            rules={[{ validator: validateLngRange }, { required: true, message: 'Required' }]}>
            <InputNumber disabled={isDisableA} placeholder="Enter Longitude A" />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <IsViewerWrapper>
                <Button
                  type="primary"
                  className="point-a-btn"
                  htmlType="submit"
                  ghost
                  data-testid="pointA-submit"
                  disabled={
                    !pointAForm.isFieldsTouched(true) ||
                    !!pointAForm.getFieldsError().filter(({ errors }) => errors.length).length ||
                    isDisableA
                  }>
                  Submit
                </Button>
              </IsViewerWrapper>
            )}
          </Form.Item>
        </Form>
      </div>
      <div className="enter-point-main">
        <div className="enter-point-title">
          Enter Point B <Text>*</Text>
        </div>
        <Form
          form={pointBForm}
          onFinish={handleFinishPointB}
          autoComplete="off"
          className="point-form"
          onBlurCapture={() => {
            handleDisableBeelineBtn(
              !isEmpty(pointBForm.getFieldError('endlatB')) ||
                !isEmpty(pointBForm.getFieldError('endlngB'))
            );
          }}>
          <Form.Item
            name="endlatB"
            rules={[
              { validator: validateLatAndLngRange },
              { required: true, message: 'Required' }
            ]}>
            <InputNumber disabled={isDisableB} placeholder="Enter Latitude B" />
          </Form.Item>
          <Form.Item
            name="endlngB"
            rules={[{ validator: validateLngRange }, { required: true, message: 'Required' }]}>
            <InputNumber disabled={isDisableB} placeholder="Enter Longitude B" />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <IsViewerWrapper>
                <Button
                  type="primary"
                  className="point-b-btn"
                  htmlType="submit"
                  ghost
                  data-testid="pointB-submit"
                  disabled={
                    !pointBForm.isFieldsTouched(true) ||
                    !!pointBForm.getFieldsError().filter(({ errors }) => errors.length).length ||
                    !pointAForm.isFieldsTouched(true) ||
                    !!pointAForm.getFieldsError().filter(({ errors }) => errors.length).length ||
                    isDisableB
                  }>
                  Submit
                </Button>
              </IsViewerWrapper>
            )}
          </Form.Item>
        </Form>
      </div>
      {isDisableB && (
        <div className="confirm-beeline flexJustifyAlignCenter">
          <DotIcon />
          Bee Line Confirmed
        </div>
      )}
    </div>
  );
};
export default BeeLinePointsForm;

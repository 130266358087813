import { createSelector } from '@reduxjs/toolkit';
import { filter, find, groupBy, orderBy, uniqBy, upperFirst } from 'lodash';
import { LulcDataType, LulcStyleType } from 'constants/map.constants';

import { decryptValue } from 'utils/decodeEncodeData';
import { RootState } from '..';
import { repofileSlice } from './slice';
import { RepositoryFile, FilesCategory, FileType } from './types';

export const {
  setRequestingFiles,
  setFiles,
  setEditRequestRepositoryFile,
  setAddRequestingRepositoryFile,
  setRequestingFileMapAccess,
  setRequestingCategories,
  setCategories,
  setRequestingDownloadFile,
  setDeleteRequestingRepositoryFile,
  setRequestingGeoserverLayerStyles,
  setGeoserverLayerStyles,
  setUpdateLayerWeightBuffer,
  setUpdateAllLayersWeightBuffer,
  clearRepofiles
} = repofileSlice.actions;
export default repofileSlice.reducer;

export const selectStartRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return filter(repositoryFiles, {
    category: FilesCategory.Start,
    active: true
  });
};

export const activeLayersFile = (state: RootState) => {
  const { layers } = state.layers;

  return filter(layers, {
    active: true
  });
};

export const selectEndRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return filter(repositoryFiles, {
    category: FilesCategory.End,
    active: true
  });
};

export const selectBeeRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return filter(repositoryFiles, {
    category: FilesCategory.Bee,
    active: true
  });
};

export const getLULCRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) => file.category === FilesCategory.LULC && file.active
  );
};

export const getDEMRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) => file.category === FilesCategory.DEM && file.active
  );
};

export const getDSMRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) => file.category === FilesCategory.DSM && file.active
  );
};

export const getHighResolutionSataliteImagesRepoFile = (state: RootState) => {
  const { repositoryFiles } = state.repofiles;

  return find(
    repositoryFiles,
    (file: RepositoryFile) =>
      file.category === FilesCategory.HighResolutionSataliteImages && file.active
  );
};

const selectedGeoserverLayerStyles = (state: RootState) => state.repofiles.geoserverLayerStyles;
export const getLulcStyleLayers = createSelector(
  [selectedGeoserverLayerStyles],
  (geoserverLayerStyles): LulcDataType[] => {
    if (!geoserverLayerStyles) return [];
    const layers = geoserverLayerStyles.map((style: LulcStyleType) => {
      return { title: style.label, value: style.env, color: style.color };
    });

    return orderBy(layers, ['title'], ['asc']);
  }
);

const selectedFiles = (state: RootState) => state.repofiles.repositoryFiles;
export const getFileCategoryOptions = createSelector([selectedFiles], (files: RepositoryFile[]) => {
  if (files.length === 0) return [{ label: 'All', key: 'all' }];

  const categoryOptions = files.map((file: RepositoryFile) => {
    return { label: file.category, key: file.category };
  });

  const uniqueOptions = orderBy(uniqBy(categoryOptions, 'label'), ['label'], ['asc']);
  return [{ label: 'All', key: 'all' }, ...uniqueOptions];
});

export const getUploadedByOptions = createSelector([selectedFiles], (files: RepositoryFile[]) => {
  if (files.length === 0) return [{ label: 'All', key: 'all' }];

  const uploadedByOptions = files.map((file: RepositoryFile) => {
    let firstname;
    let lastname;
    if (file.first_name && file.last_name) {
      firstname = upperFirst(decryptValue(file.first_name));
      lastname = upperFirst(decryptValue(file.last_name));
    } else {
      firstname = upperFirst(decryptValue(file.user_email));
      lastname = '';
    }
    return { label: `${firstname} ${lastname}`, key: file?.created_by };
  });

  const uniqueOptions = orderBy(uniqBy(uploadedByOptions, 'label'), ['label'], ['asc']);
  return [{ label: 'All', key: 'all' }, ...uniqueOptions];
});

const selectedCategory = (state: RootState, category: string) => category;
const selectedUploadedByUser = (state: RootState, category: string, uploadedByUser: string) =>
  uploadedByUser;

export const getFiles = createSelector(
  [selectedFiles, selectedCategory, selectedUploadedByUser],

  (files: RepositoryFile[], category: string, user: string) => {
    if (category !== 'all' && user !== 'all') {
      return files.filter((file) => file.category === category && file.created_by === user);
    }
    if (user !== 'all') {
      return files.filter((file) => file.created_by === user);
    }
    if (category !== 'all') {
      return files.filter((file) => file.category === category);
    }
    return files;
  }
);

const layersData = (state: RootState) => state.repofiles.repositoryFiles;

export const getAOILayersGroupByCategory = createSelector(
  [layersData],
  (layerData): { [key: string]: RepositoryFile[] } => {
    return groupBy(
      filter(
        layerData,
        (layer: RepositoryFile) =>
          layer.active && (layer.type === FileType.lulclow || layer.type === FileType.vector)
      ),
      'category'
    );
  }
);

export const getRouteLayersGroupByCategory = createSelector(
  [layersData],
  (layerData): { [key: string]: RepositoryFile[] } => {
    return groupBy(
      filter(
        layerData,
        (layer: RepositoryFile) =>
          layer.active && (layer.type === FileType.lulchigh || layer.type === FileType.vector)
      ),
      'category'
    );
  }
);

export const getVectorLayersGroupByCategory = createSelector(
  [layersData],
  (layerData): { [key: string]: RepositoryFile[] } => {
    return groupBy(
      filter(layerData, (layer: RepositoryFile) => layer.active && layer.type === FileType.vector),
      'category'
    );
  }
);

export const activeLowresLayersFile = (state: RootState) => {
  const { repositoryFiles: layers } = state.repofiles;

  return filter(
    layers,
    (layer: RepositoryFile) => layer.active && layer.type !== FileType.lulchigh
  );
};

export const activeHighresLayersFile = (state: RootState) => {
  const { repositoryFiles: layers } = state.repofiles;

  return filter(layers, (layer: RepositoryFile) => layer.active && layer.type !== FileType.lulclow);
};

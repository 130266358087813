import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip, Typography } from 'antd';
import ArchiveIcon from 'assets/svg/ArchiveIcon';
import DownloadIcon from 'assets/svg/DownloadIcon';
import EditFileIcon from 'assets/svg/EditFileIcon';
import { primary, white } from 'constants/theme.constants';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { RepositoryFile } from 'store/repositoryfile/types';

interface FileActionButtonsProps {
  record: RepositoryFile;
  handleEditRepoFile: Function;
  handleDownloadFile: Function;
  handleFileArchive: Function;
  isRequestingDownloadFile: boolean;
  downloadFileId: number | null;
}

const { Text } = Typography;

const FileActionButtons = ({
  record,
  handleEditRepoFile,
  handleDownloadFile,
  isRequestingDownloadFile,
  downloadFileId,
  handleFileArchive
}: FileActionButtonsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmArchive = () => {
    handleFileArchive(record);
    handleCloseModal();
  };

  return (
    <>
      <Space size="middle">
        <Tooltip
          className="switchAction"
          placement="bottom"
          color={white}
          title={<Text>Edit</Text>}>
          <IsViewerWrapper>
            <Button
              onClick={() => handleEditRepoFile(record)}
              shape="circle"
              style={{ border: 0 }}
              ghost
              icon={<EditFileIcon color={primary} />}
            />
          </IsViewerWrapper>
        </Tooltip>
        <Tooltip
          className="switchAction"
          placement="bottom"
          color={white}
          title={<Text>Download</Text>}>
          <IsViewerWrapper>
            <a onClick={() => handleDownloadFile(record)}>
              {isRequestingDownloadFile && downloadFileId === record.id ? (
                <LoadingOutlined />
              ) : (
                <DownloadIcon />
              )}
            </a>
          </IsViewerWrapper>
        </Tooltip>
        <Tooltip
          className="switchAction"
          placement="bottom"
          color={white}
          title={<Text>Archive</Text>}>
          <IsViewerWrapper>
            <Button
              style={{ border: 0 }}
              icon={<ArchiveIcon height={20} width={18} />}
              onClick={handleOpenModal}
              ghost
              shape="circle"
            />
          </IsViewerWrapper>
        </Tooltip>
      </Space>
      <ConfirmModal
        isOpen={isModalOpen}
        isClosable
        isCancelButton
        title="Confirm Archive"
        description="Are you sure you want to archive this file?"
        okbuttonText="Archive"
        cancelbuttonText="Cancel"
        handleClickOkButton={handleConfirmArchive}
        handleClickCancelButton={handleCloseModal}
      />
    </>
  );
};

export default FileActionButtons;

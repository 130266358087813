import { lightDarkGrey3 } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const EyeCloseIconV1 = ({ color, height, width, onClick, classname }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      className={classname}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26517 1.26517C9.41161 1.11872 9.41161 0.881282 9.26517 0.734835C9.11872 0.588388 8.88128 0.588388 8.73483 0.734835L0.734835 8.73483C0.588388 8.88128 0.588388 9.11872 0.734835 9.26517C0.881282 9.41161 1.11872 9.41161 1.26517 9.26517L2.78267 7.74766C3.44158 8.05161 4.19593 8.25 5 8.25C6.30889 8.25 7.486 7.72431 8.33419 7.07332C8.75884 6.74741 9.10728 6.38544 9.35176 6.03316C9.59157 5.6876 9.75 5.32423 9.75 5C9.75 4.67577 9.59157 4.3124 9.35176 3.96684C9.10728 3.61456 8.75884 3.25259 8.33419 2.92668C8.19889 2.82284 8.05522 2.72218 7.90399 2.62634L9.26517 1.26517ZM6.56435 3.96598L6.01536 4.51497C6.08564 4.66183 6.125 4.82632 6.125 5C6.125 5.62132 5.62132 6.125 5 6.125C4.82632 6.125 4.66183 6.08564 4.51497 6.01536L3.96598 6.56435C4.26239 6.76067 4.61784 6.875 5 6.875C6.03553 6.875 6.875 6.03553 6.875 5C6.875 4.61784 6.76067 4.26239 6.56435 3.96598Z"
        fill={color}
      />
      <path
        d="M5.26715 3.14389C5.35143 3.15591 5.43797 3.1317 5.49817 3.0715L6.27965 2.29002C6.41201 2.15766 6.3568 1.93331 6.17472 1.88994C5.79977 1.80064 5.40623 1.75 5 1.75C3.69111 1.75 2.514 2.27569 1.66581 2.92668C1.24116 3.25259 0.892718 3.61456 0.648245 3.96684C0.40843 4.3124 0.25 4.67577 0.25 5C0.25 5.32423 0.40843 5.6876 0.648245 6.03316C0.839358 6.30855 1.09401 6.58986 1.39893 6.85524C1.4951 6.93893 1.63909 6.93058 1.72924 6.84044L3.0715 5.49817C3.1317 5.43797 3.15591 5.35143 3.14389 5.26715C3.13144 5.17989 3.125 5.0907 3.125 5C3.125 3.96447 3.96447 3.125 5 3.125C5.0907 3.125 5.17989 3.13144 5.26715 3.14389Z"
        fill={color}
      />
    </svg>
  );
};
EyeCloseIconV1.defaultProps = {
  color: lightDarkGrey3,
  height: 10,
  width: 10,
  onClick: () => {},
  classname: ''
};

export default EyeCloseIconV1;

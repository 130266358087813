import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().email('Required').required('Required'),
  password: yup.string().required('Required')
});

export const addMemberSchema = yup.object().shape({
  email: yup.string().email('Required').required('Required'),
  members: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string().email('Required')
      })
    )
    .required('Required')
});

export const adminDetailSchema = yup.object().shape({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  emp_id: yup.string().required('Required'),
  phone_no: yup
    .string()
    .test('len', 'Enter only 10 digits', (val) => val?.length === 10)
    .matches(/^\d+$/, 'Enter Only Number')
    .required('Required')
    .typeError('Enter Only Number'),
  userEmail: yup.string().email('Required').required('Required')
});

export const ManagerMemberDetailSchema = yup.object().shape({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  emp_id: yup.string().required('Required'),
  department: yup.string().required('Required'),
  phone_no: yup
    .string()
    .test('len', 'Enter only 10 digits', (val) => val?.length === 10)
    .matches(/^\d+$/, 'Enter Only Number')
    .required('Required')
    .typeError('Enter Only Number'),
  userEmail: yup.string().email('Required').required('Required')
});

export const createProjectSchema = yup.object().shape({
  project_title: yup
    .string()
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/, 'Enter valid project name')
    .required()
    .typeError('Enter valid project name'),
  project_member_ids: yup.array().of(yup.string()).required('Required')
});

export const organizationSchema = yup.object().shape({
  organisation_name: yup.string().required('Required'),
  admin_firstname: yup.string().required('Required'),
  admin_lastname: yup.string().required('Required'),
  admin_email: yup.string().email('Required').required('Required')
});

export const fileRepositorySchema = yup.object().shape({
  category_id: yup.string().required('Required'),
  comment: yup.string().required('Required'),
  buffer: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? 0 : value))
    .min(0, 'Min val:0')
    .max(10000, 'Max val:10000'),
  weight: yup.number().integer().min(0).max(100, 'Min val:0, Max val:100')
});

export const editFileRepoSchema = yup.object().shape({
  filename: yup
    .string()
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, 'Enter a valid filename (ex.:file-name_1.kml)')
    .required('Required'),
  category_id: yup.string().required('Required'),
  comment: yup.string().required('Required'),
  buffer: yup.number().min(0, 'Min val:0').max(10000, 'Max val:10000').required('Required'),
  weight: yup.number().integer().min(0, 'Min val:0').max(100, 'Max val:100').required('Required')
});

export const coordSchema = yup.object().shape({
  lat: yup.string().required('Required'),
  lng: yup.string().required('Required')
});

import { createSelector } from '@reduxjs/toolkit';
import { filter, isEmpty, orderBy, toNumber, upperFirst } from 'lodash';
import {
  AvailableProjectMember,
  MemberT,
  ProjectActivityData,
  ProjectMember,
  ProjectStatus,
  ProjectType,
  TeamMember
} from 'store/projects/types';
import { decryptValue } from 'utils/decodeEncodeData';
import { RootState } from '..';
import { projectSlice } from './slice';

export const {
  setRequestingProjects,
  setProjects,
  setAddProjectRequesting,
  setSelectedProject,
  setRequestProject,
  setProject,
  setProjectMemberRequest,
  setAvailableProjectMemberRequesting,
  setAvailableProjectMembers,
  setDeadlineStageUploadRequesting,
  setRequestingAddProjectDetails,
  setRequestingRemoveMemberFromProject,
  setRequestingRemoveMemberFromOrganization,
  setRequestingProjectActivityLogs,
  setProjectActivityLogs,
  setRequestCloseProject,
  setProjectDetail,
  setRequestLulcLayerDetails,
  setLULCLayerDetails,
  clearProjects
} = projectSlice.actions;
export default projectSlice.reducer;

export const getTeamMembers = (members: TeamMember[] | []): Array<MemberT> => {
  return members?.map((member: TeamMember) => {
    if (member.first_name && member.last_name) {
      const firstname = upperFirst(decryptValue(member.first_name));
      const lastname = upperFirst(decryptValue(member.last_name));

      return {
        label: upperFirst(`${firstname} ${lastname}`),
        value: member.id
      };
    }

    return {
      label: decryptValue(member.email),
      value: member.id
    };
  });
};

export const getMember = (member: TeamMember): MemberT => {
  if (member.first_name && member.last_name) {
    const firstname = upperFirst(decryptValue(member.first_name));
    const lastname = upperFirst(decryptValue(member.last_name));

    return {
      label: upperFirst(`${firstname} ${lastname}`),
      value: member.id
    };
  }

  return {
    label: decryptValue(member.email),
    value: member.id
  };
};

export const getTeamMember = (member: ProjectMember | TeamMember) => {
  if (member.first_name && member.last_name) {
    return {
      label: upperFirst(`${member.first_name} ${member.last_name}`),
      value: member.id
    };
  }

  return {
    label: member.email,
    value: member.id
  };
};

export const selectAvailableProjectMembers = (state: RootState) => {
  const { availableProjectMembers } = state.projects;

  return availableProjectMembers
    ?.filter((projectMember: AvailableProjectMember) => projectMember)
    .map((member: AvailableProjectMember) => {
      if (member.first_name && member.last_name) {
        const firstname = upperFirst(decryptValue(member.first_name));
        const lastname = upperFirst(decryptValue(member.last_name));
        return {
          label: upperFirst(`${firstname} ${lastname}`),
          value: member.id
        };
      }

      return {
        label: decryptValue(member.email),
        value: member.id
      };
    });
};

export const getProjectCountByInprogress = (projects: Array<ProjectType>) => {
  if (projects?.length > 0) {
    return projects.filter(
      (obj) => obj.completion_percentage! > 0 && obj.completion_percentage! < 100
    ).length;
  }

  return 0;
};

export const getProjectCountByCompletedClosed = (
  projects: Array<ProjectType>,
  status: ProjectStatus
) => {
  if (projects?.length > 0) {
    return projects.filter(
      (obj) => obj.current_stage_status === status && obj.completion_percentage! === 100
    ).length;
  }

  return 0;
};

const allProjects = (state: RootState) => state.projects.projects;
const searchQuery = (state: RootState, text: string) => text;
const activityLogs = (state: RootState) => state.projects.projectActivityLogs;
const selectUserId = (state: RootState, id: string | undefined | null) => id;

export const getProjectActivityData = createSelector(
  [activityLogs, selectUserId],
  (activityLog, userId) => {
    if (!userId) return orderBy(activityLog, ['created_at'], ['desc']);
    const filterData = activityLog.filter(
      (activity: ProjectActivityData) => activity.user_id === userId
    );
    return orderBy(filterData, ['created_at'], ['desc']);
  }
);

export const getProjectLists = createSelector(
  [allProjects, searchQuery],
  (projects, searchquery) => {
    return filter(projects, (item) => {
      if (!isEmpty(searchquery)) {
        return (
          item.id === toNumber(searchquery) ||
          item.name.toLowerCase().includes(searchquery.toLowerCase())
        );
      }

      return projects;
    });
  }
);

import { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Avatar, Card, List, Modal, Tooltip, Typography } from 'antd';
import { upperFirst } from 'lodash';
import { white } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import AddProjectMember from 'components/Modal/AddProjectMember/AddProjectMemberModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { UserPermission } from 'store/users/types';
import { getMember, getProjectActivityData } from 'store/projects';
import { ProjectActivityData, ProjectType, TeamMember, MemberT } from 'store/projects/types';
import { shortLabel, getDateTimeFormat } from 'utils/util';
import { decryptValue } from 'utils/decodeEncodeData';
import { getAsyncStorageValue } from 'utils/localStorage';
import { getAvailableProjectMembers } from 'store/projects/actions';

interface ProjectTeamMemberProps {
  selectedProject: ProjectType;
  loading: boolean;
}
const { Text } = Typography;

const TeamMemberAndRecentActivity = ({ selectedProject, loading }: ProjectTeamMemberProps) => {
  const dispatch = useAppDispatch();
  const [selectedRow, setSelectedRow] = useState<ProjectType | null>(null);
  const [isOpenAddProjectMemberModal, setAddProjectMemberModal] = useState(false);
  const [currentAvtar, setCurrentAvtar] = useState<MemberT | null>(null);
  const activityData: Array<ProjectActivityData> | [] = useAppSelector((state) =>
    getProjectActivityData(state, currentAvtar?.value)
  );

  const handleOnClick = (item: MemberT) => {
    if (currentAvtar && currentAvtar.value === item.value) {
      setCurrentAvtar(null);
    } else {
      setCurrentAvtar(item);
    }
  };

  const handleAddProjectMemberForm = () => {
    if (
      selectedProject?.user_permission === UserPermission.Manager ||
      selectedProject?.user_permission === UserPermission.WorkspaceManager
    ) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(getAvailableProjectMembers(token));
      });
    }
    setAddProjectMemberModal(!isOpenAddProjectMemberModal);
    setSelectedRow(selectedProject);
  };

  const handleCloseAddProjectMemberModal = () => {
    setSelectedRow(null);
    setAddProjectMemberModal(false);
  };

  return (
    <>
      <Card title="Project Members" className="mainMemberContent">
        {selectedProject?.members.map((teamMember: TeamMember) => {
          const member = getMember(teamMember);
          return (
            <Tooltip
              color={white}
              overlayClassName="tootltipMember"
              title={member.label}
              placement="bottom"
              key={member.value}>
              <Avatar
                className={currentAvtar?.value === member.value ? 'changecolor' : 'memberAvatar'}
                onClick={() => handleOnClick(member)}>
                {shortLabel(member.label)}
              </Avatar>
            </Tooltip>
          );
        })}
        {(selectedProject?.user_permission === UserPermission.Manager ||
          selectedProject?.user_permission === UserPermission.WorkspaceManager) && (
          <Tooltip
            title="Add members to project"
            color={white}
            placement="bottom"
            overlayClassName="tootltipMember">
            <Avatar
              className="memberAvatar project-member-add-icon"
              onClick={() => handleAddProjectMemberForm()}
              icon={<BiPlus size={14} />}
            />
          </Tooltip>
        )}
      </Card>

      <Card
        title="Recent Activity"
        extra={
          <span>
            {currentAvtar ? (
              <Avatar className="avtarchangecolor">{shortLabel(currentAvtar.label)}</Avatar>
            ) : (
              'All'
            )}
          </span>
        }
        className="mainActivityContent">
        <List
          className="listMemberActivity"
          loading={loading}
          dataSource={activityData}
          renderItem={(item: ProjectActivityData) => {
            return (
              <List.Item
                extra={<Text className="activity-time">{getDateTimeFormat(item.created_at)}</Text>}
                key={item.id}
                className="memberListContent">
                <List.Item.Meta
                  avatar={
                    <Avatar className="itemAvatar">
                      {(!item?.first_name && upperFirst(decryptValue(item?.email)[0])) ||
                        shortLabel(
                          `${decryptValue(item?.first_name)} ${decryptValue(item?.last_name)}`
                        )}
                    </Avatar>
                  }
                  title={`${
                    !item?.first_name && !item?.last_name
                      ? decryptValue(item?.email)
                      : `${upperFirst(decryptValue(item?.first_name))} ${upperFirst(
                          decryptValue(item?.last_name)
                        )}`
                  }`}
                  description={item.message}
                  className="memberListItem"
                />
              </List.Item>
            );
          }}
        />
      </Card>

      {isOpenAddProjectMemberModal && selectedRow && (
        <Modal
          open={isOpenAddProjectMemberModal}
          okText="Create"
          cancelText="Cancel"
          centered
          width={697}
          className="newModal"
          closable={false}
          footer={null}>
          <AddProjectMember
            handleCloseAddProjectMemberModal={handleCloseAddProjectMemberModal}
            selectedRow={selectedRow}
            isProjectMember="timeline"
          />
        </Modal>
      )}
    </>
  );
};

export default TeamMemberAndRecentActivity;

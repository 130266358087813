import { filter, isEmpty, sortBy } from 'lodash';
import { RepositoryFile, FilesCategory } from 'store/repositoryfile/types';
import { UserRole } from 'store/users/types';
import { createSelector } from '@reduxjs/toolkit';
import { fromLonLat } from 'ol/proj';
import { LineString } from 'ol/geom';
import { getLength } from 'ol/sphere';
import { Route, RouteDetail, TowerOptionType } from 'store/route/types';
import { routeSlice } from './slice';
import { RootState } from '..';

export const {
  setRequestingRouteFiles,
  setRoutes,
  setRequestingGenerateRoute,
  setRequestingEditRoute,
  setRequestingUpdateRouteDetails,
  setRequestingTowerType,
  setTowerTypes,
  setRequestingDeviationAngle,
  setRequestingUpdateTowerType,
  clearRoute,
  setRequestingDownloadDetails
} = routeSlice.actions;
export default routeSlice.reducer;

export const getRouteFiles = (state: RootState) => {
  const { routes } = state.route;

  return sortBy(
    filter(routes, {
      category: FilesCategory.Routes,
      active: true
    }),
    ['id']
  );
};

export const getSuperAdminRouteFiles = (state: RootState) => {
  const { routes } = state.route;

  return sortBy(
    filter(routes, {
      category: FilesCategory.Routes,
      active: true,
      user_role: UserRole.WorkspaceManager
    }),
    ['id']
  );
};

export const getAdminManagerRouteFiles = (state: RootState) => {
  const { routes } = state.route;

  return sortBy(
    filter(
      routes,
      (file: RepositoryFile) =>
        file.category === FilesCategory.Routes &&
        file.active &&
        file.user_role !== UserRole.WorkspaceManager
    ),
    ['id']
  );
};

export const selectDsmElevetion = (state: RootState) => {
  const { dsmElevetion } = state.route;
  if (!dsmElevetion) {
    return [0, 50, 100, 200];
  }
  const min = dsmElevetion?.min;
  const max = dsmElevetion?.max;
  const diff = max - min;
  const step = diff / 3;
  const secondValue = min + step;
  const thirdValue = secondValue + step;

  return [min, secondValue, thirdValue, max];
};

export const selectDemElevetion = (state: RootState) => {
  const { demElevetion } = state.route;
  if (!demElevetion) {
    return [0, 50, 100, 200];
  }
  const min = demElevetion?.min;
  const max = demElevetion?.max;
  const diff = max - min;
  const step = diff / 3;
  const secondValue = min + step;
  const thirdValue = secondValue + step;

  return [min, secondValue, thirdValue, max];
};

interface RouteData {
  total_tower_cost: any;
  total_row_cost: any;
  tower_stats: any;
  linear_count: any;
  area_cross_stat: any;
  id: string;
  length: number;
  linearCounts: Array<{ file_cat_name: string; count: number }>;
  areaCrossStats: Array<{ file_cat_name: string; area_km_sq: number; unit_cost: number }>;
  towerTypes: string[];
  index: number;
}

export const computeRouteData = (routeList: Route[]): RouteData[] => {
  const routeDataArray: RouteData[] = [];

  routeList.forEach((route) => {
    const uniqueTowerTypes = new Set<string>();
    const counts: Array<{ file_cat_name: string; count: number }> = [];
    const areaStats: Array<{ file_cat_name: string; area_km_sq: number; unit_cost: number }> = [];
    let length = 0;

    // Process tower types
    route.tower_stats?.forEach((stat) => {
      uniqueTowerTypes.add(stat.type);
    });

    // Process linear counts
    counts.push(
      ...(route.linear_count?.map((line) => ({
        file_cat_name: line.file_cat_name,
        count: line.count
      })) || [])
    );

    // Process area stats
    areaStats.push(
      ...(route.area_cross_stat?.map((stat) => ({
        file_cat_name: stat.file_cat_name,
        area_km_sq: stat.area_km_sq,
        unit_cost: stat.unit_cost
      })) || [])
    );

    // Process route lengths
    if (route.geometry && route.geometry.type === 'LineString') {
      const line = new LineString(
        route.geometry.coordinates.map((point: [number, number]) => fromLonLat(point))
      );
      length = getLength(line);
    }
    const totalTowerCost = route.total_tower_cost;
    const totalRowCost = route.total_row_cost;

    const routeData: RouteData = {
      id: route.id,
      length,
      linearCounts: counts,
      areaCrossStats: areaStats,
      towerTypes: Array.from(uniqueTowerTypes),
      total_tower_cost: totalTowerCost,
      total_row_cost: totalRowCost,
      tower_stats: route.tower_stats,
      linear_count: route.linear_count,
      area_cross_stat: route.area_cross_stat,
      index: route.index
    };
    routeDataArray.push(routeData);
  });

  return routeDataArray;
};

export const getGeneratedRoute = (state: RootState) => {
  const { routeList } = state.route;
  if (isEmpty(routeList)) return [];
  return routeList.filter(
    (route: RouteDetail) => route.status !== 'Requested' && route.status !== 'Failed'
  );
};

export const getSelectedRoute = createSelector(
  [
    (state: RootState) => state.route.routeList,
    (state: RootState, selectedRouteIndex: string[]) => selectedRouteIndex
  ],
  (routeList: any[], selectedRouteIndex: string | any[]) => {
    if (isEmpty(routeList)) return [];

    if (isEmpty(selectedRouteIndex)) {
      return computeRouteData(routeList.slice(0, 3));
    }

    return computeRouteData(
      routeList
        .filter((route: RouteDetail) => selectedRouteIndex.includes(route.id))
        .sort(
          (a: RouteDetail, b: RouteDetail) =>
            selectedRouteIndex.indexOf(a.id) - selectedRouteIndex.indexOf(b.id)
        )
    );
  }
);

export const getDropDownOptions = createSelector(
  [
    (state: RootState) => state.route.routeList,
    (state: RootState, selectedRouteIndex: string[]) => selectedRouteIndex
  ],
  (routeList, selectedRouteIndex) => {
    if (isEmpty(routeList)) return [];
    if (isEmpty(selectedRouteIndex)) {
      return routeList
        .filter((route: RouteDetail) => route.status !== 'Requested' && route.status !== 'Failed')
        .slice(3)
        .map((route: RouteDetail) => ({
          label: `ROUTE ${route.index}`,
          value: route.id
        }));
    }

    return routeList
      .filter(
        (route: RouteDetail) =>
          !selectedRouteIndex.includes(route.id) &&
          route.status !== 'Requested' &&
          route.status !== 'Failed'
      )
      .map((route: RouteDetail) => ({
        label: `Route ${route.index}`,
        value: route.id
      }));
  }
);

export const getTowerTypeOptions = createSelector(
  [(state: RootState) => state.route.towerTypes],
  (towers) => {
    if (isEmpty(towers)) return [];

    return towers.map((t: TowerOptionType) => ({
      label: t.name,
      value: t.id
    }));
  }
);

import { useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Form, Input } from 'antd';
import SatsureSkies from 'assets/svg/SatsureLogoWhite.png';
import SatSureLogo from 'assets/png/SatSureLogo.png';
import { routes } from 'constants/pageRoutes.constants';
import { USER_ROLE } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { getRSA, userLogin } from 'store/users/actions';
import type { LoginPayload } from 'store/users/types';
import { getStorageValue } from 'utils/localStorage';
import { loginSync } from 'utils/yupsync';
import { rsaEnc } from 'utils/decodeEncodeData';
import './styles.less';

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingLogin, rsaKey, user } = useAppSelector((state) => state.user);

  useEffect(() => {
    const userRole = getStorageValue(USER_ROLE);

    if (!rsaKey) {
      dispatch(getRSA());
    }

    if (userRole && user) {
      handleNavigate(); // eslint-disable-line
    }
  }, []);

  const handleNavigate = () => {
    navigate(routes.ProjectList);
  };

  const handleLogin = (values: LoginPayload) => {
    if (!rsaKey) {
      toast.error('RSA key not found');
      return;
    }

    dispatch(
      userLogin(
        {
          email: rsaEnc(values.email, rsaKey),
          password: rsaEnc(values.password, rsaKey)
        },
        handleNavigate
      )
    );
  };

  return (
    <div className="mainRow">
      <div className="textBetweenLogo">Namaste!</div>
      <img className="logoContent" src={SatsureSkies} alt="SatsureSkies" />
      <div className="formContent">
        <Card className="mainLoginCard">
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="mainLoginForm"
            onFinish={handleLogin}>
            <h1 className="formTitle">Login</h1>
            <Form.Item
              name="email"
              label="Email Address"
              className="inputField"
              rules={[loginSync]}>
              <Input placeholder="Enter Email Address" className="inputLabel" />
            </Form.Item>
            <Form.Item name="password" label="Password" className="inputField" rules={[loginSync]}>
              <Input.Password placeholder="Enter Password" className="inputLabel" />
            </Form.Item>
            <Button type="primary" htmlType="submit" block size="large" loading={isLoadingLogin}>
              Log In
            </Button>
          </Form>
          <div className="forgotPasswordBtn">
            {/* <Button type="link">Forgot Password ?</Button> */}
          </div>
          <div className="formBottomLogo">
            <p>Powered By</p>
            <img src={SatSureLogo} width="150" alt="SatSureLogo" />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default memo(Login);

import React from 'react';
import { Tooltip } from 'antd';

interface ProjectNameTooltipProps {
  projectName: string;
}

const ProjectNameTooltip: React.FC<ProjectNameTooltipProps> = ({ projectName }) => (
  <Tooltip title={projectName} placement="top">
    <span
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100px'
      }}>
      {projectName}
    </span>
  </Tooltip>
);

export default ProjectNameTooltip;

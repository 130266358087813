import { useEffect, useRef } from 'react';
import { Feature } from 'ol';
import { LineString, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { Fill, Text } from 'ol/style';
import Stroke from 'ol/style/Stroke';
import PointRed from 'assets/png/PointRed.png';
import { black, lightBlack, white } from 'constants/theme.constants';
import { useMap } from './MapContext';

interface BeePointsAndLinePropsType {
  startPoint: number[] | null;
  endPoint: number[] | null;
}

const BeePointsAndLine = ({ startPoint, endPoint }: BeePointsAndLinePropsType) => {
  const map = useMap();
  const pointARef: any = useRef(null);
  const pointBRef: any = useRef(null);
  // @ts-ignore
  const beeLineRef: any = useRef<VectorLayer>();

  useEffect(() => {
    if (startPoint && endPoint && map) {
      const line = new LineString([fromLonLat(startPoint), fromLonLat(endPoint)]);
      const view = map.getView();
      const extent = line.getExtent();
      view.fit(extent, {
        duration: 1000,
        padding: [200, 200, 200, 200]
      });
    }
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (startPoint) {
      const startPointMarker = new Feature({
        geometry: new Point(fromLonLat(startPoint)),
        name: 'Point A'
      });
      const vectorLayerStartpoint = new VectorLayer({
        source: new VectorSource({
          features: [startPointMarker]
        }),
        zIndex: 300,
        style: new Style({
          text: new Text({
            fill: new Fill({
              color: white
            }),
            backgroundFill: new Fill({
              color: lightBlack
            }),
            padding: [7, 10, 7, 10],
            textAlign: 'start',
            offsetX: -20,
            offsetY: -40,
            text: 'Point A'
          }),
          image: new Icon({
            anchor: [0.5, 1],
            height: 20,
            width: 20,
            src: PointRed
          }),
          zIndex: 300
        })
      });

      vectorLayerStartpoint.set('name', 'pointa');
      pointARef.current = vectorLayerStartpoint;

      if (map) {
        map.addLayer(vectorLayerStartpoint);
        return () => {
          map.removeLayer(vectorLayerStartpoint);
        };
      }
    }
  }, [startPoint, map]);

  // eslint-disable-next-line
  useEffect(() => {
    if (endPoint) {
      const endPointMarker = new Feature({
        geometry: new Point(fromLonLat(endPoint)),
        name: 'Point B'
      });
      const vectorSourceEndPoint = new VectorSource({
        features: [endPointMarker]
      });
      const vectorLayerEndpoint = new VectorLayer({
        source: vectorSourceEndPoint,
        zIndex: 300,
        style: new Style({
          text: new Text({
            fill: new Fill({
              color: white
            }),
            backgroundFill: new Fill({
              color: lightBlack
            }),
            padding: [7, 10, 7, 10],
            textAlign: 'start',
            offsetX: -20,
            offsetY: 20,
            text: 'Point B'
          }),
          image: new Icon({
            anchor: [0.5, 1],
            src: PointRed,
            height: 20,
            width: 20
          }),
          zIndex: 300
        })
      });
      vectorLayerEndpoint.set('name', 'pointb');
      pointBRef.current = vectorLayerEndpoint;
      if (map) {
        map.addLayer(vectorLayerEndpoint);
        return () => {
          map.removeLayer(vectorLayerEndpoint);
        };
      }
    }
  }, [endPoint, map]);

  // eslint-disable-next-line
  useEffect(() => {
    if (startPoint && endPoint) {
      const beeLine = new Feature({
        geometry: new LineString([fromLonLat(startPoint), fromLonLat(endPoint)]),
        name: 'Linestring A-B'
      });
      const vectorSourceBeeLine = new VectorSource({
        features: [beeLine]
      });
      const vectorLayerBeeLine = new VectorLayer({
        source: vectorSourceBeeLine,
        zIndex: 300,
        style: new Style({
          stroke: new Stroke({
            color: black,
            width: 1
          }),
          zIndex: 300
        })
      });
      vectorLayerBeeLine.set('name', 'beeline');
      beeLineRef.current = vectorLayerBeeLine;
      if (map) {
        map.addLayer(vectorLayerBeeLine);

        return () => {
          map.removeLayer(vectorLayerBeeLine);
        };
      }
    }
  }, [startPoint, endPoint, map]);

  return null;
};

export default BeePointsAndLine;

import { Button, Result, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constants/pageRoutes.constants';
import { primary } from 'constants/theme.constants';
import { useUserDetail } from 'hooks/useUserDetail';

const { Text } = Typography;

const style = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const NotFound = () => {
  const navigate = useNavigate();
  const userDetail = useUserDetail();

  const handleNavigate = () => {
    if (userDetail?.isCurrentUser) {
      navigate(routes.ProjectList, { replace: true });
    } else {
      navigate(routes.LoginUrl, { replace: true });
    }
  };

  return (
    <div style={style}>
      <Result
        status="404"
        subTitle={
          <Text
            style={{
              color: primary,
              fontSize: '18px',
              fontWeight: 'lighter',
              letterSpacing: '0.5px'
            }}>
            Sorry, the page you visited does not exist.
          </Text>
        }
        extra={
          <Button type="primary" onClick={handleNavigate}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;

import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { AiOutlineLoading } from 'react-icons/ai';
import VectorSource from 'ol/source/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { Point, Polygon } from 'ol/geom';
import { Modify } from 'ol/interaction';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import CircleStyle from 'ol/style/Circle';
import Collection from 'ol/Collection';
import { black, white } from 'constants/theme.constants';
import { aoiBackColors } from 'constants/common.constant';
import { AOIDetail } from 'store/aoi/types';
import { CoordinateType } from 'types/common.types';
import { useMap } from './MapContext';

interface EditAOILayerPropsType {
  editedAOI: AOIDetail | null;
  handleSaveEditAOICoordsData: Function;
  handleEditAoi: Function;
}

const EditAOILayer = ({
  editedAOI,
  handleSaveEditAOICoordsData,
  handleEditAoi
}: EditAOILayerPropsType) => {
  const map = useMap();
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const vectorSourceRef = useRef<VectorSource>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 51,
      style: (feature) => {
        const fillColor = feature.get('fillColor');
        const aoiPointColor = feature.get('color');
        const type = feature.get('type');
        if (type === CoordinateType.Point) {
          return new Style({
            image: new CircleStyle({
              radius: 3,
              fill: new Fill({ color: aoiPointColor }),
              stroke: new Stroke({ color: black })
            }),
            zIndex: 2
          });
        }
        return new Style({
          fill: new Fill({
            color: aoiBackColors[fillColor]
          }),
          stroke: new Stroke({
            color: fillColor,
            width: 1
          }),
          zIndex: 51
        });
      }
    });
    vectorLayerRef.current.set('name', 'editaoi');
    if (map) {
      map.addLayer(vectorLayerRef.current);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
      }
    };
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (map && editedAOI && editedAOI.geometry && !isEmpty(editedAOI.geometry.coordinates)) {
      setLoading(true);

      const coords = editedAOI.geometry.coordinates.map((ring: any) =>
        ring.map((coord: any) => fromLonLat(coord))
      );
      const polygonFeature = new Feature({
        geometry: new Polygon(coords),
        fillColor: editedAOI.color
      });
      polygonFeature.setId(editedAOI.id);
      polygonFeature.set('type', 'Polygon');
      vectorSourceRef.current?.addFeature(polygonFeature);

      coords[0].forEach((coord: any, index: number) => {
        const aoiPointFeature = new Feature({
          geometry: new Point(coord),
          color: editedAOI.color,
          type: 'Point'
        });
        aoiPointFeature.set('index', index);
        aoiPointFeature.setId(`${editedAOI.id}-${index}`);
        vectorSourceRef.current?.addFeature(aoiPointFeature);
      });

      const pointFeatures = vectorSourceRef.current
        ?.getFeatures()
        .filter((f) => f.get('type') === 'Point');

      const modify = new Modify({
        features: new Collection(pointFeatures)
      });
      map.addInteraction(modify);

      modify.on('modifyend', () => {
        // @ts-ignore
        const pointsCoordinates = vectorSourceRef.current
          .getFeatures()
          // @ts-ignore
          .filter((feature) => feature.getGeometry().getType() === 'Point')
          .sort((a, b) => a.get('index') - b.get('index')) // @ts-ignore
          .map((feature) => feature.getGeometry().getCoordinates());

        // @ts-ignore
        polygonFeature.getGeometry().setCoordinates([pointsCoordinates]);

        handleEditAoi();
        handleSaveEditAOICoordsData(pointsCoordinates.map((coords) => toLonLat(coords)));
      });

      setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => {
        map.removeInteraction(modify);
      };
    }
  }, [editedAOI]);

  return (
    <Spin
      spinning={loading}
      className="spinner"
      style={{
        width: '100%',
        position: 'absolute'
      }}
      indicator={<AiOutlineLoading className="spin-loader" speed={500} color={white} />}
    />
  );
};

export default EditAOILayer;

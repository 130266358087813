import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistor, store } from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/ttf/ManropeBold.ttf';
import './fonts/ttf/ManropeExtraBold.ttf';
import './fonts/ttf/ManropeExtraLight.ttf';
import './fonts/ttf/ManropeLight.ttf';
import './fonts/ttf/ManropeMedium.ttf';
import './fonts/ttf/ManropeRegular.ttf';
import './fonts/ttf/ManropeSemiBold.ttf';
import './fonts/ttf/ManropeVariableFontWeight.ttf';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://8aac660815d58a8c1854def2ff6a9cb5@o4506252999000064.ingest.us.sentry.io/4507508897611776',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer theme="colored" />
      <App />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DateFormat2, TimeFormat } from 'constants/common.constant';
import { orderBy } from 'lodash';
import { TimezoneContext } from 'context/CreateUseContext';
import { useContext } from 'react';
import moment from 'moment-timezone';
import {
  ProjectStage,
  ProjectStagesList,
  ProjectTimeline,
  ProposedTimelineStatus
} from 'store/projects/types';

interface ManagerProposedTimelineProps {
  project: ProjectTimeline;
  isRequestProject: boolean;
}
const { Text, Link } = Typography;

const ProposedTimeline = ({ project, isRequestProject }: ManagerProposedTimelineProps) => {
  const timeZone = useContext(TimezoneContext);
  const columns: ColumnsType<ProjectStage> = [
    {
      title: 'Date',
      dataIndex: 'deadline',
      key: 'date',
      align: 'right',
      width: '100px',
      render: (_, record) => {
        if (record.completion_date !== null) {
          const date: any = record.completion_date
            ? moment(record.completion_date).format(DateFormat2)
            : '-';
          const time: any = record.completion_date
            ? `${moment(record.completion_date).tz('Asia/Kolkata').format(TimeFormat)} ${
                timeZone?.timezone
              }`
            : '';

          return (
            <>
              <Text key={`${record.id}_date`} className="scheduleColumn">
                {date}
              </Text>
              <Text key={`${record.id}_time`} className="scheduleColumn">
                {time}
              </Text>
            </>
          );
        }
        return null;
      }
    },
    {
      title: '',
      className: 'mainEmptyCol',
      dataIndex: '',
      key: 'empty',
      width: '40px',
      render: (_, record) => {
        let conClass = '';
        if (
          record.status === ProposedTimelineStatus.Completed ||
          record.status === ProposedTimelineStatus.Approved
        ) {
          conClass = 'completeContent';
        } else if (
          record.status === ProposedTimelineStatus.InProgress ||
          record.status === ProposedTimelineStatus.SentforApproval ||
          record.status === ProposedTimelineStatus.Started
        ) {
          conClass = '';
        } else if (record.status === ProposedTimelineStatus.NotStarted) {
          conClass = 'notStartedPendingCon';
        } else if (record.status === null) {
          conClass = 'completePendingCon';
        }
        if (project?.stages.length < 1) {
          conClass = 'completeContent row-empty-content';
        }
        if (record.stage_name === ProjectStagesList.RouteGeneration) {
          conClass += ' row-empty-content';
        }

        return (
          <div key={record.id} className="emptyColum">
            <div className={`roundContent ${conClass}`} />
          </div>
        );
      }
    },
    {
      title: 'Project Stages',
      dataIndex: 'stage_name',
      key: 'stage_name',
      width: '50%',
      render: (_, record) => {
        let textColor = 'black';
        if (
          record.status === ProposedTimelineStatus.InProgress ||
          record.status === ProposedTimelineStatus.Completed ||
          record.status === ProposedTimelineStatus.Approved
        ) {
          textColor = 'black';
        } else {
          textColor = '#9A9A9A';
        }

        return (
          <Link key={record.id} style={{ color: textColor, cursor: 'default' }}>
            {record.stage_name}
          </Link>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '180px',
      render: (_, record) => {
        let statusName = record.status;
        let statusClass = '';
        if (statusName && statusName === ProposedTimelineStatus.NotStarted) {
          statusName = ' ';
        }
        if (
          statusName &&
          (statusName === ProposedTimelineStatus.InProgress ||
            statusName === ProposedTimelineStatus.SentforApproval)
        ) {
          statusClass = 'in_progress ';
        }
        return (
          <Text key={record.id} className={`approvalStatus ${statusClass}`}>
            {statusName}
          </Text>
        );
      }
    }
  ];

  return (
    <>
      <div className="mainTimeLine">
        <div className="table-content">
          <Table
            loading={isRequestProject}
            className="timelineTable"
            columns={columns}
            dataSource={orderBy(project?.stages, ['id'], ['asc'])}
            pagination={false}
            sticky
            tableLayout="fixed"
            rowClassName="mainRowContent"
            rowKey={(row) => row.id}
          />
        </div>
      </div>
      {project?.stages.length === 0 ? (
        <Text className="empty-timeline">No Timeline added yet</Text>
      ) : (
        ''
      )}
    </>
  );
};

export default ProposedTimeline;

import { greyShed5 } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const VerticalThreeDotIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 10"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.99992 9.6661C1.67909 9.6661 1.40443 9.55186 1.17596 9.32339C0.947488 9.09492 0.833252 8.82026 0.833252 8.49943C0.833252 8.1786 0.947488 7.90394 1.17596 7.67547C1.40443 7.447 1.67909 7.33276 1.99992 7.33276C2.32075 7.33276 2.5954 7.447 2.82388 7.67547C3.05235 7.90394 3.16659 8.1786 3.16659 8.49943C3.16659 8.82026 3.05235 9.09492 2.82388 9.32339C2.5954 9.55186 2.32075 9.6661 1.99992 9.6661ZM1.99992 6.1661C1.67909 6.1661 1.40443 6.05186 1.17596 5.82339C0.947488 5.59492 0.833252 5.32026 0.833252 4.99943C0.833252 4.6786 0.947488 4.40394 1.17596 4.17547C1.40443 3.947 1.67909 3.83276 1.99992 3.83276C2.32075 3.83276 2.5954 3.947 2.82388 4.17547C3.05235 4.40394 3.16659 4.6786 3.16659 4.99943C3.16659 5.32026 3.05235 5.59492 2.82388 5.82339C2.5954 6.05186 2.32075 6.1661 1.99992 6.1661ZM1.99992 2.6661C1.67909 2.6661 1.40443 2.55186 1.17596 2.32339C0.947488 2.09492 0.833252 1.82026 0.833252 1.49943C0.833252 1.1786 0.947488 0.903944 1.17596 0.675472C1.40443 0.447 1.67909 0.332764 1.99992 0.332764C2.32075 0.332764 2.5954 0.447 2.82388 0.675472C3.05235 0.903944 3.16659 1.1786 3.16659 1.49943C3.16659 1.82026 3.05235 2.09492 2.82388 2.32339C2.5954 2.55186 2.32075 2.6661 1.99992 2.6661Z"
        fill={color}
      />
    </svg>
  );
};
VerticalThreeDotIcon.defaultProps = {
  color: greyShed5,
  height: 10,
  width: 4
};

export default VerticalThreeDotIcon;

import { useEffect, useState } from 'react';
import { Button, Modal, Tabs, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { ACCESS_TOKEN, USER_ROLE } from 'constants/common.constant';
import AoiTab from 'components/Compare/AoiTab';
import RouteTab from 'components/Compare/RoutesTab';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { Category, FilesCategory, UploadFilePayload } from 'store/repositoryfile/types';
import { addRepositoryFile } from 'store/repositoryfile/actions';
import { UserRole } from 'store/users/types';
import { getAsyncStorageValue, getStorageValue } from 'utils/localStorage';
import { getProject } from 'store/projects/actions';
import { getAOILists } from 'store/aoi/actions';
import { getRouteLists } from 'store/route/actions';
import './styles.less';

const { Text } = Typography;

const Compare = () => {
  const userRole = getStorageValue(USER_ROLE);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { categories, isRequestingCategory } = useAppSelector((state) => state.repofiles);
  const [isOpenUploadCsv, setOpenUploadCsv] = useState<boolean>(false);
  const [uploadCsvFile, setUploadCsvFile] = useState<File | null>(null);
  // const [uploadCsvFileName, setUploadCsvFileName] = useState<string | null>(null);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      if (id) {
        dispatch(getProject(Number(id), token));
        dispatch(getAOILists(Number(id), token));
        dispatch(getRouteLists(Number(id), token));
      }
    });
  }, []);

  const handleUploadCallback = () => {
    setUploadCsvFile(null);
    setOpenUploadCsv(false);
  };

  const handleConfirmUploadCsv = () => {
    if (uploadCsvFile) {
      const payload: UploadFilePayload = {
        comment: 'Null',
        category_id: categories.find(
          (category: Category) => category.category_name === FilesCategory.RoutesFeatureList
        ).id,
        buffer: '0',
        project_id: selectedProject?.id,
        weight: 7
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
        dispatch(addRepositoryFile(payload, token, handleUploadCallback, uploadCsvFile));
      });
    }
  };

  const handleCancelUploadCsv = () => {
    setUploadCsvFile(null);
    setOpenUploadCsv(false);
  };

  return (
    <>
      <div className="main-analytics">
        <Tabs defaultActiveKey="1" className="main-tab-content">
          <Tabs.TabPane
            tab={<Text className="tab-title-inr">aoi</Text>}
            key="1"
            className="feature-tab">
            <AoiTab />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text className="tab-title-inr">route</Text>}
            key="2"
            className="cost-tab">
            <RouteTab />
          </Tabs.TabPane>
        </Tabs>
        {
          userRole === UserRole.WorkspaceManager
          // <Button className="upload-csv">
          //   <img src={DownloadIcon} alt="CSV Icon" />
          //   Download as CSV
          // </Button>
        }
      </div>
      {uploadCsvFile && (
        <Modal
          title=""
          className="approve-modal"
          width={722}
          closable={false}
          open={isOpenUploadCsv}
          footer={null}
          centered>
          <div className="approve-modal-content">
            <Text className="modal-title">Do you want to upload &quot;{}&quot;?</Text>
            <div className="modal-content-btn">
              <Button
                type="primary"
                onClick={handleConfirmUploadCsv}
                disabled={isRequestingCategory}
                loading={isRequestingCategory}>
                Yes
              </Button>
              <Button type="ghost" onClick={handleCancelUploadCsv}>
                No
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Compare;

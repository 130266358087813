import type { UserRole } from 'store/users/types';

export type Response<T> = {
  data: T;
  status: boolean;
  message?: string;
};

export type PostResponse = {
  status: boolean;
  message: string;
};

export type Error = {
  message: string;
};

export type JWT = {
  iat: number;
  nbf: number;
  jti: string;
  exp: number;
  identity: string;
  fresh: boolean;
  type: string;
  user_claims: { role: UserRole; IsActive: boolean };
};

export enum Maptype {
  Roadmap = 'roadmap',
  satellite = 'satellite',
  terrain = 'terrain',
  Hybrid = 'hybrid'
}

export interface IconProps {
  color?: string;
  classname?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
}

export interface OptionsTypeNumber {
  label: string;
  value: number;
}

export enum CoordinateType {
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Polygon = 'Polygon',
  Point = 'Point',
  MultilineString = 'MultiLineString',
  LineString = 'LineString'
}

export enum ViewType {
  Project = 'Project',
  Map = 'Map'
}

import { createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { defaultGrey, lightred1 } from 'constants/theme.constants';
import { aoiRouteColors } from 'constants/common.constant';
import { AOIDetail, AoiState, AOIStausType } from './types';

export const initialState: AoiState = {
  isRequestingAoiPointsCoords: false,
  isRequestingApprovedAoi: false,
  aoiLists: [],
  isRequestingGenerateLULCLayer: false,
  isRequestingGenerateAOI: false,
  isRequestingAoiFiles: false,
  isRequestingAppproveDeclineAOI: false,
  isRequestingEditAOI: false,
  isRequestHighRes: false
};

export const aoiSlice = createSlice({
  name: 'aoi',
  initialState,
  reducers: {
    setRequestingBeelinePointsCoords: (state, action) => {
      state.isRequestingAoiPointsCoords = action.payload;
    },
    setRequestingGenerateAOI: (state, action) => {
      state.isRequestingGenerateAOI = action.payload;
    },
    setRequestingEditAOI: (state, action) => {
      state.isRequestingEditAOI = action.payload;
    },
    setRequestingAppproveDeclineAOI: (state, action) => {
      state.isRequestingAppproveDeclineAOI = action.payload;
    },
    setRequestingGetApprovedAoi: (state, action) => {
      state.isRequestingApprovedAoi = action.payload;
    },
    setRequestingAoiFiles: (state, action) => {
      state.isRequestingAoiFiles = action.payload;
    },
    setAoiLists: (state, action) => {
      const data = orderBy(action.payload, ['requested_at'], ['asc']).map(
        (d: AOIDetail, i: number) => ({
          ...d,
          color: d.status === AOIStausType.invalid ? lightred1 : aoiRouteColors[i] || defaultGrey,
          index: i + 1
        })
      );
      state.aoiLists = data;
      // state.aoiLists = action.payload;
    },
    setRequestingGenerateLULCLayer: (state, action) => {
      state.isRequestingGenerateLULCLayer = action.payload;
    },
    setRequestHighRes: (state, action) => {
      state.isRequestHighRes = action.payload;
    },
    clearAoi: () => {
      return initialState;
    }
  }
});

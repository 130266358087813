import { Button, Modal, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import { AOIDetail } from 'store/aoi/types';
import { getAOIArea } from 'utils/util';

const { Text } = Typography;
interface AOIApproveConfirmModalPropsType {
  isOpen: boolean;
  isClosable: boolean;
  isCancelButton: boolean;
  approvedAoi: AOIDetail | null;
  handleClickOkButton: Function;
  handleClickCancelButton: Function;
  isLoading?: boolean;
  okbuttonText?: string;
  cancelbuttonText?: string;
  subDescription?: string | null;
  width?: number;
}

const AOIApproveConfirmModal = ({
  isOpen,
  isClosable,
  isCancelButton,
  okbuttonText,
  cancelbuttonText,
  width,
  approvedAoi,
  subDescription,
  isLoading,
  handleClickOkButton,
  handleClickCancelButton
}: AOIApproveConfirmModalPropsType) => {
  return (
    <Modal
      title="Approve High Res Satellite Purchase"
      className="approve-modal"
      width={width}
      closable={isClosable}
      open={isOpen}
      closeIcon={<CloseIcon onClick={() => handleClickCancelButton()} />}
      footer={null}
      centered>
      <div className="approve-modal-content approved-aoi-sat-purchase">
        <Text className="modal-title">
          Are you sure you want to approve “AOI {approvedAoi?.index}” for purchasing the High Res
          Satellite Imagery?
        </Text>
        <Text>
          <Text style={{ fontWeight: '600' }}>AOI {approvedAoi?.index} Details</Text>
          <Text>Area of Interest (sq km) : {approvedAoi && getAOIArea(approvedAoi.geometry)}</Text>
        </Text>
        <div className={subDescription ? 'modal-content-btn no-cancel-btn' : 'modal-content-btn'}>
          <Button
            type="primary"
            loading={isLoading}
            className="confirm-btn"
            onClick={() => handleClickOkButton()}>
            {okbuttonText}
          </Button>
          {isCancelButton && (
            <Button type="ghost" className="cancel-btn" onClick={() => handleClickCancelButton()}>
              {cancelbuttonText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
AOIApproveConfirmModal.defaultProps = {
  okbuttonText: 'Approve Purchase',
  cancelbuttonText: 'Cancel',
  subDescription: null,
  isLoading: false,
  width: 523
};

export default AOIApproveConfirmModal;

import { Button } from 'antd';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import { useAppSelector } from 'hooks/useReduxHook';
import { isEmpty } from 'lodash';
import React from 'react';
import { LatLongNumberType } from 'types/aoi.types';

interface ConfirmBeeLineButtonType {
  currentStep: number;
  isBeelineBtnDisable: boolean;
  latlngA: LatLongNumberType | null;
  latlngB: LatLongNumberType | null;
  handleConfirmBeeLine: () => void;
}

const ConfirmBeeLineButton = ({
  currentStep,
  latlngA,
  latlngB,
  isBeelineBtnDisable,
  handleConfirmBeeLine
}: ConfirmBeeLineButtonType) => {
  const { selectedProject } = useAppSelector((state) => state.projects);

  return currentStep === 0 && isEmpty(selectedProject?.start_point) ? (
    <IsViewerWrapper>
      <Button
        type="primary"
        ghost
        disabled={
          !(latlngA && latlngA.lat && latlngA.lng && latlngB && latlngB.lat && latlngB.lng) ||
          isBeelineBtnDisable
        }
        onClick={() => handleConfirmBeeLine()}>
        Confirm Bee Line
      </Button>
    </IsViewerWrapper>
  ) : null;
};

export default ConfirmBeeLineButton;

import React, { useEffect, useState } from 'react';
import { Button, InputNumber, Modal, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import { RepositoryFile } from 'store/repositoryfile/types';

const { Text } = Typography;

interface EditBufferModalPropsType {
  isOpen: boolean;
  isClosable: boolean;
  editBufferData: RepositoryFile[] | [];
  handleClickOkButton: (val: number | undefined) => void;
  handleClickCancelButton: () => void;
  title?: string;
  description?: string;
}

const EditBufferModal = ({
  isOpen,
  isClosable,
  title,
  description,
  editBufferData,
  handleClickOkButton,
  handleClickCancelButton
}: EditBufferModalPropsType) => {
  const [editedBufferValue, setEditedBufferValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    setEditedBufferValue(editBufferData[0]?.buffer || 0);
  }, [editBufferData]);

  const handleonChange = (value: number) => {
    setEditedBufferValue(value);
  };

  return (
    <Modal
      width={312}
      title={title}
      className="approve-modal decline-modal"
      open={isOpen}
      closable={isClosable}
      closeIcon={<CloseIcon onClick={() => handleClickCancelButton()} />}
      footer={null}
      centered>
      <div className="approve-modal-content">
        <Text className="modal-title" style={{ color: '#A4A4A4' }}>
          {description}
        </Text>
        <div style={{ width: '100%', padding: '0px 16px 16px 24px' }}>
          <InputNumber
            status={editedBufferValue && editedBufferValue > 10000 ? 'error' : ''}
            min={0}
            // max={10000}
            // @ts-ignore
            onChange={handleonChange}
            addonAfter="m"
            value={editedBufferValue || 0}
          />
          <Text style={{ display: 'block', marginTop: 5, fontSize: 10 }}>
            Min.: 0 , Max.: 10000
          </Text>
        </div>

        <div className="modal-content-btn" style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            className="confirm-btn"
            disabled={editedBufferValue === undefined}
            onClick={() => handleClickOkButton(editedBufferValue)}>
            Save
          </Button>
          <Button type="ghost" className="cancel-btn" onClick={handleClickCancelButton}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EditBufferModal.defaultProps = {
  title: '',
  description: ''
};

export default EditBufferModal;

import { useEffect, useRef } from 'react';
import { indexOf, isEmpty, orderBy } from 'lodash';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import { Style, Fill, Stroke } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { aoiBackColors } from 'constants/common.constant';
import { AOIDetail } from 'store/aoi/types';
import { CoordinateType } from 'types/common.types';
import { useMap } from './MapContext';

interface AOILayerComponentProps {
  selectedAoiIds: string[];
  aoiLists: AOIDetail[] | [];
  selectedAOI: AOIDetail | null;
}

const AOILayerComponent = ({ aoiLists, selectedAoiIds, selectedAOI }: AOILayerComponentProps) => {
  const map = useMap();
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const vectorSourceRef = useRef<VectorSource>();

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 51,
      style: (feature) => {
        const fillColor = feature.get('fillColor');
        return new Style({
          fill: new Fill({
            color: aoiBackColors[fillColor]
          }),
          stroke: new Stroke({
            color: fillColor,
            width: 0
          }),
          zIndex: 51
        });
      }
    });
    vectorLayerRef.current.set('name', 'aoilist');
    if (map) {
      map.addLayer(vectorLayerRef.current);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!vectorSourceRef.current || !aoiLists) return;

    vectorSourceRef.current.clear();

    orderBy(aoiLists, ['requested_at'], ['desc']).forEach((aoi: AOIDetail) => {
      if (
        aoi.geometry &&
        aoi.geometry.type === CoordinateType.Polygon &&
        selectedAoiIds.includes(aoi.id) &&
        !isEmpty(aoi.geometry.coordinates)
      ) {
        const index: number = indexOf(selectedAoiIds, aoi.id);
        const coords = aoi.geometry.coordinates[0].map((point: any) => fromLonLat(point));
        const feature = new Feature({
          geometry: new Polygon([coords]),
          fillColor: aoi.color
        });
        feature.setId(aoi.id);
        if (selectedAOI && selectedAOI.id === aoi.id) {
          feature.setStyle(
            new Style({
              fill: new Fill({
                color: aoiBackColors[selectedAOI.color]
              }),
              stroke: new Stroke({
                color: selectedAOI.color,
                width: 2
              }),
              zIndex: selectedAoiIds.length + 55
            })
          );
        } else {
          feature.setStyle(
            new Style({
              fill: new Fill({
                color: aoiBackColors[aoi.color]
              }),
              stroke: new Stroke({
                color: aoi.color,
                width: 0
              }),
              zIndex: index + 55
            })
          );
        }

        vectorSourceRef.current?.addFeature(feature);
      }
    });
  }, [aoiLists, selectedAoiIds, selectedAOI]);

  return null;
};

export default AOILayerComponent;

import { useEffect, useRef, useState } from 'react';
import { orderBy } from 'lodash';
import { Spin } from 'antd';
import { AiOutlineLoading } from 'react-icons/ai';
import VectorSource from 'ol/source/Vector';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { LineString, Point } from 'ol/geom';
import { Modify, Select } from 'ol/interaction';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import CircleStyle from 'ol/style/Circle';
import Collection from 'ol/Collection';
import OLPopup from 'ol-popup';
import { never, click } from 'ol/events/condition';
import { white } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch } from 'hooks/useReduxHook';
import { RouteDetail, TowerType } from 'store/route/types';
import { getDeviationAngleData } from 'store/route/actions';
import { CoordinateType } from 'types/common.types';
import { getAsyncStorageValue } from 'utils/localStorage';
import { findPointDetails } from 'utils/util';

import { useMap } from './MapContext';

interface EditRouteLayerPropsType {
  editedRoute: RouteDetail | null;
  handleSaveEditRouteCoordsData: Function;
  handleEditRoute: Function;
}

const EditRouteLayer = ({
  editedRoute,
  handleSaveEditRouteCoordsData,
  handleEditRoute
}: EditRouteLayerPropsType) => {
  const map = useMap();
  const dispatch = useAppDispatch();
  // @ts-ignore
  const vectorLayerRef = useRef<VectorLayer>();
  const vectorSourceRef = useRef<VectorSource>();
  const popupRef = useRef<OLPopup>();
  const selectInteractionRef = useRef<Select>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    vectorSourceRef.current = new VectorSource();
    vectorLayerRef.current = new VectorLayer({
      source: vectorSourceRef.current,
      zIndex: 101,
      style: (feature) => {
        const routeColor = feature.get('fillColor');
        const routePointColor = feature.get('color');
        const type = feature.get('type');
        if (type === CoordinateType.Point) {
          return new Style({
            image: new CircleStyle({
              radius: 3,
              fill: new Fill({ color: routePointColor }),
              stroke: new Stroke({ color: routePointColor })
            })
          });
        }
        return new Style({
          stroke: new Stroke({
            color: routeColor,
            width: 1,
            lineDash: [5, 10]
          }),
          zIndex: 100
        });
      }
    });

    vectorLayerRef.current.set('name', 'editroute');

    if (map) {
      map.addLayer(vectorLayerRef.current);
    }
    return () => {
      if (map) {
        map.removeLayer(vectorLayerRef.current);
        selectInteractionRef.current?.getFeatures().clear();
      }
    };
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (map && editedRoute && editedRoute?.geometry) {
      setLoading(true);

      orderBy(editedRoute.towers, 'id').forEach((tower: TowerType, index: number) => {
        const routePointFeature = new Feature({
          geometry: new Point(fromLonLat(tower.geometry.coordinates)),
          color: editedRoute.color,
          type: tower.geometry.type
        });
        routePointFeature.setStyle(
          new Style({
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({ color: editedRoute.color }),
              stroke: new Stroke({ color: editedRoute.color })
            }),
            zIndex: 3
          })
        );

        routePointFeature.setId(tower.id);
        routePointFeature.set('name', `${tower.type}-${Number(tower.deviation_angle).toFixed(2)}`);
        routePointFeature.set('index', index);
        vectorSourceRef.current?.addFeature(routePointFeature);
      });

      const coords = orderBy(editedRoute.towers, 'id').map((t: TowerType) =>
        fromLonLat(t.geometry.coordinates)
      );

      const routeLineFeature = new Feature({
        geometry: new LineString(coords),
        fillColor: editedRoute.color,
        type: 'LineString'
      });
      routeLineFeature.setId(editedRoute.id);
      routeLineFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: editedRoute.color,
            width: 2,
            lineDash: [5, 5]
          }),
          zIndex: 100
        })
      );
      vectorSourceRef.current?.addFeature(routeLineFeature);

      const pointFeatures = vectorSourceRef.current
        ?.getFeatures()
        .filter((f) => f.get('type') === 'Point');

      const modify = new Modify({
        features: new Collection(pointFeatures),
        insertVertexCondition: never
      });

      map.addInteraction(modify);

      modify.on('modifyend', (event: any) => {
        const modifiedFeature = event.features.getArray()[0];
        const modifiedCoordinates = modifiedFeature.getGeometry().getCoordinates();
        const modifiedId = modifiedFeature.getId();

        // @ts-ignore
        const pointsCoordinates = vectorSourceRef.current
          .getFeatures()
          // @ts-ignore
          .filter((feature) => feature.getGeometry().getType() === 'Point')
          .sort((a, b) => a.get('index') - b.get('index')) // @ts-ignore
          .map((feature) => feature.getGeometry().getCoordinates());

        // @ts-ignore
        routeLineFeature.getGeometry().setCoordinates(pointsCoordinates);
        handleSaveEditRouteCoordsData(pointsCoordinates.map((d: any) => toLonLat(d)));
        handleEditRoute();
        const d = findPointDetails(
          toLonLat(modifiedCoordinates),
          pointsCoordinates.map((d: any) => toLonLat(d))
        );

        if (d) {
          getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
            dispatch(
              getDeviationAngleData(
                d?.prevPoint.toString(),
                d?.point.toString(),
                d?.nextPoint.toString(),
                modifiedId,
                token,
                (data: { id: number; type: string; deviation_angle: number }) => {
                  popupRef.current?.hide();
                  selectInteractionRef.current?.getFeatures().clear();
                  modifiedFeature.set(
                    'name',
                    `${data.type}-${Number(data.deviation_angle).toFixed(2)}`
                  );
                }
              )
            );
          });
        }
      });

      const select = new Select({
        condition: click,
        filter: (feature) => feature.get('type') === 'Point'
      });
      map.addInteraction(select);
      selectInteractionRef.current = select;

      select.on('select', (event) => {
        const selectedFeature = event.selected[0];

        if (selectedFeature) {
          // @ts-ignore
          const coordinates = selectedFeature.getGeometry().getCoordinates();
          const name = selectedFeature.get('name');

          if (popupRef.current) {
            popupRef.current.show(
              coordinates,
              `<div class='route-point'>
            <div class='route-ol-container'>
              <div>
                <span>Deviation Angle: <b>${name.split('-')[1]}</b></span>
                <span>Tower Type: <b>${name.split('-')[0]}</b></span>
              </div>
                   <button id='closebutton'>
               x
             </button>
            </div></div>`
            );
          }
          const closeBtn = document.getElementById('closebutton');

          if (closeBtn) {
            closeBtn.addEventListener('click', () => {
              popupRef.current?.hide();
              selectInteractionRef.current?.getFeatures().clear();
            });
          }
        }
      });

      setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => {
        map.removeInteraction(modify);
        map.removeInteraction(select);
      };
    }
  }, [editedRoute]);

  useEffect(() => {
    if (map) {
      popupRef.current = new OLPopup();
      map.addOverlay(popupRef.current);
    }
    return () => {
      if (map && popupRef.current) {
        map.removeOverlay(popupRef.current);
      }
    };
  }, [map]);

  return (
    <Spin
      className="spinner"
      spinning={loading}
      style={{
        width: '100%',
        position: 'absolute'
      }}
      indicator={<AiOutlineLoading className="spin-loader" speed={500} color={white} />}
    />
  );
};

export default EditRouteLayer;

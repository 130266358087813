import { Button, Dropdown, Menu } from 'antd';

interface FilterMenuPropstype {
  isDisableGoNoGoZone: boolean;
  isEditAoiRoute: boolean;
  handleShowHideAllLayers: (text: boolean) => void;
  handleGozone: (text: boolean) => void;
  handleNoGozone: (text: boolean) => void;
}

const FilterMenu = ({
  isEditAoiRoute,
  isDisableGoNoGoZone,
  handleGozone,
  handleNoGozone,
  handleShowHideAllLayers
}: FilterMenuPropstype) => {
  const handleClickMenu = (key: any) => {
    if (key === 'hideall') {
      handleShowHideAllLayers(false);
      handleGozone(false);
      handleNoGozone(false);
    } else if (key === 'showall') {
      handleShowHideAllLayers(true);
      handleGozone(false);
      handleNoGozone(false);
    } else if (key === 'gozone') {
      handleGozone(true);
      handleNoGozone(false);
    } else if (key === 'nogozone') {
      handleGozone(false);
      handleNoGozone(true);
    }
  };

  return (
    <span>
      <Dropdown
        disabled={isEditAoiRoute}
        placement="bottomRight"
        overlay={
          <Menu onClick={({ key }: any) => handleClickMenu(key)}>
            <Menu.Item key="gozone" disabled={isDisableGoNoGoZone}>
              Show all Go zones
            </Menu.Item>
            <Menu.Item key="nogozone" disabled={isDisableGoNoGoZone}>
              Show all No-Go zones
            </Menu.Item>
            <Menu.Item key="showall">Show all layers</Menu.Item>
            <Menu.Item key="hideall">Hide all layers</Menu.Item>
          </Menu>
        }
        trigger={['click']}
        overlayClassName="filter-dropdown">
        <Button type="text" ghost>
          filter
        </Button>
      </Dropdown>
    </span>
  );
};

export default FilterMenu;

import { primary } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const EyeOpenIconV1 = ({ color, height, width, onClick, classname }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      className={classname}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 2.875C4.37868 2.875 3.875 3.37868 3.875 4C3.875 4.62132 4.37868 5.125 5 5.125C5.62132 5.125 6.125 4.62132 6.125 4C6.125 3.37868 5.62132 2.875 5 2.875Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0.75C3.69111 0.75 2.514 1.27569 1.66581 1.92668C1.24116 2.25259 0.892718 2.61456 0.648245 2.96684C0.40843 3.3124 0.25 3.67577 0.25 4C0.25 4.32423 0.40843 4.6876 0.648245 5.03316C0.892718 5.38544 1.24116 5.74741 1.66581 6.07332C2.514 6.72431 3.69111 7.25 5 7.25C6.30889 7.25 7.486 6.72431 8.33419 6.07332C8.75884 5.74741 9.10728 5.38544 9.35176 5.03316C9.59157 4.6876 9.75 4.32423 9.75 4C9.75 3.67577 9.59157 3.3124 9.35176 2.96684C9.10728 2.61456 8.75884 2.25259 8.33419 1.92668C7.486 1.27569 6.30889 0.75 5 0.75ZM3.125 4C3.125 2.96447 3.96447 2.125 5 2.125C6.03553 2.125 6.875 2.96447 6.875 4C6.875 5.03553 6.03553 5.875 5 5.875C3.96447 5.875 3.125 5.03553 3.125 4Z"
        fill={color}
      />
    </svg>
  );
};
EyeOpenIconV1.defaultProps = {
  color: primary,
  width: 10,
  height: 8,
  onClick: () => {},
  classname: ''
};

export default EyeOpenIconV1;

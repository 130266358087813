import { useEffect, useState } from 'react';
import { capitalize, isEmpty, upperFirst } from 'lodash';
import { Col, Dropdown, Menu, Row, Tooltip, Button, Avatar, Typography } from 'antd';
import { FaRegUser, FaRegBell } from 'react-icons/fa';
import { RiQuestionMark } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { BiLogOutCircle } from 'react-icons/bi';
import SatSureLogo from 'assets/svg/AdaniSatSure.svg';
import SettingIcon from 'assets/svg/SettingIcon';
import { white } from 'constants/theme.constants';
import { routes } from 'constants/pageRoutes.constants';
import { USER_ROLE, userRoleText } from 'constants/common.constant';
import TooltipTitle from 'components/core/TooltipTitle';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { userLogout } from 'store/users/actions';
import { shortLabel } from 'utils/util';
import { getStorageValue } from 'utils/localStorage';
import { decryptValue } from 'utils/decodeEncodeData';
import './styles.less';

const { Text } = Typography;

interface MainHeaderProps {
  title: string;
}
const MainHeader = ({ title }: MainHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userRole = getStorageValue(USER_ROLE);
  const { user } = useAppSelector((state) => state.user);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [compressName, setCompressName] = useState<string | null>(null);
  const [role, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    if (!isEmpty(user)) {
      setUserRole(userRoleText[userRole]);
      if (user.firstname && user.lastname) {
        const decryptedFname = decryptValue(user.firstname);
        const decryptedLname = decryptValue(user.lastname);
        setFirstName(upperFirst(decryptedFname));
        setLastName(upperFirst(decryptedLname));
        setCompressName(shortLabel(`${decryptedFname} ${decryptedLname}`));
      } else {
        const decryptedEmail = decryptValue(user.email);
        setFirstName(upperFirst(decryptedEmail));
        setCompressName(capitalize(decryptedEmail.charAt(0)));
      }
    }
  }, []);

  const handleNavigate = () => {
    navigate(routes.ProjectList, { replace: true });
  };

  const handleLogoutCallback = () => {
    navigate(routes.LoginUrl, { replace: true });
    window.location.reload();
  };

  const handleClickMenu = ({ key }: any) => {
    if (key === 'logout') {
      dispatch(userLogout(handleLogoutCallback));
    }
  };

  return (
    <Row className="mainHeaderRow">
      <Col xxl={6} className="mainLogoCol">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a onClick={handleNavigate}>
            <img src={SatSureLogo} alt="SatSureSkies" />
          </a>
        </div>
      </Col>
      <Col xxl={12} className="mainTitleCol">
        <span>{title}</span>
      </Col>
      <Col xxl={6} className="mainIconCol">
        <Tooltip
          placement="bottomLeft"
          title={<TooltipTitle title="Notifications" />}
          color={white}
          key="Notifications">
          <Button type="text" icon={<FaRegBell className="tooltipIcon" />} />
        </Tooltip>
        <Tooltip title={<TooltipTitle title="Help" />} color={white} placement="bottom" key="Help">
          <Button type="text" icon={<RiQuestionMark className="tooltipIcon" />} />
        </Tooltip>
        <Tooltip
          title={<TooltipTitle title="Account" />}
          color={white}
          placement="bottom"
          key="Account">
          <Dropdown
            className="user-dropDown"
            overlay={
              <Menu onClick={handleClickMenu}>
                <Menu.Item key="profile" className="user-menu-item" style={{ cursor: 'default' }}>
                  <div className="menu-title">
                    <Avatar className="name-avatar">{compressName}</Avatar>
                    <div>
                      <Text>{`${firstName} ${lastName || ''}`}</Text>
                      <Text>{role}</Text>
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item key="setting">
                  <div className="menuTitle">
                    <SettingIcon />
                    <Text>Settings</Text>
                  </div>
                </Menu.Item>
                <Menu.Item key="logout">
                  <div className="menuTitle">
                    <BiLogOutCircle />
                    <Text>Log out</Text>
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
            placement="bottomRight">
            <Button type="text" icon={<FaRegUser className="tooltipIcon" />} />
          </Dropdown>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default MainHeader;

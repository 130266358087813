export const primary = '#316DB3';
export const red = '#ff0000';
export const lightred0 = '#F24E1E';
export const lightred1 = '#D60000';
export const lightred = '#EB5757';
export const orange = '#ff8719';
export const green = '#10af46';
export const black = '#000000';
export const lightBlack = '#333333';
export const white = '#ffffff';
export const grey = '#ddd';
export const lightGrey = '#d9d9d9';
export const lightDarkGrey = '#b0b0b0';
export const lightDarkGrey2 = '#bdbdbd';
export const lightDarkGrey3 = '#C8C8C8';
export const greyShed = '#828282';
export const greyShed2 = '#727272';
export const greyShed3 = '#666666';
export const greyShed4 = '#42444A';
export const greyShed5 = '#C7C7CC';
export const greyShed6 = '#696B72';
export const defaultGrey = '#CCCCCC';

import { primary } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const DotIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 4"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="2" fill={color} />
    </svg>
  );
};
DotIcon.defaultProps = {
  color: primary,
  height: 4,
  width: 4,
  onClick: () => {}
};

export default DotIcon;

import { white } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const TrueIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39754 13.4999C7.19054 13.4999 6.99254 13.4144 6.85079 13.2637L3.20354 9.3794C2.91929 9.0779 2.93504 8.60315 3.23654 8.31965C3.53879 8.03615 4.01354 8.05115 4.29629 8.35265L7.39004 11.6459L13.696 4.7444C13.9765 4.43765 14.4505 4.4174 14.7565 4.6964C15.0618 4.9754 15.0828 5.45015 14.8038 5.7554L7.95104 13.2554C7.81079 13.4099 7.61129 13.4984 7.40279 13.4999H7.39754Z"
        fill={color}
      />
    </svg>
  );
};
TrueIcon.defaultProps = {
  color: white,
  height: 18,
  width: 18
};

export default TrueIcon;

import { Button, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';
import React from 'react';

const { Text } = Typography;

interface NoAndGoZoneTextProps {
  isGozone: boolean;
  isNoGozone: boolean;
  handleCloseGoNogoZoneFilter: () => void;
}

const NoAndGoZoneText = ({
  isGozone,
  isNoGozone,
  handleCloseGoNogoZoneFilter
}: NoAndGoZoneTextProps) => {
  return (
    <div className="bee-line-distance go-nogo-text">
      <Text>
        Only {isGozone && 'Go'} {isNoGozone && 'NO-Go'} Zones are visible.
      </Text>
      <Button
        type="text"
        onClick={handleCloseGoNogoZoneFilter}
        icon={<CloseIcon height={10} width={10} />}
      />
    </div>
  );
};

export default NoAndGoZoneText;

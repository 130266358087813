import { Button, Dropdown, Menu, Radio, Typography } from 'antd';
import { LineString, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import EyeCloseIconV1 from 'assets/svg/EyeCloseIconV1';
import EyeOpenIconV1 from 'assets/svg/EyeOpenIconV1';
import MapLayerIcon from 'assets/svg/MapLayerIcon';
import MinusIcon from 'assets/svg/MinusIcon';
import PlusIcon from 'assets/svg/PlusIcon';
import RecenterIcon from 'assets/svg/RecenterIcon';
import RulerIcon from 'assets/svg/RulerIcon';
import { greyShed, lightDarkGrey3, white } from 'constants/theme.constants';
import { useAppSelector } from 'hooks/useReduxHook';
import { ProjectState } from 'store/projects/types';
import { LatLongNumberType } from 'types/aoi.types';
import { Maptype } from 'types/common.types';

const { Text } = Typography;

interface MapControlsPropsType {
  map: any;
  isWorkspace: boolean;
  isShowTowerText: boolean;
  isShowRecenter: boolean;
  isRulerMeasurement: boolean;
  mapType: Maptype;
  latlngA: LatLongNumberType | null;
  latlngB: LatLongNumberType | null;
  handleMapType: (type: Maptype) => void;
  handleChangeRulerMeasurement: () => void;
  handleShowHideTowerText: () => void;
}

const MapControls = ({
  map,
  isWorkspace,
  isShowRecenter,
  isRulerMeasurement,
  mapType,
  latlngA,
  latlngB,
  isShowTowerText,
  handleMapType,
  handleChangeRulerMeasurement,
  handleShowHideTowerText
}: MapControlsPropsType) => {
  const { selectedProject }: ProjectState = useAppSelector((state) => state.projects);

  const mapZoom = (zoomValue: number) => {
    const view = map.getView();
    const currentzoom = map.getView().getZoom();
    view.animate({
      zoom: currentzoom + zoomValue,
      duration: 1000
    });
  };

  const handleReCenter = () => {
    const view = map.getView();
    if (selectedProject && selectedProject.start_point && selectedProject.end_point) {
      const points = [
        fromLonLat([selectedProject.start_point.lng, selectedProject.start_point.lat]),
        fromLonLat([selectedProject.end_point.lng, selectedProject.end_point.lat])
      ];
      const line = new LineString(points);
      const extent = line.getExtent();
      view.fit(extent, { duration: 1000 });
    } else if (latlngA && latlngB) {
      const points = [
        fromLonLat([latlngA.lng, latlngA.lat]),
        fromLonLat([latlngB.lng, latlngB.lat])
      ];
      const line = new LineString(points);
      const extent = line.getExtent();
      view.fit(extent, { duration: 1000, minResolution: 40 });
    } else if (latlngA) {
      const line = new Point(fromLonLat([latlngA.lng, latlngA.lat]));
      const extent = line.getExtent();
      view.fit(extent, { duration: 1000, minResolution: 10 });
    } else if (latlngB) {
      const line = new Point(fromLonLat([latlngB.lng, latlngB.lat]));
      const extent = line.getExtent();
      view.fit(extent, { duration: 1000, minResolution: 10 });
    } else {
      const line = new Point(fromLonLat([78.0, 21.0]));
      const extent = line.getExtent();
      view.fit(extent, { duration: 1000, minResolution: 10 });
    }
  };

  return (
    <div className="map-controls-section">
      <Button
        ghost
        icon={<PlusIcon />}
        onClick={() => mapZoom(+1)}
        className="map-control-btn plus-btn"
      />
      <Button
        ghost
        className="map-control-btn minus-btn"
        icon={<MinusIcon />}
        onClick={() => mapZoom(-1)}
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="roadmap">
              <div onClick={() => handleMapType(Maptype.Roadmap)} className="roadmap">
                <Radio value={Maptype.Roadmap} checked={mapType === Maptype.Roadmap} />
                <Text>Google Maps Roads</Text>
              </div>
            </Menu.Item>
            {!isWorkspace && (
              <Menu.Item key="hybrid">
                <div onClick={() => handleMapType(Maptype.Hybrid)}>
                  <Radio value={Maptype.Hybrid} checked={mapType === Maptype.Hybrid} />
                  <Text>Google Maps Satellite</Text>
                </div>
              </Menu.Item>
            )}
            {isWorkspace && (
              <Menu.Item key="satellite">
                <div onClick={() => handleMapType(Maptype.satellite)}>
                  <Radio value={Maptype.satellite} checked={mapType === Maptype.satellite} />
                  <Text>Google Maps Satellite</Text>
                </div>
              </Menu.Item>
            )}
          </Menu>
        }
        trigger={['click']}
        placement="topRight"
        overlayClassName="maplayer-dropdown">
        <Button
          type="text"
          ghost
          className="map-control-btn map-layer"
          icon={<MapLayerIcon height={28} width={28} />}
        />
      </Dropdown>
      {isShowRecenter && (
        <Button
          type="text"
          ghost
          className="map-control-btn map-icon"
          onClick={handleReCenter}
          icon={<RecenterIcon />}
        />
      )}
      {isWorkspace && (
        <Button
          type="text"
          ghost
          className="map-control-btn map-icon"
          onClick={() => handleShowHideTowerText()}
          icon={
            isShowTowerText ? (
              <EyeOpenIconV1 height={12} width={10} color={lightDarkGrey3} />
            ) : (
              <EyeCloseIconV1 />
            )
          }
        />
      )}
      <Button
        type="text"
        ghost
        className={
          isRulerMeasurement
            ? 'active-ruler map-control-btn move-icon'
            : 'map-control-btn move-icon'
        }
        icon={<RulerIcon color={isRulerMeasurement ? greyShed : white} />}
        onClick={() => handleChangeRulerMeasurement()}
      />
    </div>
  );
};

export default MapControls;

import { black } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const CloseIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17854 5.00021L9.75604 1.42271C10.0819 1.09687 10.0819 0.570208 9.75604 0.244375C9.43021 -0.0814583 8.90354 -0.0814583 8.57771 0.244375L5.00021 3.82187L1.42271 0.244375C1.09688 -0.0814583 0.570208 -0.0814583 0.244375 0.244375C-0.0814583 0.570208 -0.0814583 1.09687 0.244375 1.42271L3.82188 5.00021L0.244375 8.57771C-0.0814583 8.90354 -0.0814583 9.43021 0.244375 9.75604C0.406875 9.91854 0.620208 10.0002 0.833542 10.0002C1.04688 10.0002 1.26021 9.91854 1.42271 9.75604L5.00021 6.17854L8.57771 9.75604C8.74021 9.91854 8.95354 10.0002 9.16687 10.0002C9.38021 10.0002 9.59354 9.91854 9.75604 9.75604C10.0819 9.43021 10.0819 8.90354 9.75604 8.57771L6.17854 5.00021Z"
        fill={color}
      />
    </svg>
  );
};
CloseIcon.defaultProps = {
  color: black,
  height: 10,
  width: 10,
  onClick: () => {}
};

export default CloseIcon;

import React from 'react';
import { Button, Input, Modal, Typography } from 'antd';
import CloseIcon from 'assets/svg/CloseIcon';

const { Text } = Typography;
const { TextArea } = Input;

interface DeclineModalPropsType {
  isOpen: boolean;
  isClosable: boolean;
  handleClickOkButton: () => void;
  comment: string | null;
  handleSetDeclineComment: (comment: string | null) => void;
  handleClickCancelButton: () => void;
  title?: string;
  description?: string;
}

const DeclineModal = ({
  isOpen,
  isClosable,
  title,
  comment,
  description,
  handleClickOkButton,
  handleClickCancelButton,
  handleSetDeclineComment
}: DeclineModalPropsType) => {
  const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleSetDeclineComment(e.target.value || null);
  };

  return (
    <Modal
      title={title}
      className="approve-modal decline-modal"
      open={isOpen}
      closable={isClosable}
      closeIcon={<CloseIcon onClick={() => handleClickCancelButton()} />}
      footer={null}
      centered>
      <div className="approve-modal-content">
        <Text className="modal-title">
          {description}
          <Text>*</Text>
        </Text>
        <div style={{ width: '100%', padding: '0px 16px 16px 24px' }}>
          <TextArea
            rows={3}
            style={{ resize: 'none', background: '#F5F5F5', border: 'none' }}
            placeholder="Enter your comments here"
            onChange={handleChangeComment}
          />
        </div>

        <div className="modal-content-btn">
          <Button
            type="primary"
            className="confirm-btn"
            disabled={!comment}
            onClick={() => handleClickOkButton()}>
            Decline
          </Button>
          <Button type="ghost" className="cancel-btn" onClick={() => handleClickCancelButton()}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeclineModal.defaultProps = {
  title: '',
  description: ''
};

export default DeclineModal;

import { useEffect } from 'react';
import { Draw } from 'ol/interaction';
import { Feature, Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { LineString, Point } from 'ol/geom';
import CircleStyle from 'ol/style/Circle';
import { black, white } from 'constants/theme.constants';

interface MeasurementTooltype {
  map: Map | null | undefined;
}

const MeasurementTool = ({ map }: MeasurementTooltype) => {
  useEffect(() => {
    if (!map) return;

    const vectorSource = new VectorSource();

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        const geometry = feature.getGeometry() as LineString;
        const totalLength = geometry.getLength();
        const totalDistanceText = `${(totalLength / 1000).toFixed(2)} km`;
        return new Style({
          stroke: new Stroke({
            color: black,
            width: 1
          }),
          text: new Text({
            placement: 'line',
            fill: new Fill({
              color: white
            }),
            backgroundFill: new Fill({
              color: 'rgba(0, 0, 0, 0.7)'
            }),
            padding: [5, 5, 5, 5],
            text: totalDistanceText
          })
        });
      }
    });

    const draw = new Draw({
      source: vectorSource,
      type: 'LineString'
    });

    map.addInteraction(draw);

    draw.on('drawstart', (event: any) => {
      vectorSource.clear();
      event.feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: black,
            width: 2,
            lineDash: [5, 10]
          })
        })
      );
    });

    draw.on('drawend', (event: any) => {
      const { feature } = event;
      const geometry = feature.getGeometry() as LineString;
      const coordinates = geometry.getCoordinates();
      const totalLength = geometry.getLength();

      let accumulatedLength = 0;

      coordinates.forEach((coord, index) => {
        const pointFeature = new Feature({
          geometry: new Point(coord)
        });

        pointFeature.setStyle(
          new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: black
              })
            })
          })
        );

        vectorSource.addFeature(pointFeature);

        if (index > 0) {
          const start = coordinates[index - 1];
          const end = coord;
          const segment = new LineString([start, end]);
          const segmentLength = segment.getLength();
          accumulatedLength += segmentLength;
          const segmentDistanceText = `${(accumulatedLength / 1000).toFixed(2)} km`;

          const midPoint = new Point([(start[0] + end[0]) / 2, (start[1] + end[1]) / 2]);

          const segmentFeature = new Feature({
            geometry: midPoint
          });

          segmentFeature.setStyle(
            new Style({
              text: new Text({
                text: segmentDistanceText,
                placement: 'point',
                fill: new Fill({
                  color: white
                }),
                backgroundFill: new Fill({
                  color: 'rgba(0, 0, 0, 0.7)'
                }),
                padding: [5, 5, 5, 5],
                offsetX: 15,
                offsetY: -15
              })
            })
          );

          vectorSource.addFeature(segmentFeature);
        }
      });

      const lastPoint = new Point(coordinates[coordinates.length - 1]);
      const totalDistanceFeature = new Feature({
        geometry: lastPoint
      });

      const totalDistanceText = `${(totalLength / 1000).toFixed(2)} km`;
      totalDistanceFeature.setStyle(
        new Style({
          text: new Text({
            text: totalDistanceText,
            placement: 'point',
            fill: new Fill({
              color: white
            }),
            backgroundFill: new Fill({
              color: 'rgba(0, 0, 0, 0.7)'
            }),
            padding: [5, 5, 5, 5],
            offsetX: 15,
            offsetY: -15
          })
        })
      );
      vectorSource.addFeature(totalDistanceFeature);
    });

    map.addLayer(vectorLayer);

    // eslint-disable-next-line
    return () => {
      map.removeLayer(vectorLayer);
      map.removeInteraction(draw);
    };
  }, []);

  return null;
};

export default MeasurementTool;

import { white } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const MapLayerIcon = ({ color, height, width, onClick }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <g id="Zoom out">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
          fill="rgba(37, 37, 37, 0.75)"
        />
        <g id="layers">
          <mask
            id="mask0_1077_4884"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="17"
            height="17">
            <rect id="Bounding box" x="4.02734" y="4.73047" width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1077_4884)">
            <path
              id="layers_2"
              d="M12.0273 18.7637L6.02734 14.0971L7.12734 13.2637L12.0273 17.0637L16.9273 13.2637L18.0273 14.0971L12.0273 18.7637ZM12.0273 15.3971L6.02734 10.7304L12.0273 6.06372L18.0273 10.7304L12.0273 15.3971ZM12.0273 13.6971L15.8607 10.7304L12.0273 7.76372L8.19401 10.7304L12.0273 13.6971Z"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
MapLayerIcon.defaultProps = {
  color: white,
  width: 24,
  height: 24,
  onClick: () => {}
};

export default MapLayerIcon;

import { Typography } from 'antd';
import ProgressIcon from 'assets/svg/Progress.svg';

const { Text } = Typography;

const ProcuringSatelliteImageryText = ({ currentStep, isSatelliteProcurementInProgress }: any) => {
  return currentStep === 1 && isSatelliteProcurementInProgress ? (
    <div className="approve-dwnld" style={{ justifyContent: 'center' }}>
      <img src={ProgressIcon} alt="progress" />
      <Text className="procurring">Procuring Satellite Imagery</Text>
    </div>
  ) : null;
};

export default ProcuringSatelliteImageryText;

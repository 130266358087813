import React from 'react';
import { Table, Typography } from 'antd';
import { sortBy } from 'lodash';
import { StatsType } from 'store/aoi/types';

const { Text } = Typography;

interface AreaCrossingPropsType {
  title: string;
  stats?: StatsType[];
}

const AreaCrossing = ({ title, stats }: AreaCrossingPropsType) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'file_cat_name',
      key: 'file_cat_name'
    },
    {
      title: 'Area (sq km)',
      dataIndex: 'area_km_sq',
      key: 'area_km_sq',
      render: (area: number) => area.toFixed(2)
    }
  ];

  return (
    <div>
      <div className="aoi_card">
        <Text className="aoi_card_title">{title}</Text>
        <Table
          scroll={{ y: '200px' }}
          className="aoi-card-table"
          dataSource={sortBy(stats, ['file_cat_name'])}
          pagination={false}
          columns={columns}
        />
      </div>
    </div>
  );
};

AreaCrossing.defaultProps = {
  stats: []
};

export default AreaCrossing;

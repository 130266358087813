import { useEffect, useState } from 'react';
import { remove } from 'lodash';
import { IoMdClose } from 'react-icons/io';
import { Button, Checkbox, Form, Input, Select, Typography } from 'antd';
import { ACCESS_TOKEN } from 'constants/common.constant';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import type { MemberT, ProjectType } from 'store/projects/types';
import { selectAvailableProjectMembers, getTeamMembers } from 'store/projects';
import { addProjectMembers } from 'store/projects/actions';
import { createProjectSync } from 'utils/yupsync';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';
import { primary } from 'constants/theme.constants';

const { Option } = Select;
const { Text } = Typography;

interface DatasetItem {
  label: string;
  value: string;
}

interface AddProjectMemberModalProps {
  selectedRow: ProjectType;
  handleCloseAddProjectMemberModal: Function;
  isProjectMember: string;
}

const AddProjectMemberModal = ({
  selectedRow,
  handleCloseAddProjectMemberModal,
  isProjectMember
}: AddProjectMemberModalProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const availableMembers = useAppSelector(selectAvailableProjectMembers);
  const { isAddProjectMemberRequest } = useAppSelector((state) => state.projects);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const existingMembers = getTeamMembers(selectedRow?.members || []);

  useEffect(() => {
    if (selectedRow) {
      form.setFieldsValue({
        project_title: selectedRow.name
      });
    }
  }, [selectedRow]);

  const handleSubmitCallback = () => {
    handleCloseAddProjectMemberModal();
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields();
    const payload = {
      project_member_ids: selectedMembers
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(
        addProjectMembers(payload, selectedRow.id, token, isProjectMember, handleSubmitCallback)
      );
    });
  };

  const handleSelectMember = (value: string[]) => {
    setSelectedMembers(value);
  };

  const handleRemoveMember = (item: string) => {
    remove(selectedMembers, (member) => {
      return member === item;
    });

    form.setFieldsValue({ project_member_ids: [...selectedMembers] });
    setSelectedMembers([...selectedMembers]);
  };

  const handleCloseModal = () => {
    setSelectedMembers([]);
    handleCloseAddProjectMemberModal();
    form.resetFields();
  };

  const filteredDataset = availableMembers?.filter(
    (item2: DatasetItem) =>
      !existingMembers?.some((item1: DatasetItem) => item1?.value === item2?.value)
  );

  const furtherFilteredDataset = filteredDataset?.filter(
    (item: DatasetItem) => item?.value !== selectedRow?.manager?.id
  );

  return (
    <div className="modalFormContent">
      <p>Add Project Members to Project</p>
      <Form layout="vertical" className="addmember-form" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="project_title"
          rules={[createProjectSync]}
          label="Name of project"
          className="formField">
          <Input disabled placeholder="Enter name of project" className="formInputField" />
        </Form.Item>
        <Form.Item
          name="project_member_ids"
          label="Assign Project Members to project"
          rules={[
            createProjectSync,
            {
              validator: (_, value) =>
                value && value.length > 0
                  ? Promise.resolve()
                  : Promise.reject(new Error('Please select at least one member.'))
            }
          ]}
          className="formField">
          <Select
            showArrow
            showSearch={false}
            mode="multiple"
            value={selectedMembers}
            onChange={handleSelectMember}
            style={{ width: '100%' }}
            className="formSelectField">
            {furtherFilteredDataset?.map((item: MemberT) => {
              const isChecked = selectedMembers.includes(item.value);

              const handleOptionClick = () => {
                if (isChecked) {
                  handleSelectMember(selectedMembers.filter((id) => id !== item.value));
                } else {
                  handleSelectMember([...selectedMembers, item.value]);
                }
              };

              return (
                <Option
                  key={item.value}
                  value={item.value}
                  className="member-dropdown"
                  onClick={handleOptionClick}>
                  <Checkbox
                    checked={isChecked}
                    onChange={() => handleOptionClick()}
                    style={{ marginRight: 8 }}>
                    {item.label}
                  </Checkbox>
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <div className="selected-list">
          {selectedMembers.length > 0 &&
            availableMembers
              .filter((m: MemberT) => selectedMembers.includes(m.value))
              .map((item: MemberT) => (
                <Text key={item.value} className="member-list">
                  <Text>{item.label}</Text>
                  <IoMdClose color={primary} onClick={() => handleRemoveMember(item.value)} />
                </Text>
              ))}
          {existingMembers?.length > 0 &&
            existingMembers.map((item: MemberT) => (
              <Text key={item.value} className="member-list">
                <Text>{item.label}</Text>
              </Text>
            ))}
        </div>
        <div className="formButton">
          <Button
            loading={isAddProjectMemberRequest}
            type="primary"
            className="formCreatebtn"
            htmlType="submit">
            Add
          </Button>
          <Button type="ghost" className="formCancelbtn" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddProjectMemberModal;

import { greyShed2 } from 'constants/theme.constants';
import { IconProps } from 'types/common.types';

const InfoIcon = ({ color, height, width, classname }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classname}>
      <path
        d="M7.99992 6.95898C8.3451 6.95898 8.62492 7.23881 8.62492 7.58398V11.7507C8.62492 12.0958 8.3451 12.3757 7.99992 12.3757C7.65474 12.3757 7.37492 12.0958 7.37492 11.7507V7.58398C7.37492 7.23881 7.65474 6.95898 7.99992 6.95898Z"
        fill={color}
      />
      <path
        d="M7.99992 5.50065C8.46016 5.50065 8.83325 5.12755 8.83325 4.66732C8.83325 4.20708 8.46016 3.83398 7.99992 3.83398C7.53968 3.83398 7.16658 4.20708 7.16658 4.66732C7.16658 5.12755 7.53968 5.50065 7.99992 5.50065Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.708252 8.00065C0.708252 3.97357 3.97284 0.708984 7.99992 0.708984C12.027 0.708984 15.2916 3.97357 15.2916 8.00065C15.2916 12.0277 12.027 15.2923 7.99992 15.2923C3.97284 15.2923 0.708252 12.0277 0.708252 8.00065ZM7.99992 1.95898C4.6632 1.95898 1.95825 4.66393 1.95825 8.00065C1.95825 11.3374 4.6632 14.0423 7.99992 14.0423C11.3366 14.0423 14.0416 11.3374 14.0416 8.00065C14.0416 4.66393 11.3366 1.95898 7.99992 1.95898Z"
        fill={color}
      />
    </svg>
  );
};
InfoIcon.defaultProps = {
  color: greyShed2,
  height: 16,
  width: 16,
  classname: ''
};

export default InfoIcon;

import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useAppSelector } from 'hooks/useReduxHook';
import { AOIDetail } from 'store/aoi/types';
import { getAOIArea } from 'utils/util';
import { getDistance } from 'utils/geoCode';
import AOIWeightBufferDetail from './AOIWeightBufferDetail';
import AreaCrossing from './AreaCrossing';
import './styles.less';

const { Text } = Typography;

interface AoiDetailsCardPropsType {
  selectedAOI: AOIDetail;
  isOpenApproveAOIModal: any;
  isOpenApprovePurchaseModal: any;
  isOpenDeclineAOIModal: any;
}

const AoiDetailsCard = ({
  selectedAOI,
  isOpenApproveAOIModal,
  isOpenDeclineAOIModal,
  isOpenApprovePurchaseModal
}: AoiDetailsCardPropsType) => {
  const { selectedProject } = useAppSelector((state) => state.projects);
  const [isOpenCloseAOIDetailModal, setOpenCloseAOIDetailModal] = useState<boolean>(false);

  useEffect(() => {
    if (isOpenApproveAOIModal) {
      setOpenCloseAOIDetailModal(true);
    }
  }, [isOpenApproveAOIModal]);

  useEffect(() => {
    if (isOpenApprovePurchaseModal) {
      setOpenCloseAOIDetailModal(true);
    }
  }, [isOpenApprovePurchaseModal]);

  useEffect(() => {
    if (isOpenDeclineAOIModal) {
      setOpenCloseAOIDetailModal(true);
    }
  }, [isOpenDeclineAOIModal]);

  useEffect(() => {
    setOpenCloseAOIDetailModal(true);
  }, []);

  return (
    <div className="aoi_details_card_container">
      <div className="aoiDetailsCard_Sections">
        <div className="arrow_icon_sections">
          <Button
            onClick={() => setOpenCloseAOIDetailModal(!isOpenCloseAOIDetailModal)}
            ghost
            type="text"
            icon={
              isOpenCloseAOIDetailModal ? (
                <IoIosArrowUp
                  data-testid="arrow-up"
                  size={18}
                  style={{ cursor: 'pointer', marginTop: 6 }}
                />
              ) : (
                <IoIosArrowDown
                  data-testid="arrow-down"
                  size={18}
                  style={{ cursor: 'pointer', marginTop: 7 }}
                />
              )
            }
          />
        </div>
        <div className={`${isOpenCloseAOIDetailModal ? 'hide_modal' : 'open_modal'}`}>
          <Row className="header-sections aoi-data-details">
            <Col span={8}>
              <Text className="detail-title">Details : </Text>
              <Text className="detail-title-data">AOI {selectedAOI.index}</Text>
            </Col>
            <Col span={8}>
              <Text className="detail-title">Area of Interest (sq km) : </Text>
              <Text className="detail-title-data">{getAOIArea(selectedAOI.geometry)}</Text>
            </Col>
            <Col span={8}>
              <Text className="detail-title">Bee Line Length (km) : </Text>
              <Text className="detail-title-data">
                {selectedProject && selectedProject.start_point && selectedProject.end_point
                  ? getDistance(
                      Number(selectedProject.start_point?.lat),
                      Number(selectedProject.start_point?.lng),
                      Number(selectedProject.end_point?.lat),
                      Number(selectedProject.end_point?.lng)
                    )
                  : ''}
              </Text>
            </Col>
          </Row>
          <Row className="body_sections" justify="space-between">
            <Col span={12}>
              <AreaCrossing title="Area Crossing Stats" stats={selectedAOI.stats} />
            </Col>
            <Col span={12}>
              <AOIWeightBufferDetail title="Weights" vectorData={selectedAOI.parameters.vectors} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AoiDetailsCard;

import { LULCLayerType } from 'store/projects/types';
import { UploadFilePayload } from 'store/repositoryfile/types';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const TILESERVER_URL = process.env.REACT_APP_TILESERVER_URL;

// auth
export const RSA_URL = `${BASE_URL}/rsa`;
export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const AES_KEY_URL = `${BASE_URL}/encrypt/`;
export const REFRESH_BOTH_TOKEN = `${BASE_URL}/refresh_both_tokens`;

// user
export const ADD_USER_DETAIL_URL = `${BASE_URL}/add_user_details`;

// projects
export const GET_ALL_PROJECT_URL = (orgId: number) =>
  `${BASE_URL}/get_all_projects?organisation_id=${orgId}`;
export const GET_ALL_PROJECTS_URL = `${BASE_URL}/projects/`;
export const ADD_PROJECT_URL = `${BASE_URL}/projects/`;
export const GET_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}`;
export const GET_AVAILABLE_PROJECT_MEMBERS_URL = `${BASE_URL}/users`;
export const ADD_DEADLINE_STAGES_UPLOAD_URL = `${BASE_URL}/deadline_stages_upload`;
export const ADD_PROJECT_STAGE_CHANGE_STATUS_URL = `${BASE_URL}/change_status`;
export const GET_PROJECT_DETAILS_URL = `${BASE_URL}/projects/master-data`;
export const UPDATE_PROJECT_DETAILS_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/edit`;
export const ADD_PROJECT_MEMBERS_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/members`;
export const REMOVE_MEMBER_FROM_PROJECT_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/member`;
export const REMOVE_MEMBER_FROM_ORGANIZATION_URL = (orgId: number, memberId: string) =>
  `${BASE_URL}/organisations/${orgId}/members/${memberId}/access`;
export const GET_PROJECT_ACTIVITY_LOG_URL = (projectId: number) =>
  `${BASE_URL}/activitylogs/${projectId}`;
export const CLOSED_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/close`;
export const OPEN_PROJECT_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/open`;
export const GET_PROJECT_EXTRACT_COORDS_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/projects/${projectId}/files/${repoFileId}/geometry`;
export const GET_LULC_LAYER_DETAILS_URL = (projectId: number, lulcType: LULCLayerType) =>
  `${BASE_URL}/projects/${projectId}/lulc/${lulcType}`;

// repository file
export const GET_ALL_FILES_URL = (projectId: number) => `${BASE_URL}/project/${projectId}/files`;
export const EDIT_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/edit`;
// WORK
export const ARCHIVE_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/active`;
export const GET_ALL_CATEGORIES_URL = (projectId: number) =>
  `${BASE_URL}/file-repo/get-file-categories/${projectId}`;
export const ADD_REPOSITORY_FILE_URL = (payload: UploadFilePayload) =>
  `${BASE_URL}/projects/${payload.project_id}/files?&category_id=${payload.category_id}&weight=${payload.weight}&buffer=${payload.buffer}&comment=${payload.comment}`;
export const FILE_MAP_ACCESS_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/map_access`;
export const DOWNLOAD_REPO_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/download`;
export const DELETE_REPOSITORY_FILE_URL = (projectId: number, repoFileId: number) =>
  `${BASE_URL}/project/${projectId}/file/${repoFileId}/delete`;

// aoi
export const AOI_COORDS_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/coords`;
export const GENERATE_AOI_URL = (projectId: number) =>
  `${BASE_URL}/aoi/${projectId}/aoi-generation`;
export const EDIT_AOI_URL = (projectId: number, aoiId: string) =>
  `${BASE_URL}/aoi/${projectId}/aois/${aoiId}/edit`;
export const GET_AOI_URL = (projectId: number) => `${BASE_URL}/aoi/${projectId}`;
export const APPROVE_DECLINE_AOI_URL = (projectId: number, aoiID: string) =>
  `${BASE_URL}/aoi/${projectId}/aois/${aoiID}/approval`;

// route
export const GET_ROUTE_URL = (projectId: number) => `${BASE_URL}/route/${projectId}`;
export const GENERATE_ROUTE_URL = (projectId: number) =>
  `${BASE_URL}/route/${projectId}/generate-route`;
export const EDIT_ROUTE_URL = (routeId: string) => `${BASE_URL}/route/${routeId}/edit-route`;
export const ROUTE_DETAILS_URL = (routeId: string) =>
  `${BASE_URL}/route/${routeId}/edit-route-stats`;
export const GET_ROUTE_TOWER_TYPE_OPTION_URL = `${BASE_URL}/route/tower-types`;
export const GET_TOWER_DEVIATION_ANGLE_URL = (
  firstpoint: string,
  secondpoint: string,
  thirdpoint: string,
  towerid: number
) =>
  `${BASE_URL}/route/get-tower-details?first_point=${firstpoint}&second_point=${secondpoint}&third_point=${thirdpoint}&id=${towerid}`;
export const UPDATE_ROUTE_TOWER_TYPE_URL = (towerid: number) =>
  `${BASE_URL}/route/towers/${towerid}`;
export const DOWNLOAD_ROUTE_DETAILS_URL = (routeId: string) =>
  `${BASE_URL}/route/${routeId}/export`;

// Workspace
export const BEELINE_COORDS_URL = (projectId: number) => `${BASE_URL}/projects/${projectId}/coords`;
export const LULC_LAYER_GENERATION_URL = (projectId: number) =>
  `${BASE_URL}/aoi/${projectId}/initiate-layer-generation`;

export const HIGH_RES_URL = `${BASE_URL}/aoi/initiate-high-res-generation`;
// analytics
export const GET_PROJECT_ANALYTICS_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/analytics`;
export const ANALYTICS_UNIT_COST_URL = (projectId: number) =>
  `${BASE_URL}/projects/${projectId}/analytics/unit-cost`;

// geoserver/wms
export const GET_GEOSERVER_WMS_URL = `${BASE_URL}/geoserver/wms`;
export const GET_GEOSERVER_WMTS_URL = `${BASE_URL}/geoserver/wmts`;
export const GET_GEOSERVER_LAYER_STYLE_URL = (layer: string) =>
  `${BASE_URL}/geoserver/layer-legend?layer_name=${layer}`;

// google base layer url
export const roadmapUrl = 'http://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}';
export const hybridUrl = 'http://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}';
export const satelliteUrl = 'http://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';

// LULC low/high res
export const LulcLowHighrResUrl = (layerName: string) =>
  `${TILESERVER_URL}/data/${layerName}/{z}/{x}/{y}.pbf`;

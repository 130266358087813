export const routes = {
  LoginUrl: '/',
  ProjectList: '/projects',
  Mapview: '/map-view',
  MemberDetail: '/member-detail',
  TeamOnboarding: '/team-onboarding',
  ManagerMemberDetail: '/manager-detail',
  Projects: '/projects',
  ProjectTimelineUrl: 'project-timeline',
  FileRepository: 'file-repository',
  Workspace: 'workspace',
  Compare: 'compare'
};

import {
  ADD_PROJECT_MEMBERS_URL,
  ADD_PROJECT_URL,
  GET_AVAILABLE_PROJECT_MEMBERS_URL,
  GET_PROJECT_URL,
  ADD_DEADLINE_STAGES_UPLOAD_URL,
  REMOVE_MEMBER_FROM_PROJECT_URL,
  REMOVE_MEMBER_FROM_ORGANIZATION_URL,
  GET_PROJECT_ACTIVITY_LOG_URL,
  CLOSED_PROJECT_URL,
  OPEN_PROJECT_URL,
  GET_ALL_PROJECTS_URL,
  GET_PROJECT_DETAILS_URL,
  UPDATE_PROJECT_DETAILS_URL,
  GET_LULC_LAYER_DETAILS_URL
} from 'utils/apiUrls';
import { toast } from 'react-toastify';
import { apiCall } from 'utils/connect';
import type { Response, Error } from 'types/common.types';
import { ACCESS_TOKEN, TOKEN_EXPIRE_ERROR } from 'constants/common.constant';
import { getAsyncStorageValue } from 'utils/localStorage';
import type { AppDispatch } from '..';
import {
  AddProjectMemberPayload,
  AvailableProjectMember,
  ProjectData,
  ProjectActivityData,
  ProjectPayload,
  ProjectType,
  RemoveOrganizationMemberPayload,
  UpdateProjectDetailsPayload,
  RemoveProjectMembersPayload,
  StageUploadPayload,
  ProjectDetail,
  LULCLayerType
} from './types';
import {
  setRequestingProjects,
  setProjects,
  setProject,
  setAddProjectRequesting,
  setRequestProject,
  setProjectMemberRequest,
  setAvailableProjectMemberRequesting,
  setAvailableProjectMembers,
  setDeadlineStageUploadRequesting,
  setRequestingRemoveMemberFromProject,
  setRequestingRemoveMemberFromOrganization,
  setSelectedProject,
  setProjectActivityLogs,
  setRequestingProjectActivityLogs,
  setRequestCloseProject,
  setProjectDetail,
  setRequestingAddProjectDetails,
  setRequestLulcLayerDetails,
  setLULCLayerDetails
} from '.';

export const getProjects = (token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setRequestingProjects(true));

    const onSuccess = (response: Response<Array<ProjectData>>) => {
      dispatch(setProjects(response.data));
      dispatch(setRequestingProjects(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getProjects(reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setRequestingProjects(false));
      }
    };

    apiCall('GET', GET_ALL_PROJECTS_URL, '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setRequestingProjects(false));
    toast.error(error.message);
  }
};

export const addProject =
  (payload: ProjectPayload, token: string, callback: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setAddProjectRequesting(true));

      const onSuccess = () => {
        toast.success('Project created successfully');
        dispatch(getProjects(token));
        dispatch(setAddProjectRequesting(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProject(payload, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setAddProjectRequesting(false));
        }
      };

      apiCall('POST', ADD_PROJECT_URL, { ...payload }, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setAddProjectRequesting(false));
      toast.error(error.message);
    }
  };

export const getProject =
  (projectId: number, token: string, callback?: Function) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestProject(true));

      const onSuccess = (response: Response<ProjectType>) => {
        dispatch(setProject(response.data));
        if (response.data) {
          dispatch(setSelectedProject(response.data));
        }
        dispatch(setRequestProject(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getProject(Number(projectId), reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestProject(false));
        }
      };

      apiCall('GET', GET_PROJECT_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestProject(false));
      toast.error(error.message);
    }
  };

export const addProjectMembers =
  (
    payload: AddProjectMemberPayload,
    projectId: number,
    token: string,
    isProjectMember: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setProjectMemberRequest(true));

      const onSuccess = () => {
        if (isProjectMember === 'timeline') {
          dispatch(getProject(projectId, token));
        }
        dispatch(getProjects(token));
        dispatch(setProjectMemberRequest(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addProjectMembers(payload, projectId, reToken, isProjectMember, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setProjectMemberRequest(false));
        }
      };

      apiCall(
        'POST',
        ADD_PROJECT_MEMBERS_URL(projectId),
        { ...payload },
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setProjectMemberRequest(false));
      toast.error(error.message);
    }
  };

export const getAvailableProjectMembers = (token: string) => (dispatch: AppDispatch) => {
  try {
    dispatch(setAvailableProjectMemberRequesting(true));

    const onSuccess = (response: Response<AvailableProjectMember>) => {
      dispatch(setAvailableProjectMembers(response.data));
      dispatch(setAvailableProjectMemberRequesting(false));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getAvailableProjectMembers(reToken));
        });
      } else {
        toast.error(error.message);
        dispatch(setAvailableProjectMemberRequesting(false));
      }
    };

    apiCall('GET', GET_AVAILABLE_PROJECT_MEMBERS_URL, '', onSuccess, onFailure, token);
  } catch (error: any) {
    dispatch(setAvailableProjectMemberRequesting(false));
    toast.error(error.message);
  }
};

export const getProjectDetails = (token: string) => (dispatch: AppDispatch) => {
  try {
    const onSuccess = (response: Response<ProjectDetail>) => {
      dispatch(setProjectDetail(response.data));
    };
    const onFailure = (error: Error) => {
      if (error.message === TOKEN_EXPIRE_ERROR) {
        getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
          dispatch(getProjectDetails(reToken));
        });
      } else {
        toast.error(error.message);
      }
    };

    apiCall('GET', GET_PROJECT_DETAILS_URL, '', onSuccess, onFailure, token);
  } catch (error: any) {
    toast.error(error.message);
  }
};

export const addDeadlineStagesUpload =
  (payload: StageUploadPayload, projectId: string, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setDeadlineStageUploadRequesting(true));

      const onSuccess = () => {
        dispatch(getProject(Number(projectId), token));
        dispatch(setDeadlineStageUploadRequesting(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(addDeadlineStagesUpload(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setDeadlineStageUploadRequesting(false));
        }
      };

      apiCall('POST', ADD_DEADLINE_STAGES_UPLOAD_URL, payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setDeadlineStageUploadRequesting(false));
      toast.error(error.message);
    }
  };

export const removeMemberFromProject =
  (
    payload: RemoveProjectMembersPayload,
    projectId: number,
    orgId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRemoveMemberFromProject(true));

      const onSuccess = () => {
        dispatch(getProjects(token));
        dispatch(setRequestingRemoveMemberFromProject(false));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(removeMemberFromProject(payload, projectId, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRemoveMemberFromProject(false));
        }
      };

      apiCall(
        'DELETE',
        REMOVE_MEMBER_FROM_PROJECT_URL(projectId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingRemoveMemberFromProject(false));
      toast.error(error.message);
    }
  };

export const updateProjectDetails =
  (payload: UpdateProjectDetailsPayload, projectId: number, token: string, callback: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingAddProjectDetails(false));
      const onSuccess = () => {
        dispatch(getProjects(token));
        callback();
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(updateProjectDetails(payload, projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingAddProjectDetails(false));
        }
      };

      apiCall('PUT', UPDATE_PROJECT_DETAILS_URL(projectId), payload, onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingAddProjectDetails(false));
      toast.error(error.message);
    }
  };

export const removeAddMemberFromOrganization =
  (
    payload: RemoveOrganizationMemberPayload,
    memberId: string,
    orgId: number,
    token: string,
    callback: Function
  ) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingRemoveMemberFromOrganization(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getProjects(token));
        dispatch(setRequestingRemoveMemberFromOrganization(false));
        callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(removeAddMemberFromOrganization(payload, memberId, orgId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingRemoveMemberFromOrganization(false));
        }
      };

      apiCall(
        'PUT',
        REMOVE_MEMBER_FROM_ORGANIZATION_URL(orgId, memberId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      dispatch(setRequestingRemoveMemberFromOrganization(false));
      toast.error(error.message);
    }
  };

export const getProjectActivityLogs =
  (projectId: number, token: string) => (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestingProjectActivityLogs(true));

      const onSuccess = (response: Response<Array<ProjectActivityData>>) => {
        dispatch(setProjectActivityLogs(response.data));
        dispatch(setRequestingProjectActivityLogs(false));
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getProjectActivityLogs(projectId, reToken));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestingProjectActivityLogs(false));
        }
      };

      apiCall('GET', GET_PROJECT_ACTIVITY_LOG_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      dispatch(setRequestingProjectActivityLogs(false));
      toast.error(error.message);
    }
  };

export const closeProject =
  (projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestCloseProject(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(setRequestCloseProject(false));
        dispatch(getProject(projectId, token));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(closeProject(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestCloseProject(false));
        }
      };

      apiCall('POST', CLOSED_PROJECT_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestCloseProject(false));
    }
  };

export const openProject =
  (projectId: number, token: string, callback?: (status: boolean) => void) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestCloseProject(true));

      const onSuccess = (response: Response<boolean>) => {
        dispatch(getProject(projectId, token));
        dispatch(setRequestCloseProject(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(openProject(projectId, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestCloseProject(false));
        }
      };

      apiCall('POST', OPEN_PROJECT_URL(projectId), '', onSuccess, onFailure, token);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestCloseProject(false));
    }
  };

export const getLulcLayerDetails =
  (projectId: number, lulcType: LULCLayerType, token: string, callback?: Function) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(setRequestLulcLayerDetails(true));

      const onSuccess = (response: Response<{ data: any; status: boolean }>) => {
        dispatch(setRequestLulcLayerDetails(false));
        if (response.status) {
          dispatch(setLULCLayerDetails(response.data));
        }
        if (callback) callback(response.data);
      };
      const onFailure = (error: Error) => {
        if (error.message === TOKEN_EXPIRE_ERROR) {
          getAsyncStorageValue(ACCESS_TOKEN).then((reToken: string) => {
            dispatch(getLulcLayerDetails(projectId, lulcType, reToken, callback));
          });
        } else {
          toast.error(error.message);
          dispatch(setRequestLulcLayerDetails(false));
        }
      };

      apiCall(
        'GET',
        GET_LULC_LAYER_DETAILS_URL(projectId, lulcType),
        '',
        onSuccess,
        onFailure,
        token
      );
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setRequestLulcLayerDetails(false));
    }
  };

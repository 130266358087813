import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'hooks/useReduxHook';
import SideBar from 'components/SideBar/SideBar';
import MainHeader from 'components/MainHeader/MainHeader';
import './styles.less';

const { Header, Content } = Layout;

const SidebarLayout = () => {
  const { selectedProject } = useAppSelector((state) => state.projects);

  return (
    <Layout>
      <Header className="mainHeaderContent">
        <MainHeader title={selectedProject?.name} />
      </Header>
      <Layout>
        <SideBar />
        <Layout className="mainLayoutContent">
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;

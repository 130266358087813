import { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Slider,
  Typography,
  Upload,
  UploadProps
} from 'antd';
import { toast } from 'react-toastify';
import { find, isEmpty, orderBy } from 'lodash';
import InfoIcon from 'assets/svg/InfoIcon';
import { greyShed5, primary, greyShed2 } from 'constants/theme.constants';
import { ACCESS_TOKEN } from 'constants/common.constant';
import IsViewerWrapper from 'components/core/IsViewerWrapper';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHook';
import { addRepositoryFile } from 'store/repositoryfile/actions';
import { Category, UploadFilePayload, UploadRepositoryFile } from 'store/repositoryfile/types';
import { fileRepositorySync } from 'utils/yupsync';
import { getAsyncStorageValue } from 'utils/localStorage';
import './styles.less';

const { Option } = Select;
const { Text } = Typography;

const FileRepositoryForm = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { selectedProject } = useAppSelector((state) => state.projects);
  const { isRequestingCategory, categories, isRequestingAddRepoFile, repositoryFiles } =
    useAppSelector((state) => state.repofiles);
  const [file, setFile] = useState<File>();
  const [weight, setWeight] = useState<number | undefined>(0);
  const [showCategoryExistsModal, setShowCategoryExistsModal] = useState(false);

  const handleColorClass = weight && weight > 0 ? primary : greyShed5;

  const uploadProps: UploadProps = {
    maxCount: 1,
    beforeUpload: (file) => {
      if (!file.name.endsWith('.kml')) {
        toast.error('Please upload .kml file only.');
        return false;
      }

      const isLt15M = file.size / 1024 / 1024 < 15;
      if (!isLt15M) {
        toast.error('File should be less than 15MB.');
        return false;
      }

      setFile(file);
      return false;
    }
  };

  const handleUploadCallback = () => {
    setFile(undefined);
    form.resetFields();
    setWeight(undefined);
  };

  const handleChangeWeights = (val: number) => {
    setWeight(val);
  };
  const handleUploadFile = (values: UploadRepositoryFile) => {
    const payload: UploadFilePayload = {
      ...values,
      category_id: values.category_id,
      buffer: values.buffer ? values.buffer.toString() : '0',
      weight: weight || 0,
      comment: encodeURIComponent(values.comment),
      project_id: selectedProject?.id,
      file_name: file
    };

    const selectedCategory = categories.find(
      (category: Category) => category.id === payload.category_id
    );

    const categoryExists = repositoryFiles.some(
      (file: { category: string; weight: number; file_name: string }) =>
        file.category === selectedCategory.category_name &&
        file.weight === payload.weight &&
        file.file_name === payload.file_name.name
    );

    if (categoryExists) {
      toast.error('File already uploaded.');
      return;
    }

    const differentWeightExists = repositoryFiles.some(
      (file: { category: string; weight: number }) =>
        file.category === selectedCategory.category_name && file.weight !== payload.weight
    );

    if (differentWeightExists) {
      setShowCategoryExistsModal(true);
      return;
    }

    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addRepositoryFile(payload, token, handleUploadCallback, file));
    });
  };

  const handleUploadFileAnyhow = (values: UploadRepositoryFile) => {
    const payload: UploadFilePayload = {
      ...values,
      category_id: values.category_id,
      buffer: values.buffer ? values.buffer.toString() : '0',
      weight: weight || 0,
      comment: encodeURIComponent(values.comment),
      project_id: selectedProject?.id
    };
    getAsyncStorageValue(ACCESS_TOKEN).then((token: string) => {
      dispatch(addRepositoryFile(payload, token, handleUploadCallback, file));
    });
  };

  const handleClearFile = () => {
    setFile(undefined);
  };

  const handleChangeCategory = (val: number) => {
    const selectedCategory = find(categories, { id: val });
    if (selectedCategory) {
      const buf = selectedCategory.default_buffer || 0;
      const wei = selectedCategory.weight || 0;
      form.setFieldsValue({
        buffer: buf,
        weight: wei
      });
      handleChangeWeights(wei);
    }
  };

  const closeModal = () => {
    setShowCategoryExistsModal(false);
  };

  return (
    <div className="repositoryForm">
      <ConfirmModal
        isOpen={showCategoryExistsModal}
        isClosable
        isCancelButton
        title="Change weightage"
        description="Are you sure you want to change the weightage for this category? This will affect all uploaded files in the same category."
        handleClickOkButton={() => {
          handleUploadFileAnyhow(form.getFieldsValue());
          closeModal();
        }}
        handleClickCancelButton={closeModal}
      />

      <Form layout="vertical" form={form} onFinish={handleUploadFile}>
        <Row justify="space-between">
          <Col xxl={4} xl={4} lg={4} md={4}>
            <Form.Item
              className="uplodeFile"
              label={
                <Text>
                  Upload File<Text>*</Text>
                </Text>
              }
              labelAlign="left">
              {file === undefined ? (
                <Upload {...uploadProps} showUploadList={false} accept="*/.kml">
                  <Button className="uplodeFileBtn" size="large" style={{ color: greyShed2 }}>
                    Select File
                  </Button>
                </Upload>
              ) : (
                <Input
                  className="file-input"
                  size="large"
                  value={file.name}
                  readOnly
                  suffix={
                    <IoIosClose
                      size={20}
                      style={{ fill: primary, cursor: 'pointer' }}
                      onClick={handleClearFile}
                    />
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col xxl={5} xl={5} lg={5} md={4}>
            <Form.Item
              className="uplodeFile"
              label={
                <Text>
                  Category<Text>*</Text>
                </Text>
              }
              labelAlign="left"
              name="category_id"
              rules={[fileRepositorySync]}>
              <Select
                className="selectCategory"
                size="large"
                showSearch
                allowClear
                disabled={isRequestingCategory}
                loading={isRequestingCategory}
                onChange={handleChangeCategory}
                placeholder="Select Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownMatchSelectWidth={false}
                placement="bottomLeft">
                {categories &&
                  categories.length > 0 &&
                  orderBy(categories, ['category_name'], ['asc']).map((cat: Category) => {
                    return (
                      <Option key={cat.id} value={cat.id}>
                        {cat.category_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xxl={8} xl={8} lg={7} md={4}>
            <Form.Item
              className="uplodeFile"
              label={
                <Text>
                  Add Weights<Text style={{ marginRight: 6 }}>*</Text>
                  <InfoIcon />
                </Text>
              }
              labelAlign="left"
              name="weight">
              <div className="file-weight-div">
                <span style={{ color: handleColorClass }}>{weight !== undefined ? weight : 0}</span>
                <div className="weights-slider">
                  <Text>0 (Go)</Text>
                  <Slider
                    onChange={handleChangeWeights}
                    tooltip={{ open: false }}
                    min={0}
                    max={100}
                    step={5}
                    value={weight}
                    handleStyle={{
                      backgroundColor: handleColorClass,
                      borderColor: handleColorClass
                    }}
                  />
                  <Text>100 (No-Go)</Text>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col xxl={4} xl={4} lg={6} md={5}>
            <Form.Item
              className="uplodeFile innerBufferContent"
              name="buffer"
              rules={[fileRepositorySync]}
              label={
                <Text>
                  Add Buffer
                  <Text style={{ marginRight: 6 }}>*</Text>
                  <InfoIcon />
                </Text>
              }
              labelAlign="left">
              <Input
                type="number"
                size="large"
                placeholder="0 (default)"
                className="custom-placeholder"
                step={0.01}
                min={0}
                addonAfter="m"
              />
            </Form.Item>
          </Col>
          <Col xxl={17} xl={17} lg={17} md={16}>
            <Form.Item
              name="comment"
              className="uplodeFile"
              label={
                <Text>
                  Add Description<Text>*</Text>
                </Text>
              }
              labelAlign="left"
              rules={[fileRepositorySync]}>
              <Input
                className="addCommentsInput"
                size="large"
                placeholder="Type here"
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} className="uplodeBtnCol">
            <Form.Item shouldUpdate>
              {() => {
                const isFileEmpty = isEmpty(file);
                const isCategoryEmpty = form.getFieldValue('category_id') === undefined;
                const isDescriptionEmpty = isEmpty(form.getFieldValue('comment'));
                const isButtonDisabled = isFileEmpty || isCategoryEmpty || isDescriptionEmpty;
                return (
                  <IsViewerWrapper>
                    <Button
                      className="submit-button"
                      loading={isRequestingAddRepoFile}
                      disabled={isButtonDisabled}
                      htmlType="submit"
                      type="primary"
                      size="large">
                      Upload
                    </Button>
                  </IsViewerWrapper>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FileRepositoryForm;
